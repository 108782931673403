import { HttpParams } from '@angular/common/http';
import { DEFAULT_PAGE_SIZE } from '../app.constants';

export class DatatablesUtils {
  static buildDatatablesSettings(extra: any): DataTables.Settings {
    const dtOptions: DataTables.Settings = {
      // 'scrollY': '490px',
      pagingType: 'full_numbers',
      pageLength: DEFAULT_PAGE_SIZE,
      scrollCollapse: true,
      // dom: '<"top"flp<"clear">>',
      ...extra,
    };
    return dtOptions;
  }

  static convertToApiParams(dtParams: any): any {
    const params: any = {};
    if (dtParams) {
      params.offset = dtParams.start;
      params.limit = dtParams.length;
      params.search = dtParams.search?.value;
      if (dtParams.order && dtParams.order.length) {
        params.sortField = dtParams.order.map((o) => dtParams.columns[o.column]?.data).join(',');
        params.sortDir = dtParams.order.map((o) => o.dir).join(',');
      }
    }
    return params;
  }
}
