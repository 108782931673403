import { Component, OnInit, AfterContentInit, Input, ViewChild } from '@angular/core';
import { SignatureFieldComponent } from 'app/_components/signature-field/signature-field.component';

declare var $: any;

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
})
export class QuestionComponent implements OnInit, AfterContentInit {
  @Input() question: any;
  @Input() answers: any;
  @Input() choices: any;
  @Input() noQuestionText: any;
  @Input() questionClass: string = 'h4';
  @Input() questionHeaderClass: string = 'h3';
  @Input() disabled: boolean = false;
  @ViewChild(SignatureFieldComponent) public signatureField: SignatureFieldComponent;

  ngOnInit() {
    const vchoices = this.choices ?? this.question.choices;
    if (!vchoices) {
      this.choices = [];
    } else {
      this.choices = Array.isArray(vchoices) ? vchoices : vchoices.split("\n");
    }
  }

  ngAfterContentInit() {

  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.answers[this.question.id] = {
          name: file.name,
          type: file.type,
          size: file.size,
          data: reader.result
        };
      };
    }
  }
}
