import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: '[appDateGreaterThan]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DateGreaterThanValidatorDirective,
    multi: true
  }]
})
export class DateGreaterThanValidatorDirective implements Validator {
  @Input() greaterThan: Date | undefined;
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value || !this.greaterThan) {
      return null;
    }
    if (value < this.greaterThan) {
      return { greaterThan: true };
    }
    return null;
  }
}