<div class="modal-header">
  <h4 class="modal-title">  Update Speaker Status</h4>
  <button type="button" class="btn btn-danger float-end" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div>
    <button type="button" class="btn btn-primary mb-2" (click)="updateStatus('awaiting_reply')">Awaiting Reply</button>
    <button type="button" class="btn btn-primary mb-2" (click)="updateStatus('confirmed')">Confirmed</button>
    <button type="button" class="btn btn-primary mb-2" (click)="updateStatus('unavailable')">Unavailable</button>
    <button type="button" class="btn btn-primary mb-2" (click)="updateStatus('declined')">Declined</button>
    <button type="button" class="btn btn-primary mb-2" (click)="updateStatus('unresponsive')">Unresponsive</button>
    <button type="button" class="btn btn-primary mb-2" (click)="updateStatus('canceled')">Canceled</button>
    <button type="button" class="btn btn-primary mb-2" (click)="updateStatus('not_contacted')">Not Contacted</button>
  </div>
  <div class="row">
    <div class="col-6 row">
      <label class="col-2 col-form-label" for="slot">Slot: </label>
      <div class="col-6">
        <select class="form-control" name="slot" [(ngModel)]="eventSpeaker.slot" (change)="updateSlot($event.target.value)">
          <option *ngFor="let slot of speakerSlots" [value]="slot">{{slot}}</option>
        </select>
      </div>
    </div>
    <div class="col-6">
      <button type="button" class="btn btn-danger mb-2 float-end" (click)="updateStatus('remove')">Remove</button>
    </div>
  </div> 
</div>