import { Component, Input } from '@angular/core';

@Component({
  selector: '[app-collapable-panel]', // eslint-disable-line
  templateUrl: './collapable-panel.component.html',
})
export class CollapablePanelComponent {
  @Input() tabid: string;
  @Input() tabtitle: string;
  @Input() collapsed: boolean;
}
