<div class="mb-3">
  <div class="row">
    <div class="col-sm-5">
      <input type="text" class="form-control" name="phone_code" [(ngModel)]="phoneCountryCode" (change)="updatePhoneNumber()" list="phoneCodes" [required]="required">
      <datalist id="phoneCodes">
        <option *ngFor="let countryData of countryDatas" [value]="countryData.phone_code">
          {{countryData.name}}
        </option>
      </datalist>
      <!-- <select [(ngModel)]="phoneCountryCode" (change)="updatePhoneNumber()" class="form-select">
        <option *ngFor="let countryData of countryDatas" [value]="countryData.phone_code">
          {{countryData.phone_code}}({{countryData.name}})
        </option>
      </select> -->
    </div>
    <div class="col-sm-7">
      <input type="text" [(ngModel)]="phoneNumber" (change)="updatePhoneNumber()" class="form-control"
        pattern="^[1-9][0-9]{7,9}$" [required]="required">
    </div>
  </div>
</div>
