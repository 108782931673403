import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from 'app/_components/base.component';
import { AuthenticationService, CompanyService } from 'app/_services';
import { COMPANY_NAME, COMPANY_SITE } from 'app/app.constants';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.css']
})
export class LoginLayoutComponent extends BaseComponent implements OnInit {
  COMPANY_NAME = COMPANY_NAME;
  COMPANY_SITE = COMPANY_SITE;

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit() {

  }
}
