import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

@Injectable()
export class AgencyService extends BaseService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/agencies`);
  }

  updateVendors(urlParams: any, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${this.baseUrl}/%(agency_id)s/vendors`, urlParams);
    return this.http.put<any>(url, apiParams, {});
  }
}