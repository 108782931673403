import { Base } from './_base.model';

export class Country extends Base {
  id: string;
  prev_id: string;
  name: string;
  is_deleted: boolean;

  constructor() {
    super();
  
    this.is_deleted = false;
  }
}