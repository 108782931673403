import { Base } from './_base.model';

export class PaymentInfo extends Base {
  id: number;
  payment_id: number;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  zip: string;
  state: string;
  pay_to: string;
  payee_name: string;
}