import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { CallPlanService, CallPlanContactService, AttendeeService } from 'app/_services';

@Component({
  selector: 'app-call-plan-search-modal',
  templateUrl: './call_plan-search-modal.component.html',
})
export class CallPlanSearchModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  searchForm = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
  });
  callPlans: any[];

  includeCallPlans: Boolean = true;
  includeAttendees: Boolean = false;
  searched: Boolean = false;

  selected: any[] = [];
  attendeeContactIds: string[] = [];

  enableCreate: Boolean;
  multiSelectable: Boolean;
  states: any[];
  companyId: string;
  eventId: string;
  divisionId: number;
  salesforceId: string;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private callPlanContactService: CallPlanContactService,
    private attendeeService: AttendeeService
  ) { }

  ngOnInit() {
    if (this.eventId) {
      this.attendeeService.list({
        company_id: this.companyId,
        event_id: this.eventId
      }).subscribe((result) => {
        const attendees = result;
        this.attendeeContactIds = attendees.map(a => a.contact_id);
      });
    }
  }

  onSearch() {
    this.searched = true;
    let attendeeType;
    if (!this.includeCallPlans && !this.includeAttendees) {
      this.toastr.error("Please a group of records");
      this.searched = false;
      return;
    } else if (this.includeCallPlans && this.includeAttendees) {
      attendeeType = '';
    } else if (this.includeAttendees) {
      attendeeType = 'contact';
    } else if (this.includeCallPlans) {
      attendeeType = 'call_plan';
    }

    this.callPlanContactService.listPage({
      company_id: this.companyId,
    }, {
      ...this.searchForm.value,
      type: attendeeType,
      attended: true,
      limit: 100,
    }, null).subscribe(res => {
      this.callPlans = res.call_plan_contacts;
    });
    this.selected = [];
  }

  toggleCallPlan(callPlan) {
    if (!callPlan.type) callPlan.type = 'call_plan';
    if (this.multiSelectable) {
      if (this.isSelected(callPlan)) {
        const index = this.selected.findIndex((obj) => obj.id === callPlan.id);
        this.selected.splice(index, 1);
      } else {
        this.selected.push(callPlan);
      }
    } else {
      this.selected = [callPlan];
    }
  }

  isSelected(callPlan): Boolean {
    return this.selected && this.selected.find(obj => obj.id === callPlan.id);
  }

  hasSelected(): Boolean {
    return this.selected && this.selected.length > 0;
  }

  submitSelected() {
    this.bsModalRef.hide();
    this.event.emit(this.selected);
  }

  createNew() {
    this.bsModalRef.hide();
    this.event.emit('new_attendee');
  }

  attendeeExist(person) {
    if (person.type === 'contact') {
      return this.attendeeContactIds.includes(person.id);
    } else if (person.type === 'call_plan') {
      return person.contact_id && this.attendeeContactIds.includes(person.contact_id);
    }
    return false;
  }
}
