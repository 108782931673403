import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()

export class GlobalNotificationService extends BaseService {
  constructor (
    protected http: HttpClient
  ) {
    super(http, `${environment.apiBaseUrl}/notifications`);
  }

  getCompanies(id: string): Observable<any> {
    const url = this.baseUrl + `/${id}/companies`;
    return this.http.get<any>(url, {});
  }

  updateCompanies(id: string, companies: any): Observable<any> {
    const url = this.baseUrl + `/${id}/companies`;
    return this.http.post<any>(url, companies);
  }
}