import { Base } from './_base.model';

export class DownloadForm extends Base {
  id: number;
  event_card_category_id: string;
  form: string;
  topics: any[];
  salesforces: any[];
  products: any[];
  meeting_types: any[];
  form_file: string | ArrayBuffer;
  category: string;
}
