<div [ngClass]="field.is_half ? 'mb-3 half-field': 'mb-3 full-field'" *ngIf="eventValues">
  <div *ngIf="field.id != 'space'">
    <label [for]="field.id" class="form-label" [ngClass]="{'required': field.required}">
      {{field.label}}
    </label>
    <input *ngIf="field.type === 'text'" type="text" class="form-control" [id]="field.id" [name]="field.id"
      [(ngModel)]="eventValues[field.id]" [required]="field.required">
    <input *ngIf="field.type === 'date'" type="date" class="form-control" [id]="field.id" [name]="field.id"
      [(ngModel)]="eventValues[field.id]" [required]="field.required">
    <input *ngIf="field.type === 'checkbox'" type="checkbox" class="ms-2 form-check-input" [id]="field.id"
      [name]="field.id" [(ngModel)]="eventValues[field.id]">
    <ng-select *ngIf="field.type === 'dropdown'" [items]="lists[field.list_name]" bindLabel="label" bindValue="value"
      [multiple]="field.multiple" [id]="field.id" [name]="field.id" [(ngModel)]="eventValues[field.id]"
      [required]="field.required"></ng-select>
  </div>
</div>
<div *ngIf="!eventValues" [ngClass]="field.is_half ? 'mb-3 half-field': 'mb-3 full-field'">
  <label [for]="field.id" class="form-label"> {{ field.id != 'space' ? field.label : ''}}</label>
  <input *ngIf="field.type === 'text' && field.id != 'space'" type="text" class="form-control" [id]="field.id">
  <input *ngIf="field.type === 'text' && field.id == 'space' && isVisibleSpace " type="text" class="form-control"
    [id]="field.id" value="S P A C E" disabled>
  <input *ngIf="field.type === 'date'" type="date" class="form-control" [id]="field.id">
  <input *ngIf="field.type === 'checkbox'" type="checkbox" class="ms-2 form-check-input" [id]="field.id">
  <ng-select *ngIf="field.type === 'dropdown'" [items]="lists[field.list_name]" [multiple]="field.multiple"
    [id]="field.id"></ng-select>
</div>