import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { PopoverDirective } from "ngx-bootstrap/popover";
import { AuthenticationService, CompanyService, QuestionTooltipService } from 'app/_services';

import { BaseComponent } from 'app/_components/base.component';

import { QuestionTooltip } from 'app/_models';

@Component({
  selector: 'app-question-tooltip',
  templateUrl: './question-tooltip.component.html',
})
export class QuestionTooltipComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() id: string;
  @ViewChild('pop') pop: PopoverDirective;
  popoverLinkId: string;
  popoverContainerId: string;
  tooltip: QuestionTooltip | null;

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    private tooltipService: QuestionTooltipService,
  ) {
    super(router, authenticationService, companyService);
    this.tooltip = null;
  }

  ngOnInit() {
    this.popoverLinkId = `tooltip-popover-link-${this.id}`;
    this.popoverContainerId = `tooltip-popover-container-${this.id}`;
    this.tooltipService.list().subscribe(() => {
      this.tooltip = this.tooltipService.getTooltip(this.id);
    });
  }

  ngAfterViewInit() {
    $(document).on('click', (e: any) => {
      if (this.pop.isOpen) {
        const container = $(`#${this.popoverLinkId},#${this.popoverContainerId}`);
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          this.pop.hide();
        }
      }
    });
  }

  onClick() {
    this.pop.toggle();
  }
}
