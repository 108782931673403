import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import moment from 'moment';

import {
  AuthenticationService,
  ProfileService,
  CompanyService,
  SettingService,
  FiscalYearService,
  UserNotificationService,
  UserChecklistService,
  SocketService,
  StaffService
} from '../../_services';
import { Company, User } from '../../_models';
import { BaseComponent } from '../../_components/base.component';
import { COMPANY_NAME } from 'app/app.constants';

import { ConfirmationModalComponent } from 'app/_components/confirmation-modal/confirmation-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent extends BaseComponent implements OnInit {
  COMPANY_NAME = COMPANY_NAME;
  changeEventSubscription: Subscription;
  user: User = new User();
  @Input() companies: Company[];
  search: string;
  unreadNotifications: any[];
  unreadNotificationsCount: number;
  userChecklist: any[];
  notification: any;
  fiscalYearId: string = '';

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private location: Location,
    private settingService: SettingService,
    private modalService: BsModalService,
    private fiscalYearService: FiscalYearService,
    private userNotificationService: UserNotificationService,
    private userChecklistService: UserChecklistService,
    private socketService: SocketService,
    private toastr: ToastrService,
    private staffService: StaffService,
  ) {
    super(router, authenticationService, companyService);
    this.unreadNotifications = [];
    this.unreadNotificationsCount = 0;
  }

  ngOnInit() {
    /* this.socketService.chatNotificationEvent.subscribe(notification => {
      this.notification = notification;
      this.toastr.info(`${moment(this.notification.when).format('MM/DD/YYYY, hh:mm A')}`, `New Message from ${this.notification.from.first_name}`, { closeButton: true, timeOut: 100000 })
        .onTap
        .pipe()
        .subscribe(() => this.router.navigate([`/chat/${this.notification.chat_room_id}`]));
    }); */
    this.userChecklist = [];
    this.route.queryParams.subscribe(params => {
      if (params.search) {
        this.search = params.search;
      }
    });
    this.profileService.getProfile()
      .subscribe(user => {
        this.user = user;
        this.userChecklistService.list().subscribe(list => {
          this.userChecklist = list;
        });
        this.userNotificationService.list({}, { limit: 3, all: 0 }).subscribe(res => {
          this.unreadNotifications = res.notifications;
          this.unreadNotificationsCount = res.notifications.length;
        });
      });
    if (this.authenticationService.client_company_id()) {
      this.companies = this.companies.filter((c) => c.id === this.authenticationService.client_company_id());
    }
    this.fiscalYearService.list({
      company_id: this.getCurrentCompanyId()
    }).subscribe(fiscalYears => {
      const [currentFiscalYear] = fiscalYears.filter((fy) => fy.current);
      this.fiscalYearId = currentFiscalYear?.id;
    });
  }

  logout() {
    this.authenticationService.logout();
    this.location.replaceState('/');
    this.router.navigate(['/login']);
  }

  getCurrentAgencyId() {
    const salesforce = this.getSalesforce();
    if (salesforce?.current_agency_id) {
      return salesforce?.current_agency_id;
    }
    const company = this.getCompany();
    return company?.current_agency_id;
  }

  selectSalesforce(company_id: string, company_name: string, salesforce_id: string, salesforce_name: string) {
    this.authenticationService.setCurrentSalesforce(company_id, company_name, salesforce_id, salesforce_name);
    this.location.replaceState('/');
    // this.navigate(this.router, '/', {}, company_name, salesforce_name);
    this.router.navigate(['/']);
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  setDefaultSalesforce(company_id: string, salesforce_id: string) {
    this.user.default_company_id = company_id;
    this.user.default_salesforce_id = salesforce_id;
    this.profileService.updateDefaultSalesforce(this.user)
      .subscribe(user => {
        this.user = user;
      });
  }

  getSalesforceNavbarColor() {
    const salesforce = this.getSalesforce();
    if (salesforce && salesforce.navbar_color) return salesforce.navbar_color;
    const company = this.getCompany();
    if (company && company.header_color) return company.header_color;
    return null;
  }

  getSalesforceImage() {
    const salesforce = this.getSalesforce();
    return salesforce ? salesforce.image : null;
  }

  getSalesforceName() {
    const salesforce = this.getSalesforce();
    return salesforce ? salesforce.name : null;
  }

  onSubmit() {
    this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.getCompanySalesforceUrl('/events/bureau')], {
        queryParams: { search: this.search }
      });
    });
  }

  changeShadow() {
    this.staffService.updateShadowSchedule({
      id: this.user.staff_id,
      shadowed: !this.user.shadowed
    }).subscribe(res => {
      this.user.shadowed = res.shadowed;
    });
  }
}
