import {
  Component,
  forwardRef,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-photo-selector',
  templateUrl: './photo-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhotoSelectorComponent),
      multi: true,
    },
  ],
})
export class PhotoSelectorComponent implements ControlValueAccessor {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() current: string | null = null;
  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;
  imageChangedEvent: any = '';
  cropperReady: boolean = false;

  get model() {
    return this._model;
  }

  set(value: any) {
    this._model = value;
    if (this.onChange) {
      this.onChange(this._model);
    }
  }

  public writeValue(value: any): void {
    this._model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  fileChangeEvent(event: any): void {
    this.cropperReady = false;
    this.imageChangedEvent = event;
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.set(event.base64);
  }

  deleteCurrent() {
    this.set(false);
    this.current = null;
  }

  reset() {
    this.fileInput.nativeElement.value = "";
    this.set(null);
    this.fileChangeEvent('');
  }
}
