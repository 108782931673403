import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class JobInstanceService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(
      http,
      `${environment.apiBaseUrl}/companies/%(company_id)s/job_instances`
    );
  }
}
