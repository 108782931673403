import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SocialAuthService } from '@abacritt/angularx-social-login';

import { AuthenticationService } from './_services';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private location: Location,
    private socialAuthService: SocialAuthService,
  ) { }

  ngOnInit() {
    this.socialAuthService.signOut();
    this.authenticationService.logout();
    this.location.replaceState('/');
    this.router.navigate(['/login']);
  }
}
