import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class EventTourService extends BaseService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/tour_events`);
  };

  createEventTour(model: any, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl + `/%(speaker_id)s`, urlParams);
    const observable = this.http.post<any>(url, model, {
      params: this.toHttpParams(apiParams)
    });
    return observable;
  };
  deleteEventTour(model: any, urlParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl + `/%(speaker_id)s`, urlParams);
    return this.http.delete<any>(url, { body: model });
  };
}