import { Component, Input, OnInit } from "@angular/core";

import { Company, Staff, StaffShadow, StaffShadowSchedule } from 'app/_models';

@Component({
  selector: 'app-shadow-settings',
  templateUrl: './shadow-settings.component.html',
})
export class ShadowSettingsComponent implements OnInit {
  @Input() companies: Company[];
  @Input() staffs: Staff[];
  @Input() defaultShadowStaffs: StaffShadow[];
  @Input() shadowSchedules: StaffShadowSchedule[];

  newStaffShadow: StaffShadow;
  newShadowSchedule: StaffShadowSchedule;

  ngOnInit(): void {
    this.newStaffShadow = new StaffShadow();
    this.newShadowSchedule = new StaffShadowSchedule();
  }

  onAddStaffShadow() {
    if (this.newStaffShadow.shadow_id) {
      this.defaultShadowStaffs.push(this.newStaffShadow);
      this.newStaffShadow = new StaffShadow();
    }
  }

  onDeleteStaffShadow(i) {
    this.defaultShadowStaffs.splice(i, 1);
  }

  onAddShadowSchedule() {
    if (this.newShadowSchedule.start_at && this.newShadowSchedule.end_at) {
      this.shadowSchedules.push(this.newShadowSchedule);
      this.newShadowSchedule = new StaffShadowSchedule();
    }
  }

  onDeleteShadowSchedule(i) {
    this.shadowSchedules.splice(i, 1);
  }
}