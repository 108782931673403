import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class AttendeeAnswerService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/attendees/%(attendee_id)s/answers`);
  }

  listAll(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/attendee_answers/all`, urlParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams(apiParams)
    });
  }

  delete(urlParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams);
    return this.http.delete<any>(url);
  }
}
