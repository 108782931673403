import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { DatatablesUtils } from 'app/_utils/datatables';
import { Vendor } from 'app/_models';
import { BaseService } from './base.service';

export class Vendors {
  vendors: Vendor[] = [];
  extended: any;
  total: number = 0;
}

@Injectable()
export class VendorService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/vendors`);
  }

  list(urlParams: any = {}, apiParams: any = {}): Observable<any[]> {
    const url = sprintf(`${environment.apiBaseUrl}/vendors`, urlParams);
    return this.http.get<any[]>(url, {
      params: this.toHttpParams(apiParams)
    });
  }

  listPage(urlParams: any, params: any, dtParams: any): Observable<Vendors> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<Vendors>(url, {
      params: this.toHttpParams({
        ...apiParams,
        ...params,
      })
    });
  }

  updateDefaults(model: any, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${this.baseUrl}/defaults`, urlParams);
    return this.http.post<any>(url, model, {
      params: this.toHttpParams(apiParams),
    });
  }

  importVendors(formData) {
    const url = `${environment.apiBaseUrl}/vendors/import`;
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  importVendorsXlsx(formData) {
    const url = `${environment.apiBaseUrl}/vendors/import_xlsx`;
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  listVendorEvents(vendorId): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/vendors/${vendorId}/events`;
    return this.http.get<any[]>(url);
  }
}
