<form name="loginform" class="form-material" id="loginform" (ngSubmit)="login()" novalidate>
  <h1>Login</h1>
  <div>
    <div id="orange_separator"></div>
  </div>
  <div id="tabs" class="row">
    <div class="col-6 tab">
      <a href="javascript:void(0)">
        <img src="/assets/img/user-icon.png"> Personal Account
      </a>
    </div>
    <div class="col-6 tab active">
      <a href="javascript:void(0)">
        <img src="/assets/img/corporate-account.png"> Corporate Account
      </a>
    </div>
  </div>
  <div *ngIf="error" class="alert alert-danger mt-5">{{error}}</div>
  <div class="mb-3">
    <input type="text" placeholder="Username" [(ngModel)]="model.username" class="form-control" name="username" />
  </div>
  <div class="mb-3">
    <input type="password" placeholder="Password" [(ngModel)]="model.password" class="form-control" name="password" />
  </div>
  <div class="mb-3">
    <button class="btn btn-danger w-100" [disabled]="loading">Log In</button>
  </div>
  <div class="text-end mb-2">
    <a routerLink="/forgot-password" id="forgot_password">Forgot username or password? Click Here</a>
  </div>
  <div class="row text-center">
    <div>
      <img src="/assets/img/login-separator-line.png">
    </div>
    <div class="mt-4">
      <img class="btn-apple-signin me-3" src="/assets/img/apple-icon.png" (click)="signinWithApple()">
      <img class="btn-facebook-signin me-3" src="/assets/img/fb-icon.png" (click)="signinWithFacebook()">
      <asl-google-signin-button class="btn-google-signin" shape="circle" size="large" width="40" height="40">
        <img src="/assets/img/google-icon.png">
      </asl-google-signin-button>
    </div>
    <div class="mt-3">
      <button class="btn btn-secondary" type="button" (click)="signinWithAuth0()">Single Sign On</button>
    </div>
    <div class="mt-3">
      <a href="javascript:void(0)" id="create_account">Create a FREE Account</a>
    </div>
  </div>
</form>