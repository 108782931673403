import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class EventPaymentService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/payments`);
  }

  payWithPlaid(model: any, urlParams: any, apiParams: any) {
    const url = sprintf(this.baseUrl, urlParams);
    return this.http.put(url, model, {
      params: this.toHttpParams(apiParams)
    });
  }

  dispute(model: any, urlParams: any, apiParams: any) {
    const url = `${sprintf(this.baseUrl, urlParams)}/${urlParams.payment_id}/dispute`;
    return this.http.put(url, model, {
      params: this.toHttpParams(apiParams)
    });
  }
}
