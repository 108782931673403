import { Base } from './_base.model';

export class SupportContact extends Base {
  id: number;
  salesforces: any[];
  support_label: string;
  support_program_phone: string;
  support_program_email: string;
  support_travel_phone: string;
  support_travel_email: string;
  support_after_hours: string;
  is_default: boolean;
  created_at: Date;

  deserialize(obj: any) {
    obj.salesforces = obj.support_contact_salesforces.map(c => c.salesforce_id);
    delete obj.support_contact_salesforces;
    Object.assign(this, obj);
    return this;
  }
}
