<label>{{tooltip ? tooltip.label : id}}</label>
&nbsp;
<a [popover]="tooltipPopover" id="{{popoverLinkId}}" placement="bottom" href="javascript:void(0)" class="flag-popup"
  (click)="onClick()" triggers="" #pop="bs-popover">
  <i class="fa fa-question-circle"></i>
</a>
<ng-template #tooltipPopover>
  <div class="row" id="{{popoverContainerId}}">
    <div class="col" [innerHTML]="tooltip ? tooltip.content : 'Missing tooltip content'"></div>
  </div>
</ng-template>