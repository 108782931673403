import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { DatatablesUtils } from '../_utils/datatables';
import { Client } from '../_models';

export class Clients {
  clients: Client[];
  total: number;
}

@Injectable()
export class ClientService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/clients`);
  }

  listPage(urlParams: any, params: any, dtParams: any): Observable<Clients> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<Clients>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    }).pipe(
      map(res => {
        const clients = new Clients();
        clients.clients = res.clients.map((c) => new Client().deserialize(c));
        clients.total = res.total;
        return clients;
      })
    );
  }

  get(id: number, urlParams: any = {}): Observable<Client> {
    const url = sprintf(this.baseUrl, urlParams) + `/${id}`;
    return this.http.get<any>(url).pipe(
      map(res => new Client().deserialize(res))
    );
  }

  import(urlParams: any, formData) {
    const url = sprintf(this.baseUrl, urlParams) + '/import';
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
