import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class DropdownService extends BaseService {
  constructor(
    http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/dropdowns`);
  }

  getDropdowns(names: string, apiParams: any = {}): Observable<any> {
    const url = `${environment.apiBaseUrl}/dropdowns`;
    return this.http.get<any>(url, {
      params: this.toHttpParams({ names, ...apiParams })
    });
  }

  getDropdownsForCompany(company_id: string, names: string, apiParams: any = {}): Observable<any> {
    const url = `${environment.apiBaseUrl}/companies/${company_id}/dropdowns`;
    return this.http.get<any>(url, {
      params: this.toHttpParams({ names, ...apiParams })
    });
  }

  getDropdownsForCompanySalesforce(company_id: string, salesforce_id: string, names: string, apiParams: any = {}): Observable<any> {
    const url = `${environment.apiBaseUrl}/companies/${company_id}/dropdowns`;
    return this.http.get<any>(url, {
      params: this.toHttpParams({ names, salesforce_id, ...apiParams })
    });
  }

  getCoreListsForCompany(company_id: string, names: string, apiParams: any = {}): Observable<any> {
    const url = `${environment.apiBaseUrl}/companies/${company_id}/core_lists`;
    return this.http.get<any>(url, {
      params: this.toHttpParams({ names, ...apiParams })
    });
  }

  getDropdown(name: string, apiParams: any = {}): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/dropdowns/${name}`;
    return this.http.get<any[]>(url, {
      params: this.toHttpParams(apiParams)
    });
  }

  // doesn't load common if company version is blank
  getDropdownForCompany(company_id: string, name: string, apiParams: any = {}): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/companies/${company_id}/dropdowns/${name}`;
    return this.http.get<any[]>(url, {
      params: this.toHttpParams(apiParams)
    });
  }

  getHCPDropdownForCompany(company_id: string, name: string, apiParams: any = {}): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/companies/${company_id}/hcp_dropdowns/${name}`;
    return this.http.get<any[]>(url, {
      params: this.toHttpParams(apiParams)
    });
  }

  updateDropdown(name: string, payload: any): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/dropdowns/${name}`;
    return this.http.put<any[]>(url, payload);
  }

  updateDropdownForCompany(company_id: string, name: string, payload: any): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/companies/${company_id}/dropdowns/${name}`;
    return this.http.put<any[]>(url, payload);
  }

  updateHCPDropdownForCompany(company_id: string, name: string, payload: any): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/companies/${company_id}/hcp_dropdowns/${name}`;
    return this.http.put<any[]>(url, payload);
  }

  updateCoreListForCompany(company_id: string, name: string, payload: any): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/companies/${company_id}/core_lists/${name}`;
    return this.http.put<any[]>(url, payload);
  }

  getDropdownList(company_id: string, apiParams: any = {}): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/companies/${company_id}/dropdowns/list`;
    return this.http.get<any[]>(url, {
      params: this.toHttpParams(apiParams)
    });
  }
}
