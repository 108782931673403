import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { sprintf } from 'sprintf-js';


import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class PresentationQrcodeService extends BaseService {

  constructor(
    protected override http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/presentation_qrcodes`);
  }
  
}
