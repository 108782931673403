import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

@Injectable()
export class VendorAgencyService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/vendors/%(vendor_id)s/vendor_agencies`);
  }

  update(model: any, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = apiParams.company_id
      ? sprintf(this.baseUrl, urlParams) + `/${urlParams.agency_id}`
      : `${environment.apiBaseUrl}/vendors/${urlParams.vendor_id}/vendor_agencies/${urlParams.agency_id}`;
    return this.http.put<any>(url, model, {
      params: this.toHttpParams(apiParams),
    });
  }
}
