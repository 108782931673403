import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-npi-input',
  templateUrl: './npi-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NPIInputComponent),
      multi: true,
    },
  ],
})
export class NPIInputComponent implements ControlValueAccessor {
  npi_no: string = "";

  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;

  public writeValue(value: any): void {
    this.npi_no = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateNPINumber(event) {
    this.npi_no = event.target.value;
    this.onChange(this.npi_no);
  }
}
