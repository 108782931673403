import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class HonorariaRateDefaultService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/honoraria_rate_defaults`);
  }

  getAllHonorariaRates(urlParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl + `/all`, urlParams);
    return this.http.get<any>(url, {});
  }
}
