import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class HcpValidationSourceService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/hcp_validation_sources`);
  }

  getCurrentHcpValidationSources(company): string | null {
    if (company.hcp_validation_sources) {
      if (company.hcp_validation_sources.length === 1) {
        return company.hcp_validation_sources[0];
      } else if (company.hcp_validation_sources.length > 1) {
        return 'all';
      }
    }
    return null;
  }

  getHcpValidationSources(value: string | null): string[] | null {
    if (value === 'all') {
      return ['MedPro', ''];
    } else if (value || value === '') {
      return [value];
    }
    return null;
  }
}
