import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { DatatablesUtils } from '../_utils/datatables';
import { Speaker } from '../_models';

export class Speakers {
  speakers: Speaker[];
  extended: any;
  total: number;
}

@Injectable()
export class SpeakerService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/speakers`);
  }

  listPage(urlParams: any, params: any, dtParams: any = {}): Observable<Speakers> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<Speakers>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    });
  }

  getSpeaker(id: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/speakers/${id}`;
    return this.http.get<any>(url);
  }

  getSpeakerCompanies(id: string): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/speakers/${id}/companies`;
    return this.http.get<any[]>(url);
  }

  import(urlParams: any, formData) {
    const url = sprintf(this.baseUrl, urlParams) + '/import';
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  updateTrainings(urlParams: any, payload) {
    const url = sprintf(this.baseUrl, urlParams) + '/trainings';
    return this.http.post(url, payload);
  }

  uploadAgreements(urlParams: any, formData) {
    const url = sprintf(this.baseUrl, urlParams) + '/agreements';
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  uploadBioCvs(urlParams: any, formData) {
    const url = sprintf(this.baseUrl, urlParams) + '/bio_cvs';
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getEvents(id: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/speakers/${id}/events`;
    return this.http.get<any>(url);
  }

  getEventNotes(urlParams: any = {}, apiParams: any = {}, dtParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/speakers/%(speaker_id)s/event_notes`, urlParams);
    return this.http.get<any>(url, { params: this.toHttpParams(apiParams) });
  }

  getTravels(id: string): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/speakers/${id}/travels`;
    return this.http.get<any[]>(url);
  }

  getVisits(urlParams: any = {}, apiParams: any = {}): Observable<any[]> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/speakers/%(speaker_id)s/visits`, urlParams);
    return this.http.get<any>(url, { params: this.toHttpParams(apiParams) });
  }

  getSpeakerPayments(id: string, apiParams: any = {}): Observable<any> {
    const url = `${environment.apiBaseUrl}/speakers/${id}/payments`;
    return this.http.get<any>(url, { params: this.toHttpParams(apiParams) });
  }

  getSpeakerBudget(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/speakers/%(speaker_id)s/budget`, urlParams);
    return this.http.get<any>(url, { params: this.toHttpParams(apiParams) });
  }

  getCompanyEvents(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/speakers/%(speaker_id)s/events`, urlParams);
    return this.http.get<any>(url, { params: this.toHttpParams(apiParams) });
  }

  getSpeakerEvaluations(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/speakers/%(speaker_id)s/evaluations`, urlParams);
    return this.http.get<any>(url, { params: this.toHttpParams(apiParams) });
  }

  updatePaymentInfo(model: any, urlParams: any): Observable<any> {
    const url = urlParams.company_id ?
      sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/speakers/%(id)s/payment_info`, urlParams) :
      sprintf(`${environment.apiBaseUrl}/speakers/%(id)s/payment_info`, urlParams);
    return this.http.put<any>(url, model);
  }

  create(model: any, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/salesforces/%(salesforce_id)s/speakers`, urlParams);
    return this.http.post<any>(url, model, {
      params: this.toHttpParams(apiParams),
    });
  }
}
