<div class="modal-header">
  <h4 class="modal-title">Search contacts for attendees</h4>
  <button type="button" class="btn btn-danger float-end" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <div class="mb-3">
    <div class="form-check-inline form-check">
      <label class="form-check-label" for="callPlans">
        <input type="checkbox" class="form-check-input" id="callPlans" [(ngModel)]="includeCallPlans"> Call Plans
      </label>
    </div>
    <div class="form-check-inline form-check">
      <label class="form-check-label" for="attendees">
        <input type="checkbox" class="form-check-input" id="attendees" [(ngModel)]="includeAttendees"> Prior Attendees
      </label>
    </div>
    <button type="button" class="btn btn-primary float-end" *ngIf="enableCreate && searched" (click)="createNew()">Create
      New</button>
  </div>
  <div>
    <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
      <div class="row">
        <div class="col-3">
          <label class="form-label" for="first_name">First Name</label>
          <input type="text" formControlName="first_name" class="form-control">
        </div>
        <div class="col-3">
          <label class="form-label" for="last_name">Last Name</label>
          <input type="text" formControlName="last_name" class="form-control">
        </div>
        <div class="col-2">
          <label class="form-label" for="city">City</label>
          <input type="text" formControlName="city" class="form-control">
        </div>
        <div class="col-2">
          <label class="form-label" for="state">State</label>
          <select formControlName="state" class="form-control">
            <ng-template ngFor let-state [ngForOf]="states">
              <option [value]="state.code" *ngIf="state.country_id === 'US'">{{state.name}}</option>
            </ng-template>
          </select>
        </div>
        <div class="col-2">
          <label class="form-label">&nbsp;</label><br />
          <button class="btn btn-primary">Search</button>
        </div>
      </div>
    </form>
  </div>

  <br />
  <table class="table table-striped" *ngIf="callPlans">
    <thead>
      <tr>
        <th></th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Credential</th>
        <th>City</th>
        <th>State</th>
      </tr>
    </thead>
    <tbody *ngIf="callPlans.length">
      <tr *ngFor="let callPlan of callPlans" [ngClass]="{ 'opacity-50': attendeeExist(callPlan) }">
        <td>
          <input type="checkbox" *ngIf="multiSelectable" [checked]="isSelected(callPlan)"
            (change)="toggleCallPlan(callPlan)">
          <input type="radio" name="selected" *ngIf="!multiSelectable" (change)="toggleCallPlan(callPlan)" [disabled]="attendeeExist(callPlan)">
        </td>
        <td [ngClass]="{'text-bold': callPlan.current}">{{callPlan.first_name}}</td>
        <td [ngClass]="{'text-bold': callPlan.current}">{{callPlan.last_name}}</td>
        <td [ngClass]="{'text-bold': callPlan.current}">{{callPlan.degree}}</td>
        <td [ngClass]="{'text-bold': callPlan.current}">{{callPlan.city}}</td>
        <td [ngClass]="{'text-bold': callPlan.current}">{{callPlan.state}}</td>
      </tr>
    </tbody>
    <tbody *ngIf="callPlans.length === 0">
      <tr>
        <td colspan="6" align="center">No matching records found</td>
      </tr>
    </tbody>
    <tfoot *ngIf="callPlans.length">
      <tr>
        <td colspan="6" align="center">
          <button type="button" class="btn btn-primary" (click)="submitSelected()" [disabled]="!hasSelected()">Add
            Selected</button>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
