<div class="mb-3"
  *ngIf="question.type_id!=='HEADER' && question.type_id!=='PARAGRAPH' && question.type_id!=='CHECKBOX'">
  <label class="form-label">{{question.text}}</label>
  <div *ngIf="question.type_id==='TF'">
    <select name="answer_{{screenIndex}}_{{questionIndex}}" class="form-control"
      [(ngModel)]="answers[screenIndex][questionIndex]">
      <option [ngValue]="value" *ngFor="let value of ['', 'True', 'False']">{{value}}</option>
    </select>
  </div>
  <div *ngIf="question.type_id==='YN'">
    <div class="form-check" *ngFor="let value of question.na ? ['', 'Yes', 'No'] : ['Yes', 'No'];index as i">
      <input type="radio" class="form-check-input" name="answer_{{screenIndex}}_{{questionIndex}}" [value]="value"
        [(ngModel)]="answers[screenIndex][questionIndex]" id="answer_{{screenIndex}}_{{questionIndex}}_{{i}}"
        (change)='onChangeYNStatus(value)'>
      <label class="form-check-label" for="answer_{{screenIndex}}_{{questionIndex}}_{{i}}"> {{value === '' ? 'N/A' :
        value}}</label>
    </div>
    <div *ngIf="showedSubQuestion" class="ps-4">
      <ng-template ngFor let-question let-i="index" [ngForOf]="question.subQuestions">
        <app-request-question [screenIndex]="screenIndex" [questionIndex]="questionIndex +'_sub_' + i"
          [question]="question" [answers]="answers" [states]="states"></app-request-question>
      </ng-template>
    </div>
  </div>
  <div *ngIf="question.type_id==='food_served' || question.type_id==='collaborate'">
    <div class="form-check" *ngFor="let value of ['', 'Yes', 'No'];index as i">
      <input type="radio" class="form-check-input" name="answer_{{screenIndex}}_{{questionIndex}}" [value]="value"
        [(ngModel)]="answers[screenIndex][questionIndex]" id="answer_{{screenIndex}}_{{questionIndex}}_{{i}}">
      <label class="form-check-label" for="answer_{{screenIndex}}_{{questionIndex}}_{{i}}"> {{value === '' ? 'N/A' :
        value}}</label>
    </div>
  </div>
  <div *ngIf="question.type_id==='others_may_join'">
    <div class="form-check" *ngFor="let value of ['Yes', 'No'];index as i">
      <input type="radio" class="form-check-input" name="answer_{{screenIndex}}_{{questionIndex}}" [value]="value"
        [(ngModel)]="answers[screenIndex][questionIndex]" id="answer_{{screenIndex}}_{{questionIndex}}_{{i}}">
      <label class="form-check-label" for="answer_{{screenIndex}}_{{questionIndex}}_{{i}}"> {{value}}</label>
    </div>
  </div>
  <div *ngIf="question.type_id==='RATING'">
    <rating [(ngModel)]="answers[screenIndex][questionIndex]" max="5"></rating>
  </div>
  <div *ngIf="question.type_id==='COMMENTS' || question.type_id==='TEXT_AREA'">
    <textarea rows="4" cols="60" name="answer_{{screenIndex}}_{{questionIndex}}" class="form-control"
      [(ngModel)]="answers[screenIndex][questionIndex]" [required]="!question.optional"></textarea>
  </div>
  <div *ngIf="question.type_id==='TEXT_FIELD'">
    <input type="text" name="answer_{{screenIndex}}_{{questionIndex}}" class="form-control"
      [(ngModel)]="answers[screenIndex][questionIndex]" [required]="!question.optional">
  </div>
  <div *ngIf="question.type_id==='PHONE_NUMBER'">
    <app-phone-input name="answer_{{screenIndex}}_{{questionIndex}}" [(ngModel)]="answers[screenIndex][questionIndex]" [required]="!question.optional"></app-phone-input>
  </div>
  <div *ngIf="question.type_id==='MULTIPLE_RADIO'">
    <ng-template ngFor let-value let-i="index" [ngForOf]='question.choices.split("\n")'>
      <div class="form-check" *ngIf='value'>
        <input type="radio" class="form-check-input" name="answer_{{screenIndex}}_{{questionIndex}}_{{i}}"
          [value]="value" [(ngModel)]="answers[screenIndex][questionIndex]"
          id="answer_{{screenIndex}}_{{questionIndex}}_{{i}}">
        <label class="form-check-label" for="answer_{{screenIndex}}_{{questionIndex}}_{{i}}"> {{value}}</label>
      </div>
    </ng-template>
  </div>
  <div *ngIf="question.type_id==='MULTIPLE_CHECK'">
    <ng-template ngFor let-value let-i="index" [ngForOf]='question.choices.split("\n")'>
      <div class="form-check" *ngIf='value'>
        <input type="checkbox" class="form-check-input" name="answer_{{screenIndex}}_{{questionIndex}}_{{i}}"
          [value]="value" [(ngModel)]="answers[screenIndex][questionIndex][value]">
        <label class="form-check-label" for="answer_{{screenIndex}}_{{questionIndex}}_{{i}}"> {{value}}</label>
      </div>
    </ng-template>
  </div>
  <div *ngIf="question.type_id==='SIGNATURE'">
    <div fxLayout="row">
      <button type="button" (click)="signatureField.clear()"
        [disabled]="!answers[screenIndex][questionIndex]">Clear</button>
    </div>
    <div fxLayout="row">
      <app-signature-field [(ngModel)]="answers[screenIndex][questionIndex]"></app-signature-field>
    </div>
  </div>
  <div *ngIf="question.type_id==='NUMBER' || question.type_id==='speaker_count'">
    <input type="text" name="answer_{{screenIndex}}_{{questionIndex}}" class="form-control"
      [(ngModel)]="answers[screenIndex][questionIndex]">
  </div>
  <div
    *ngIf="question.type_id==='AMOUNT' || question.type_id==='attendee_count_estimate' || question.type_id==='client_count_estimate'">
    <input type="text" name="answer_{{screenIndex}}_{{questionIndex}}" class="form-control"
      [(ngModel)]="answers[screenIndex][questionIndex]" [required]="!question.optional">
  </div>
  <div *ngIf="question.type_id==='DATE'">
    <input type="text" name='answer_{{screenIndex}}_{{questionIndex}}' [(ngModel)]="answers[screenIndex][questionIndex]"
      class="form-control datetimepicker" autocomplete="off" bsDatepicker>
  </div>
  <div *ngIf="question.type_id==='FILE'">
    <div *ngIf="answers[screenIndex][questionIndex]">{{answers[screenIndex][questionIndex].name}}</div>
    <input type="file" name="answer_{{screenIndex}}_{{questionIndex}}" class="dropify"
      (change)="onFileChange($event)" />
  </div>
  <div *ngIf="question.type_id==='state'">
    <select class="form-control" name="answer_{{screenIndex}}_{{questionIndex}}"
      [(ngModel)]="answers[screenIndex][questionIndex]">
      <option value="">Please select a state</option>
      <option [value]="state.code" *ngFor="let state of states">{{state.code}}</option>
    </select>
  </div>
</div>
<div *ngIf="question.type_id==='CHECKBOX'">
  <div class="form-check mb-3">
    <input type="checkbox" class="form-check-input" name="answer_{{screenIndex}}_{{questionIndex}}" value="true"
      [(ngModel)]="answers[screenIndex][questionIndex]" [required]="!question.optional">
    <label class="form-check-label" for="answer_{{screenIndex}}_{{questionIndex}}">{{question.text}}</label>
  </div>
</div>
<div class="h3 b-none mt-2" *ngIf="question.type_id==='HEADER'">
  {{question.text}}
</div>
<p *ngIf="question.type_id==='PARAGRAPH'">
  {{question.text}}
</p>
