import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class StaffService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/staffs`);
  }

  updateShadowSchedule(payload: any, urlParams: any = {}): Observable<any>{
    const url = sprintf(this.baseUrl, urlParams) + `/${payload.id}/shadow`;
    return this.http.put<any>(url, payload);
  }
}
