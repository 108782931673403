<div class="panel-heading" role="tab">
  <h4 class="panel-title cohost-pop">
    <a role="button" data-bs-toggle="collapse" href="#{{tabid}}" class="{{collapsed ? 'collapsed' : ''}}">
      {{tabtitle}}
    </a>
  </h4>
</div>
<div [id]="tabid" class="panel-collapse collapse {{!collapsed ? 'in' : '' }}" role="tabpanel">
  <div class="panel-body">
    <div class="white-box">
      <ng-content></ng-content>
    </div>
  </div>
</div>