import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

import { sprintf } from 'sprintf-js';

import { DatatablesUtils } from '../_utils/datatables';

@Injectable()
export class UserService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/users`);
  }
  
  listPage(urlParams: any, params: any, dtParams: any): Observable<any[]> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<any[]>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    });
  }
}
