import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { DatatablesUtils } from '../_utils/datatables';
import { BaseService } from './base.service';

@Injectable()
export class EventChecklistService extends BaseService {
  private subject: Subject<void> = new Subject();
  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/checklists`);
  }

  sendEventchecklistUpdatedEvent() {
    this.subject.next();
  }
  getEventchecklistUpdatedEvent(): Observable<void> {
    return this.subject.asObservable();
  }

  listPage(urlParams: any, params: any, dtParams: any): Observable<any[]> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<any[]>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    });
  }

  toggle(urlParams: any, body: any, apiParams: any = {}): Observable<any[]> {
    const url = sprintf(this.baseUrl, urlParams) + '/toggle';
    return this.http.post<any[]>(url, body, {
      params: this.toHttpParams(apiParams)
    });
  }
}
