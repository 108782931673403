import moment from 'moment';

import { Base } from './_base.model';

export class CustomReport extends Base {
  id!: number;
  name!: string;
  report_type!: string;
  status_filter!: string;
  date_range!: string;
  fiscal_year_id!: string;
  query!: string;
  calendar_year_id!: number;
  salesforce_ids: string[] = [];
  column_ids: number[] = [];
  custom_headers: string[] = [];
}
