import { Base } from './_base.model';

export class ForumSurvey extends Base {
  id: number;
  forum_id: number;
  name: string;
  user_id: string;
  created_at: Date;
  archived: boolean;

  survey_questions: any[];

  constructor() {
    super();
    this.survey_questions = [];
  }

  deserialize(obj: any) {
    Object.assign(this, obj);
    return this;
  }
}
