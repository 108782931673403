import moment from 'moment';
import { Base } from './_base.model';
import { ClientArea } from './_client_area.model';

export class Client extends Base {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  user: any;
  archive_at: Date;
  areas: ClientArea[];
  level_id: string;
  sub_level_id: string;
  title: string;
  affiliation: string;
  office_phone: string;
  cell_phone: string;
  emergency_phone: string;
  voice_mail: string;
  fax: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  username: string;
  password: string;
  core: boolean;
  srg: boolean;
  aggregator: boolean;
  speaker_submission: boolean;
  forums: boolean;
  archived: boolean;
  notes: string;
  timezone_id: string;
  team: string;
  employee_id: string;
  imed_id: string;
  meeting_types: any[];
  salesforces: any[];
  client_permissions: any[];
  permissions: any[];
  signs: any[];
  client_trainings: any[];
  photo: string;
  photo_file: any;

  constructor() {
    super();
    this.user = {};
    this.srg = true;
  }

  uri(path = '/edit') {
    return `/tools/client/${this.id}${path}`;
  }

  get full_name(): string {
    return ['first_name', 'last_name'].map(x => this[x]).filter(Boolean).join(' ') || 'N/A';
  }

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.archive_at) {
      this.archive_at = moment(this.archive_at).toDate();
    }
    if (this.areas) {
      this.areas = this.areas.map((area) => new ClientArea().deserialize(area));
    }
    return this;
  }
}
