import { Base } from './_base.model';
import { Client } from './client.model';

export class Area extends Base {
  id?: number;
  area_id?: number;
  parent_area_id?: number;
  name?: string;
  description?: string;
  children: Area[];
  manager?: Client;
  type_id?: string;
  budget_summary_by_years?: any;
  budget_by_types?: any;

  static convertToTree(areas): any {
    const ret: any[] = [];
    const convert = function (area, parent) {
      const a = {
        name: `${area.name} ${area.description}`,
        value: area.area_id,
        children: [],
      };
      parent.push(a);
      area.children.forEach((child) => convert(child, a.children));
    };
    areas.forEach((area) => convert(area, ret));
    return ret;
  }

  static convertToArray(areas): any {
    const territories: any[] = [];
    const managers: any[] = [];
    const parents: any[] = [];
    const convert = function (area) {
      if (area.manager) {
        managers.push(area.manager);
      }
      if (area.children && area.children.length) {
        parents.push(area);
        area.children.forEach(convert);
        parents.pop();
      } else {
        let description = parents.map(parent => parent.description).join('\\');
        if (description) {
          description = description + '\\';
        }
        description = description + area.description;
        let budget_allocation = 0;
        if (area.budget_by_types) {
          area.budget_by_types.forEach(budget => {
            if (budget.area_id == area.area_id)
              area.budget_by_types += budget.budget_allocation;
          });
        }
        territories.push({
          id: area.area_id,
          salesforce_id: area.salesforce_id,
          fiscal_year_id: area.fiscal_year_id,
          name: area.name,
          description_manager: `${area.manager ? area.manager.last_name + ', ' + area.manager.first_name : 'Vacant, Vacant'}\\${description}`,
          description,
          budget_allocation,
        });
      }
    };
    areas.forEach(convert);
    return {
      areas: territories,
      managers,
    };
  }

  static listAreas(areas) {
    const list: any[] = [];
    const convert = function (area) {
      list.push(area);
      if (area.children.length) {
        area.children.forEach(convert);
      }
    }
    areas.forEach(convert);
    return list;
  }

  constructor(type_id) {
    super();
    this.type_id = type_id;
    this.children = [];
  }

  override deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.children) {
      this.children = this.children.map((child) => new Area(child.type_id).deserialize(child));
    }
    if (this.manager) {
      this.manager = new Client().deserialize(this.manager);
    }
    if (this.budget_by_types && this.budget_by_types.length === 0) {
      this.budget_by_types.push({});
    }
    return this;
  }

  uri(parentPath = 'finance/budgets', path = '') {
    return `/${parentPath}/area/${this.area_id || this.id}${path}`;
  }
}
