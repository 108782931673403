import { Base } from './_base.model';

export class EmailTemplate extends Base {
  id: string;
  name: string;
  is_default: boolean;
  subject: string;
  body: string;
  recipient_specific: boolean;
}
