import { Base } from './_base.model';

export class ClientArea extends Base {
  area_id: number;

  deserialize(obj: any) {
    Object.assign(this, obj);
    return this;
  }

  uri(path = '') {
    return `/finance/budgets/area/${this.area_id}${path}`;
  }
}
