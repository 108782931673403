import { parse } from '@datasert/cronjs-parser';
import { Base } from './_base.model';
import { WEEK_DAYS } from 'app/app.constants';

export class JobInstance extends Base {
  id!: string;
  name!: string;
  category!: string;
  options!: object;
  days: object;
  mode!: string;
  cron!: string;
  job_type_id!: string;
  timezone!: string;
  event_status!: string;
  active!: boolean;
  days_after!: number;
  days_before!: number;
  time!: Date;
  created_at!: Date;
  updated_at!: Date;

  constructor() {
    super();
    this.days = {};
    this.mode = 'daily';
    this.time = new Date();
    this.time.setHours(7, 0);
    this.timezone = '';
    this.timezone = 'America/New_York';
    this.options = {};
  }

  getDays() {
    return Object.keys(this.days).filter((day) => this.days[day]);
  }

  serialize() {
    const min = this.time.getMinutes();
    const hour = this.time.getHours();
    const dayOfMonth = '*';
    const month = '*';
    const dayOfWeek = Object.keys(this.days)
      .map((weekDay) =>
        this.days[weekDay] ? weekDay.slice(0, 3).toLowerCase() : ''
      )
      .filter((m) => !!m)
      .join(',');
    this.cron = [min, hour, dayOfMonth, month, dayOfWeek].join(' ');

    return this;
  }

  override deserialize(obj: any) {
    Object.assign(this, obj);

    const parsed = parse(this.cron);
    this.days = {};
    WEEK_DAYS.forEach((w, index) => {
      this.days[w] = (
        parsed.expressions[0]['day_of_week']['values'] || []
      ).includes(index + 1);
    });
    this.time = new Date();
    this.time.setMinutes(parsed.expressions[0]['minute'].values?.[0] || 0);
    this.time.setHours(parsed.expressions[0]['hour'].values?.[0] || 0);

    return this;
  }
}
