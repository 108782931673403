import moment from 'moment';

import { Base } from './_base.model';

export class SpeakerAgreement extends Base {
  id: number;
  type: string;
  signature: string;
  client_signature: string;
  initial: string;
  signature_date: Date;
  client_signature_date: Date;
  effective_date: Date;
  expiration_date: Date;
  uploaded_date: Date;
  created_date: Date;
  file: string;
  w9_file: string;
  specialty: string;
  tier: string;
  hourly_rate: string;
  cap: number;
  tier_review_file: string;
  honoraria_rates: any;

  deserialize(obj: any) {
    Object.assign(this, obj);
    [
      'signature_date',
      'client_signature_date',
      'effective_date',
      'expiration_date',
      'uploaded_date',
    ].forEach(field => {
      if (this[field]) {
        this[field] = moment(this[field]).toDate();
      }
    });
    return this;
  }
}
