import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { AuthenticationService, CompanyService, QuestionTooltipService } from 'app/_services';
import { BaseComponent } from 'app/_components/base.component';
import { Company } from 'app/_models';
import { ProfileService } from 'app/_services';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent extends BaseComponent implements OnInit {
  public companies: Company[];
  public companyLoaded: boolean = false;
  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    private tooltipService: QuestionTooltipService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
  ) {
    super(router, authenticationService, companyService);
    this.companies = [];
    this.route.params.subscribe((params) => {
      const { companySalesforce } = params;
      this.companyService.list().subscribe(companies => {
        this.companies = companies;
        if (companySalesforce) {
          const [companyName, salesforceName] = companySalesforce.split('.');
          let company;
          let salesforce;
          if (companyName) {
            company = this.companies.find(c => c.name === companyName);
            if (company) {
              if (salesforceName) {
                salesforce = company.salesforces.find(p => p.name === salesforceName);
                if (salesforce) {
                  this.authenticationService.setCurrentSalesforce(company.id, company.name, salesforce.id, salesforce.name);
                  this.companyLoaded = true;
                }
              }
            }
          }
        } else {
          this.companyLoaded = true;
        }
        if (!this.getCurrentCompanyId() || !this.getCurrentSalesforceId()) {
          let company;
          if (!this.getCurrentCompanyId()) {
            [company] = companies;
          } else {
            company = this.companies.find(c => c.id === this.getCurrentCompanyId());
          }
          if (company && company.salesforces && company.salesforces.length) {
            const [salesforce] = company.salesforces;
            if (salesforce) {
              this.authenticationService.setCurrentSalesforce(company.id, company.name, salesforce.id, salesforce.name);
            }
          }
        }
      });
      this.tooltipService.list().subscribe();
    });
  }

  isProfileLoaded() {
    return this.profileService.isProfileLoaded();
  }

  ngOnInit() {

  }
}
