import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';
import { Base } from './_base.model';

export class EventProfile extends Base {
  id: string;
  common_event: any;
  prefix: string;
  meeting_type_id: number;
  speaker_count: number;
  topic_id: number;
  status_id: string;
  sub_status_id: string;
  planner_staff_id: string;
  venue_staff_id: string;
  faculty_staff_id: string;
  travel_staff_id: string;
  reconciliation_staff_id: string;
  planner_shadow_id: string;
  venue_shadow_id: string;
  faculty_shadow_id: string;
  travel_shadow_id: string;
  reconciliation_shadow_id: string;
  area_id: number;
  fiscal_year_id: string;
  eo_of_record_id: number;
  reconciled_at: Date | null;
  budget_status_id: string;
  shared_areas: any;
  public_calendar: string;
  only_hcps: string;
  only_hcp_specialties: string;
  agency_id: string;
  request: any;

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.reconciled_at) {
      this.reconciled_at = moment(this.reconciled_at).toDate();
    }
    if (this.common_event.start_at) {
      const eventStartAt = moment(this.common_event.start_at).tz(this.common_event.timezone_id);
      this.common_event.event_start_at = eventStartAt.toDate();
      this.common_event.event_date = eventStartAt.format('MM-DD-YYYY');
      this.common_event.event_time = eventStartAt.format('HH:mm');
    }
    return this;
  }

  setEventDate(value) {
    this.common_event.event_date = moment(value).format('YYYY-MM-DD');
  }

  setEventTime(value) {
    this.common_event.event_time = moment(value).format('HH:mm');
  }

  setEventDateTimeUTC(numberOfEventHours: number) {
    if (this.common_event.event_date && this.common_event.event_time && this.common_event.timezone_id) {
      this.common_event.start_at = moment.tz(`${this.common_event.event_date} ${this.common_event.event_time}`, this.common_event.timezone_id).toDate();
      this.common_event.end_at = moment.tz(`${this.common_event.event_date} ${this.common_event.event_time}`, this.common_event.timezone_id).add(numberOfEventHours, 'hours').toDate();
    } else {
      this.common_event.start_at = null;
      this.common_event.end_at = null;
    }
  }

  getEventDate() {
    return (this.common_event.event_date && this.common_event.event_time) ? new Date(`${this.common_event.event_date} ${this.common_event.event_time}`) : null;
  }
}
