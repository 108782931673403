import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';


@Injectable()
export class EventSpeakerAnswerService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/speaker_answers`);
  }

  listAll(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/speaker_answers/all`, urlParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams(apiParams)
    });
  }
  getExpenseAnswers(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/speaker_expense_answers`, urlParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams(apiParams)
    });
  }
  updateExpenseAnswer(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/speaker_expense_answers/%(speaker_id)s/%(question_id)s`, urlParams);
    return this.http.put<any>(url, apiParams);
  }
}
