<form #usernameForm="ngForm" (ngSubmit)="usernameForm.valid && forgotUsername()">
  <h1>Forgot Username?</h1>
  <div>
    <div id="orange_separator"></div>
  </div>
  <p *ngIf="successUsername" class="alert alert-success">
    Email has been sent to your email address.<br>
    <a routerLink="/login">Click here to login.</a>
  </p>
  <div *ngIf="!successUsername">
    <div *ngIf="errorUsername" class="alert alert-danger">{{errorUsername}}</div>
    <p class="desc">
      Please provide us with your Email and username will be sent to you.
    </p>
    <div class="row mb-3">
      <div class="col-auto">
        <input type="email" placeholder="Email" [(ngModel)]="email" class="form-control" name="email" required />
      </div>
      <div class="col-auto">
        <button class="btn btn-danger w-100 mb-2" [disabled]="loading">Enter</button>
      </div>
    </div>
  </div>
</form>
<form #passwordForm="ngForm" (ngSubmit)="passwordForm.valid && forgotPassword()">
  <h1>Forgot Password?</h1>
  <div>
    <div id="orange_separator"></div>
  </div>
  <p *ngIf="successPassword" class="alert alert-success">
    Email has been sent to your email address.<br>
    <a routerLink="/login">Click here to login.</a>
  </p>
  <div *ngIf="!successPassword">
    <div *ngIf="errorPassword" class="alert alert-danger">{{errorPassword}}</div>
    <p class="desc">
      Please provide us with your Username and your temporary link will be sent to you.
    </p>
    <div class="row mb-3">
      <div class="col-auto">
        <input type="username" placeholder="Username" [(ngModel)]="username" class="form-control" name="username"
          required />
      </div>
      <div class="col-auto">
        <button class="btn btn-danger w-100 mb-2" [disabled]="loading">Enter</button>
      </div>
    </div>
  </div>
</form>