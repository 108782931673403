import { Component, Input, OnInit, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';
import { BsModalRef } from "ngx-bootstrap/modal";
import { BaseComponent } from 'app/_components/base.component';

import { AuthenticationService, CompanyService } from 'app/_services';

@Component({
  selector: 'app-multi-select-modal',
  templateUrl: './multi-select-modal.component.html'
})

export class MultiSelectModalComponent extends BaseComponent implements OnInit {
  @Input() title: string;
  @Input() allSelectable: boolean;
  @Input() selectedAll: boolean;
  @Input() selected: string[];
  @Input() list: any[];
  @Input() bindValue: string = 'value';
  @Input() bindLabel: string = 'value';
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    public bsModalRef: BsModalRef
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit() {
    if (this.bindValue === 'value' && this.bindLabel === 'value') {
      this.list = this.extendLists(this.list, this.selected);
    }
    this.selected = [...this.selected];
  }

  selectItem(index, value) {
    const item = this.list[index][this.bindValue];
    if (value) {
      this.selected.push(item);
    } else {
      const index = this.selected.indexOf(item);
      if (index != -1) {
        this.selected.splice(index, 1);
      }
    }
  }

  selectAll() {
    // if(this.selectedAll) {
    //   this.selected = this.list.map(item => item[this.bindValue]);
    // } else {
    //   this.selected = [];
    // }
  }

  onSave() {
    this.event.emit({
      selectedAll: this.selectedAll,
      selected: this.selected,
    });
    this.bsModalRef.hide();
  }
}