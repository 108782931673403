import moment from 'moment';

import { Base } from './_base.model';

export class CreditCard extends Base {
  id: number;
  payment_category_id: string;
  speaker_id: string;
  cc_number: string;
  exp_year: number;
  exp_month: number;
  cvc: string;
  created_at: Date;
  expired_at: Date;
  stripe_card_id: string;

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.created_at) {
      this.created_at = moment(this.created_at).toDate();
    }
    this.expired_at = moment(`${this.exp_year}-${this.exp_month}`).endOf('month').toDate();
    return this;
  }
}
