import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from 'app/_components/base.component';
import { AuthenticationService, CompanyService } from 'app/_services';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent extends BaseComponent implements OnInit {
  @Input() breadcrumbs: string[];

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit() {
  }

  hasLink(breadcrumb) {
    if (breadcrumb && typeof breadcrumb === 'object' && breadcrumb.breadcrumbLink) {
      return true;
    }
    return false;
  }

  getBreadcrumbTitle(breadcrumb) {
    if (breadcrumb && typeof breadcrumb === 'object') {
      return breadcrumb.breadcrumbTitle;
    }
    return breadcrumb;
  }

  getBreadcrumbLink(breadcrumb) {
    return breadcrumb.breadcrumbLink;
  }

}
