import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class JobTypeService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http, `${environment.apiBaseUrl}/job_types`);
  }
}
