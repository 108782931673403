import { StaffShadow } from './staff_shadow.model';
import { StaffShadowSchedule } from './staff_shadow_schedule.model';
import { Base } from './_base.model';

export class Staff extends Base {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  user: any;
  shadows: StaffShadow[];
  shadowed: boolean;
  shadow_schedules: StaffShadowSchedule[];
  title: string;
  office_phone: string;
  cell_phone: string;
  emergency_phone: string;
  voice_mail: string;
  fax: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  can_account: boolean;
  is_super: boolean;
  is_it: boolean;
  core: boolean;
  srg: boolean;
  aggregator: boolean;
  speaker_submission: boolean;
  archived: boolean;
  agency_id: string;
  email_platform_updates: boolean;
  photo: string;
  photo_file: any;
  become_another_user: any;

  constructor() {
    super();
    this.user = {};
    this.shadows = [];
    this.shadow_schedules = [];
  }
}
