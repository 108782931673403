import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { mapUploadProgress } from 'app/_utils/helpers';

@Injectable()
export class S3Service {

  constructor(
    protected http: HttpClient,
  ) { }

  putObject(signedUrl, file): Observable<any> {
    return this.http.put<any>(signedUrl, file, {
      reportProgress: true,
      observe: 'events',
    }).pipe(mapUploadProgress, filter(x => x !== undefined));
  }
}
