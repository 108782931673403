import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, CompanyService } from '../_services';

import { getErrorMessage } from 'app/_utils/helpers';
import { BaseComponent } from 'app/_components/base.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})

export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  username: string;
  email: string;
  loading = false;
  successUsername = false;
  errorUsername = '';
  successPassword = false;
  errorPassword = '';

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit() {
    if (this.authenticationService.id()) {
      this.authenticationService.logout(true);
    }
  }

  forgotPassword() {
    this.loading = true;
    this.authenticationService.forgotPassword(this.username)
      .subscribe(result => {
        this.loading = false;
        this.errorPassword = '';
        this.successPassword = true;
      }, err => {
        this.loading = false;
        this.errorPassword = getErrorMessage(err);
      });
  }

  forgotUsername() {
    this.loading = true;
    this.authenticationService.forgotUsername(this.email)
      .subscribe(result => {
        this.loading = false;
        this.errorUsername = '';
        this.successUsername = true;
      }, err => {
        this.loading = false;
        this.errorUsername = getErrorMessage(err);
      });
  }
}
