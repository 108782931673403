import { Base } from './_base.model';

export class Assistant extends Base {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  fax: string;
  username: string;
  password: string;
  access_events: boolean;
}
