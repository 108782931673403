import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable()
export class ProfileService {
  private profileLoaded: boolean;
  constructor(
    private http: HttpClient,
  ) {
    this.profileLoaded = false;
  }

  isProfileLoaded() {
    return this.profileLoaded;
  }

  getProfile(): Observable<User> {
    const url = `${environment.apiBaseUrl}/profile`;
    return this.http.get<User>(url).pipe(
      tap(() => {
        this.profileLoaded = true;
      }),
    );
  }

  updateProfile(user): Observable<User> {
    const url = `${environment.apiBaseUrl}/profile`;
    return this.http.put<User>(url, user);
  }

  updateDefaultSalesforce(user): Observable<User> {
    const url = `${environment.apiBaseUrl}/profile/default_salesforce`;
    return this.http.put<User>(url, user);
  }

  updatePassword(data): Observable<any> {
    const url = `${environment.apiBaseUrl}/profile/password`;
    return this.http.put<User>(url, data);
  }
}
