import { Base } from './_base.model';

export class Timezone extends Base {
  id!: string;
  label!: string;
  abbr!: string;

  constructor() {
    super();
  }
}
