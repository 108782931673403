<div class="fileinput input-group" data-provides="fileinput"
  [ngClass]="{'fileinput-exists': _file, 'fileinput-new': !_file}">
  <div class="form-control" data-trigger="fileinput">
    <i class="glyphicon glyphicon-file fileinput-exists"></i>
    <span class="fileinput-filename">{{_filename}}</span>
  </div>
  <span class="input-group-addon btn btn-secondary btn-file">
    <span class="fileinput-new">Select file</span>
    <span class="fileinput-exists">Change</span>
    <input type="file" name="file" (change)="onFileChange($event)">
  </span>
  <a href="javascript:void(0)" class="input-group-addon btn btn-secondary fileinput-exists" data-dismiss="fileinput"
    (click)="onFileRemove()">Remove</a>
</div>