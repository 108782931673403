<div class="row mt-2" *ngIf="!hasDedicatedQuestion()">
  <div class="col-2">
    <div style="padding-left: {{20*depth}}px">
      <select name="question_type_id" [(ngModel)]="newQuestion.type_id" class="form-control">
        <ng-template ngFor let-questionType [ngForOf]='questionTypes'>
          <option [value]="questionType.id"
            *ngIf="(!questionType.dedicated_screen || (!questions.length && depth == 0)) && (!questionType.secondary_event_disabled || priority !== 'secondary_event')">
            {{questionType.label}}</option>
        </ng-template>
      </select>
    </div>
  </div>
  <div class="col-2">
    <select *ngIf="newQuestion.type_id == 'YN'" class="form-control" name="question_sub_type"
      [(ngModel)]="newQuestion.sub_type">
      <ng-template ngFor let-type [ngForOf]="REQUEST_FORM_SUB_TYPE">
        <option [value]="type.value">{{type.label}}</option>
      </ng-template>
    </select>
    <select class="form-control mt-2" *ngIf="newQuestion.sub_type == 'IFYESSKIP' || newQuestion.sub_type == 'IFNOSKIP'"
      [(ngModel)]="newQuestion.skipScreen">
      <ng-template ngFor let-screen [ngForOf]="nextScreens">
        <option [value]="screen.index">{{screen.name}}</option>
      </ng-template>
      <option value="END">END</option>
    </select>
  </div>
  <div class="col-5">
    <textarea name="question_text" class="form-control" [(ngModel)]="newQuestion.text"></textarea>
    <ng-template [ngIf]="newQuestion.type_id && newQuestion.type_id.includes('MULTI')">
      <p class="text-center">Choices:</p>
      <textarea rows="4" cols="60" name="choices-{{i}}" class="form-control"
        [(ngModel)]="newQuestion.choices"></textarea>
    </ng-template>
  </div>
  <div class="col-1"></div>
  <div class="col-1"></div>
  <div class="col-1">
    <button type="button" class="btn btn-primary" (click)="onAddNewQuestion()">
      <i class="fa fa-plus"></i>
    </button>
  </div>
</div>