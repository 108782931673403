import { Base } from './_base.model';
import { AttendeeAddress } from './attendee_address.model';
import { AttendeeLicense } from './attendee_license.model';

export class Attendee extends Base {
  id: number;
  contact_id: string;
  call_plan_id: number;
  clientid: string;
  npi_no: string;
  npi_no_missing: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  title: string;
  email: string;
  cell_phone: string;
  degrees: string[];
  other_degree: string;
  profession: string;
  specialties: string[];
  other_specialties: string;
  licenses: AttendeeLicense[];
  address: AttendeeAddress;
  suffix: string;
  hcp_type: string;

  segment: string;
  government_employee: Boolean;
  alignment: string;
  communication: string;
  attendee_type: string = 'Attendee';
  meal_opt_out: Boolean;
  signed: Boolean;
  signature: string;
  walked_in: Boolean;
  is_virtual: Boolean;
  is_virtual_attended: Boolean;

  invited_status_id: string;
  registered_status_id: string;
  attended_status_id: string;
  attended_sub_status_id: string | null;
  photo: string;
  verified: Date;

  constructor() {
    super();
    this.degrees = [''];
    this.specialties = [''];
    this.licenses = [new AttendeeLicense()];
    this.address = new AttendeeAddress();
  }

  fromCallPlan(callPlan) {
    this.attendee_type = 'Attendee';
    this.call_plan_id = callPlan.id;
    this.clientid = callPlan.client_id;
    this.npi_no = callPlan.npi_id;
    this.first_name = callPlan.first_name;
    this.last_name = callPlan.last_name;
    if (callPlan.degree) {
      this.degrees = [callPlan.degree];
    }
    if (callPlan.specialty) {
      this.specialties = [callPlan.specialty];
    }
    this.segment = callPlan.segment;
    this.licenses[0].license = callPlan.state_license_number;
    this.licenses[0].state = callPlan.state;
    this.address.address1 = callPlan.address1;
    this.address.address2 = callPlan.address2;
    this.address.city = callPlan.city;
    this.address.state = callPlan.state;
    this.address.zip = callPlan.zip;
    this.address.email = callPlan.email;
    return this;
  }

  fromContact(contact) {
    this.attendee_type = 'Attendee';
    this.contact_id = contact.id;
    this.clientid = contact.client_id;
    this.npi_no = contact.npi_no;
    this.first_name = contact.first_name;
    this.last_name = contact.last_name;
    if (contact.degree) {
      this.degrees = [contact.degree];
    }
    if (contact.specialties) {
      this.specialties = contact.specialties;
    }
    this.segment = contact.segment;
    this.licenses[0].license = contact.state_license_number;
    this.licenses[0].state = contact.state;
    this.address.address1 = contact.addresses.address1;
    this.address.address2 = contact.addresses.address2;
    this.address.city = contact.addresses[0]?.city;
    this.address.state = contact.addresses[0]?.state;
    this.address.zip = contact.addresses[0]?.zip;
    this.address.email = contact.email;
    return this;
  }

  deserialize(obj: any) {
    Object.assign(this, obj);
    ['degrees', 'specialties'].forEach((field) => {
      if (!this[field] || !this[field].length) {
        this[field] = [''];
      }
    });
    if (!this.address) {
      this.address = new AttendeeAddress();
    }
    return this;
  }

  full_name(): string {
    return ['first_name', 'last_name'].map(x => this[x]).filter(Boolean).join(' ') || 'N/A';
  }

  long_full_name(): string {
    return [this.full_name(), this.degrees].filter(Boolean).join(', ') || 'N/A';
  }

  last_name_first(): string {
    return ['last_name', 'first_name'].map(x => this[x]).filter(Boolean).join(', ') || 'N/A';
  }
}
