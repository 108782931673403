import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: '[appMultiEmails]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: MultiEmailsValidatorDirective,
    multi: true
  }]
})
export class MultiEmailsValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const emails = control.value;
    if (!emails || emails.length == 0) {
      return null;
    }
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const emailList = emails.split(',');
    for (let i = 0; i < emailList.length; i++) {
      const result = regex.test(emailList[i]);
      if (!result) {
        return { invalidEmails: true };
      }
    }
    return null;
  }
}