import moment from 'moment';
import { Base } from './_base.model';
import { Agency } from './agency.model';

export class CompanySalesforceAgency extends Base {
  id: number;
  agency: Agency;
  company_id: string;
  agency_id: string;
  permission: number;
  salesforce_id: string;
  start_date: Date;
  same_as_parent: boolean;

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.start_date) {
      this.start_date = moment(this.start_date).toDate();
    }
    return this;
  }
}
