import { Base } from './_base.model';

export class User extends Base {
  id: number;
  role: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  default_company_id: string;
  default_salesforce_id: string;
  photo: string;
  photo_file: any;
  title: string;
  cell_phone: string;
  staff_id: string;
  shadowed: boolean;
}
