<div class="modal-header">
  <h5 class="modal-title"> {{title}}</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="hideConfirmationModal()"></button>
</div>
<div class="modal-body">
  <div [innerHTML]="body"></div>
</div>
<div class="modal-footer">
  <form (ngSubmit)="onConfirm()" *ngIf="active">
    <div class="text-end">
      <button type="button" class="btn btn-default" (click)="onCancel()">{{noButton}}</button>
      &nbsp;
      <button type="submit" class="btn btn-success">{{yesButton}}</button>
    </div>
  </form>
</div>