import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Vendor } from 'app/_models';
import { VendorService } from 'app/_services';

import { getErrorMessage } from 'app/_utils/helpers';
import { VendorEditFormComponent } from '../vendor-edit-form/vendor-edit-form.component';

@Component({
  selector: 'app-vendor-search-modal',
  templateUrl: './vendor-search-modal.component.html',
})
export class VendorSearchModalComponent implements OnInit {
  @ViewChild('vendorEditForm') vendorEditForm: VendorEditFormComponent;
  public event: EventEmitter<any> = new EventEmitter();
  searchForm = new FormGroup({
    name: new FormControl(''),
    type_id: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
  });
  title: string = 'Select a Vendor';
  catereringByVenueCheckbox: Boolean = false;
  vendors: any[];
  selected: any[] = [];
  catereringByVenue: Boolean = false;

  enableCreate: Boolean;
  multiSelectable: Boolean;
  states: any[];
  typeId: string;
  vendorTypes: any[];
  allVendorTypes: any[];
  vendorStatuses: any[];
  companyId: string;

  newVendor: Vendor;
  showNearby: Boolean = false;
  latitude: number;
  longitude: number;
  meetingTypeId: any = null;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private vendorService: VendorService,
  ) { }

  ngOnInit() {
    // if (this.vendorTypes.length) {
    if (this.typeId) {
      this.searchForm.get('type_id')?.setValue(this.typeId);
    }
    if (this.showNearby) {
      this.loadNearbyVendors();
    }
  }

  loadNearbyVendors() {
    this.vendorService.listPage({
      company_id: this.companyId
    }, {
      type_id: 'restaurant,hotel',
      latitude: this.latitude,
      longitude: this.longitude,
      meeting_type_id: this.meetingTypeId,
      requestable: !!this.meetingTypeId,
      area_scope: 'nearby',
      distance: 1000,
      sortField: 'distance',
      sortDir: 'asc',
      limit: 21,
    }, {})
      .subscribe((res) => {
        this.vendors = res.vendors.slice(1);
        this.selected = [];
      });
  }

  onSearch() {
    this.vendorService.listPage({
      company_id: this.companyId,
    }, {
      ...this.searchForm.value,
      meeting_type_id: this.meetingTypeId,
      requestable: !!this.meetingTypeId,
      limit: 100,
    }, {}).subscribe(res => {
      this.vendors = res.vendors;
      this.selected = [];
    });
  }

  toggleVendor(vendor) {
    if (this.multiSelectable) {
      if (this.isSelected(vendor)) {
        const index = this.selected.findIndex((obj) => obj.id === vendor.id);
        this.selected.splice(index, 1);
      } else {
        this.selected.push(vendor);
      }
    } else {
      this.selected = [vendor];
    }
  }

  isSelected(vendor): Boolean {
    return this.selected && this.selected.find(obj => obj.id === vendor.id);
  }

  hasSelected(): Boolean {
    return this.selected && this.selected.length > 0;
  }

  submitSelected() {
    this.bsModalRef.hide();
    if (this.catereringByVenue) {
      this.event.emit({
        caterering_by_venue: true
      });
    } else {
      this.event.emit(this.selected);
    }
  }

  createNew() {
    this.newVendor = new Vendor();
  }

  backToSearch() {
    this.newVendor = null;
  }

  onCreateSubmit() {
    this.vendorService.create(this.newVendor, { company_id: this.companyId })
      .subscribe(vendor => {
        this.bsModalRef.hide();
        this.event.emit([vendor]);
      }, err => {
        this.toastr.error(getErrorMessage(err));
      });
  }
}
