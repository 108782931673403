import { Base } from './_base.model';

export class PreRequestForm extends Base {
  id: number;
  default_meeting_type_id: number;
  active: Boolean;
  salesforces: any[];
  screens: any;

  constructor() {
    super();
    this.screens = [];
  }
}
