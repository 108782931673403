import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'areaFullName' })
export class AreaFullNamePipe implements PipeTransform {
  transform(area: any): string {
    if (!area) {
      return 'N/A';
    }
    return `${area.description} (${area.name})`;
  }
}
