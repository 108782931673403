import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class ChartService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/charts`);
  }
  getData(name: string, apiParams: any = {}): Observable<any[]> {
    const url = sprintf(this.baseUrl) + `/${name}`;
    return this.http.get<any>(url, {
      params: this.toHttpParams(apiParams)
    });
  }
}
