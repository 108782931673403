import { Base } from './_base.model';

export class Trigger extends Base {
  id: string;
  name: string;
  category: string;
  triggering_action: string | null = null;
  active: boolean = true;
  event_status_reason: string | null = null;

  conditions: any[] = [];
  response_event_status_id: string | null = null;
  response_event_sub_status_id: string | null = null;
  is_default: boolean;
  response_checklists: any[] = [];
  response_reverse_checklists: any[] = [];
  response_notifications: any[] = [];
  response_emails: any[] = [];
  response_common_emails: any[] = [];
  response_common_notifications: any[] = [];
  common_emails: any[] = [];
  common_notification: any[] = [];
}
