import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'creditCardNumber' })
export class CreditCardNumberPipe implements PipeTransform {
  transform(input: string): string {
    if (!input) {
      return input;
    }
    let result = '';
    if (input.length === 15) {
      const nodes = [0, 4, 10, 15];
      for (let i = 0; i < nodes.length - 1; i ++){
        result += input.slice(nodes[i], nodes[i + 1]) + ' ';
      }
    } else {
      for (let i = 0; i < input.length; i += 4) {
        result += input.substr(i, 4) + ' ';
      }
    }
    return result;
  }
}
