import { Base } from './_base.model';

export class CoreEventForm extends Base {
  id: number;
  name: string;
  is_default: boolean;
  rank: number;
  sections: any[];

  constructor() {
    super();
    this.sections = [];
  }
}