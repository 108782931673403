import { Base } from './_base.model';

export class Agency extends Base {
  id: string;
  name: string;
  status: string;
  contact_name: string;
  contact_phone: string;
  contact_phone_after_hours: string;
  contact_email: string;
  web_address: string;
  companies: any;
  splash_page: string;
  message: string;
  image: string;
  image_file: string | ArrayBuffer;
  trusted_public_events: boolean;
  trusted_call_plan: boolean;
  travel_agent_id: string;
  av_vendor_id: string;

  constructor() {
    super();
    this.splash_page = '';
    this.message = '';
  }
}