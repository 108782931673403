import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { BaseComponent } from 'app/_components/base.component';

import { AuthenticationService, CompanyService } from 'app/_services';

@Component({
  selector: 'app-request-question-row-edit',
  templateUrl: './request-question-row-edit.component.html',
  styleUrls: ['./request-question-row-edit.component.scss']
})
export class RequestQuestionRowEditComponent extends BaseComponent implements OnInit {
  @Input() priority: string;
  @Input() questions: any[];
  @Input() depth: number;
  @Input() question: any;
  @Input() questionTypes: any[];
  @Input() dedicatedQuestionTypes: any[];
  @Input() nextScreens: any[];
  @Output() delete: EventEmitter<any> = new EventEmitter();
  newQuestion: any;

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit() {
    this.newQuestion = {
      type_id: null,
      sub_type: null,
      text: '',
      packages: null,
      equipments: null,
      min: 1,
      max: 1,
      skipScreen: -1,
      subQuestions: []
    };
  }

  getScreen() {
    return this.question.skipScreen == 'END' ? { name: 'END' } : this.nextScreens.filter(item => item.index == this.question.skipScreen)[0];
  }

  onDeleteQ(index) {
    this.question.subQuestions.splice(index, 1);
  }

  needOptionalCheckbox(question) {
    return question.type_id === 'AMOUNT' || question.type_id === 'CHECKBOX'
      || question.type_id === 'TEXT_FIELD' || question.type_id === 'PHONE_NUMBER'
      || question.type_id === 'TEXT_AREA' || question.type_id === 'state';
  }

  Delete() {
    this.delete.emit();
  }

  drop(q: CdkDragDrop<any[]>) {
    const question = q.item.data;
    if (q.container.id === q.previousContainer.id) {
      this.question.subQuestions[q.previousIndex] = this.question.subQuestions[q.currentIndex];
      this.question.subQuestions[q.previousIndex].rank = q.previousIndex + 1
      this.question.subQuestions[q.currentIndex] = question;
      this.question.subQuestions[q.currentIndex].rank = q.currentIndex + 1;
    }
  }
}
