import { Base } from './_base.model';

export class PublicTask extends Base {
  id: number;
  text: string;
  status: string;
  url: string;
  action: string;
  rank: number;
  deleted: boolean;

  deserialize(obj: any) {
    Object.assign(this, obj);
    return this;
  }
}

