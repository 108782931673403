import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService extends BaseService {
  private subject = new Subject<void>();
  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/my/notifications`);
  }
  sendUpdatedEvent() {
    this.subject.next();
  }
  getUpdatedEvent(): Observable<void> {
    return this.subject.asObservable();
  }
}