import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { ReportUser } from 'app/_models';

@Injectable()
export class ReportService extends BaseService {

  constructor(
    protected override http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/reports`);
  }

  download(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${urlParams.id}/download`;
    return this.http.post<ReportUser>(url, apiParams);
  }

  getLayout(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + '/layout';
    return this.http.get<any>(url, { params: this.toHttpParams(apiParams) });
  }

  reset(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${urlParams.id}/reset`;
    return this.http.post<ReportUser>(url, apiParams);
  }

  getArchives(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${urlParams.id}/archives`;
    return this.http.get<any>(url, apiParams);
  }
}
