import moment from 'moment';
import { Base } from './_base.model';

export class PresentationQrcode extends Base {
  id: string;
  name: string;
  html?: string;

  constructor() {
    super();
    this.html = '';
  }
  
}
