import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-request-question-preview',
  templateUrl: './request-question-preview.component.html',
})
export class RequestQuestionPreviewComponent implements OnInit {
  @Input() question: any;
  @Input() answers: any;
  @Input() screenIndex: number;
  @Input() questionIndex: number;
  @Input() dedicatedQuestionTypes: any[];
  @Input() depth: number;

  ngOnInit() {

  }
}
