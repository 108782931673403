import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

@Injectable()
export class HourlyRateService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/hourly_rates`);
  }

  override update(model: any, urlParams?: any, apiParams?: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams);
    return this.http.post(url, model, {
      params: this.toHttpParams(apiParams)
    });
  }
}
