import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { StaticContent } from 'app/_models';
import { StaticContentService } from '../_services';
import { COMPANY_NAME } from 'app/app.constants';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
})

export class StaticPageComponent implements OnInit {
  COMPANY_NAME = COMPANY_NAME;
  staticContent: StaticContent | null = null;
  loading = false;
  error = '';

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private staticContentService: StaticContentService,
  ) {
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.staticContentService.getPublic(params['id']).subscribe((staticContent) => {
          this.staticContent = new StaticContent().deserialize(staticContent);
          this.titleService.setTitle(this.staticContent.title || this.COMPANY_NAME);
        });
      }
    });
  }

  ngOnInit() {

  }
}
