import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'addressLines' })
export class AddressLinesPipe implements PipeTransform {
  transform(input: any): string {
    if (!input) {
      return input;
    }
    let lines = '';
    if (input.address1) {
      lines = input.address1;
    }
    if (input.address2) {
      lines += ' ' + input.address2;
    }
    return lines;
  }
}
