import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-icon',
  templateUrl: './file-icon.component.html',
})
export class FileIconComponent {
  @Input() file: string;
  @Input() title: string;

  get extension() {
    if (this.file) {
      const path = this.file.toLowerCase().split('?')[0];
      if (path.lastIndexOf('.') >= 0) {
        return path.substr(path.lastIndexOf('.') + 1);
      }
    }
    return null;
  }

  isDoc() {
    return this.extension === 'doc' || this.extension === 'docx';
  }

  isPdf() {
    return this.extension === 'pdf';
  }

  isPpt() {
    return this.extension === 'ppt' || this.extension === 'pptx';
  }
}
