import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserIdleService } from 'angular-user-idle';

import { AuthenticationService, CompanyService } from './_services';
import { BaseComponent } from './_components/base.component';
import { getGotoQueryParams } from './_utils/helpers';

@Component({
  selector: 'app-user-idle-modal',
  templateUrl: './user-idle-modal.component.html',
})
export class UserIdleModalComponent extends BaseComponent implements OnInit {
  idle: number;
  timeout: number;
  count: number;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    public bsModalRef: BsModalRef,
    private userIdleService: UserIdleService,
    private location: Location,
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit() {
    this.count = 0;
    // Start watch when time is up.
    this.userIdleService.onTimeout().subscribe(() => {
      this.bsModalRef.hide();
      this.authenticationService.logout();
      this.location.replaceState('/');
      this.router.navigate(['login'], { queryParams: getGotoQueryParams(this.router.url) });
      this.userIdleService.resetTimer();
    });
  }

  onCancel() {
    this.userIdleService.stopTimer();
    this.userIdleService.resetTimer();
    this.bsModalRef.hide();
  }
}
