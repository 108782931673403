import moment from 'moment';
import { Base } from './_base.model';

export class Alert extends Base {
  id: number;
  salesforces: any[];
  usertypes: string[];
  header: string;
  message: string;
  archived: boolean;
  status: string;
  start_date: Date;
  expiration_date: Date;
  created_at: Date;
  updated_at: Date;
  updated_by: any;
  days_after_opening: number;

  deserialize(obj: any) {
    if (obj.alert_salesforces) {
      obj.salesforces = obj.alert_salesforces.map(c => c.salesforce_id);
      delete obj.alert_salesforces;
    }
    Object.assign(this, obj);
    if (this.start_date) {
      this.start_date = moment(this.start_date).toDate();
    }
    if (this.expiration_date) {
      this.expiration_date = moment(this.expiration_date).toDate();
    }
    return this;
  }
}
