import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { ToastrService } from 'ngx-toastr';

import { AuthenticationService, CompanyService, Auth0Service } from '../_services';
import { getErrorMessage, getGotoQueryParams, loadAppleAuthScript } from 'app/_utils/helpers';
import { BaseComponent } from 'app/_components/base.component';
import { LOGIN_GOTO_QUERY_PARAM } from 'app/app.constants';
import { environment } from 'environments/environment';

declare var AppleID: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit, AfterViewInit {
  model: any = {};
  loading = false;
  error = '';

  handleLogin = (result) => {
    this.loading = false;
    this.error = '';
    this.router.navigateByUrl(this.getGotoUrl());
  }

  handleError = (err) => {
    this.loading = false;
    this.error = getErrorMessage(err);
  }

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    private route: ActivatedRoute,
    private socialAuthService: SocialAuthService,
    private toastr: ToastrService,
    private auth0Service: Auth0Service,
  ) {
    super(router, authenticationService, companyService);
  }

  getGotoUrl() {
    return getGotoQueryParams(this.route.snapshot.queryParams[LOGIN_GOTO_QUERY_PARAM] || '/')[LOGIN_GOTO_QUERY_PARAM];
  }

  ngOnInit() {
    if (this.authenticationService.id()) {
      this.router.navigateByUrl(this.getGotoUrl());
    } else {
      this.socialAuthService.authState.subscribe((user) => {
        if (user.provider === GoogleLoginProvider.PROVIDER_ID) {
          this.authenticationService.loginWithGoogle({
            token: user.idToken,
          }).subscribe(this.handleLogin, this.handleError);
        }
        if (user.provider === FacebookLoginProvider.PROVIDER_ID) {
          this.authenticationService.loginWithFacebook({
            token: user.authToken,
          }).subscribe(this.handleLogin, this.handleError);
        }
      });
      this.auth0Service.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.authenticationService.loginWithAuth0({
            token: authResult.idToken,
          }).subscribe(this.handleLogin, this.handleError);
        } else if (err) {
          console.log(err);
          this.router.navigate(['/login']);
        }
      });
    }
  }

  ngAfterViewInit() {
    loadAppleAuthScript(() => {
      AppleID.auth.init({
        clientId: environment.APPLE_CLIENT_ID,
        scope: 'name email',
        redirectURI: environment.APPLE_REDIRECT_URI,
        // state: '[STATE]',
        // nonce: '[NONCE]',
        usePopup: true
      });
    });
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  async signinWithApple() {
    try {
      const data = await AppleID.auth.signIn();
      this.authenticationService.loginWithApple(data).subscribe(this.handleLogin, this.handleError);
    } catch (err) {
      console.log(err);
    }
  }

  signinWithAuth0() {
    this.auth0Service.login();
  }

  signinWithFacebook() {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.model)
      .subscribe(this.handleLogin, this.handleError);
  }
}
