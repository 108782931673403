import { Base } from './_base.model';

export class EsifForm extends Base {
  id: number;
  fiscal_year_ids: string[];
  html: any;
  w9: string;
  docx: string;
  meta: any;

  constructor() {
    super();
    this.html = [];
  }
}
