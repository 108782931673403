<a [popover]="vendorPopover" id="{{popoverLinkId}}" placement="bottom" href="javascript:void(0)" class="flag-popup"
  (click)="onClick()" triggers="" #pop="bs-popover">
  <ng-content></ng-content>
</a>
<ng-template #vendorPopover>
  <div class="row" id="{{popoverContainerId}}" style="width: 500px">
    <div class="col-4">
      <div>{{vendor?.name}}</div>
      <div>{{vendor?.code}}</div>
      <div *ngFor="let vendorType of vendorTypes">
        <span *ngIf="vendorType.id==vendor?.type_id">{{vendorType.label}}</span>
      </div>
      <span>{{vendor?.address1}}</span>
      <div>{{vendor?.address2}}</div>
      <div>{{vendor?.city}}</div>
      <div>{{vendor?.state}}</div>
      <div>{{vendor?.zip}}</div>
    </div>
    <div class="col-4">
      <div>{{vendor?.contact_name | fullName}}</div>
      <div>{{vendor?.phone}}</div>
      <div>{{vendor?.fax}}</div>
      <div>{{vendor?.fax}}</div>
      <div>{{vendor?.email}}</div>
      <div *ngIf="vendor?.web_address">
        <a target="_blank" href="{{vendor?.web_address}}"></a>
      </div>
      <div *ngFor="let vendorStatus of vendorStatuses">
        <span *ngIf="vendorStatus.id==vendor?.status_id">{{vendorStatus.label}}</span>
      </div>
    </div>
    <div class="col-4">
      <div class="mb-3">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" name="valet_parking" [checked]="vendor?.valet_parking"
            disabled>
          <label class="form-check-label" for="valet_parking">Valet Parking</label>
        </div>
      </div>
      <div class="mb-3">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" name="off_street_parking"
            [checked]="vendor?.off_street_parking" disabled>
          <label class="form-check-label" for="off_street_parking">Off Street Parking</label>
        </div>
      </div>
      <div class="mb-2">
        <a [routerLink]="link" [queryParams]="{ popup: 'change'}" class="btn btn-sm btn-primary">Change</a>
        <a [routerLink]="link" [queryParams]="{ popup: 'edit'}" class="btn btn-sm btn-secondary">Edit</a>
      </div>
      <div class="mb-3">
        <a href="https://maps.google.com?q={{vendor?.address1}}+{{vendor?.address2}}+{{vendor?.city}}+{{vendor?.state}}+{{vendor?.zip}}&z=16" class="btn btn-sm btn-danger" target="_blank">View Map</a>
      </div>
    </div>
  </div>
</ng-template>