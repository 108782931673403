import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import moment from 'moment';

import { REQUEST_PRIORITIES } from 'app/app.constants';
import { getErrorMessage } from 'app/_utils/helpers';

import {
  AuthenticationService,
  CompanyService,
  ClientService,
  MeetingTypeService,
  TopicService,
  DropdownService,
  RequestFormService,
  VendorService,
  RequestService,
  PreRequestFormService,
  EquipmentService,
} from 'app/_services';

import {
  Client,
  Request,
  MeetingType,
  Topic,
  Speaker,
  Vendor,
  PreRequestForm,
  RequestForm,
} from 'app/_models';

import { BaseComponent } from 'app/_components/base.component';
import { CallPlanSearchModalComponent } from 'app/_components/call_plan-search-modal/call_plan-search-modal.component';
import { CallPlanNewModalComponent } from '../call_plan-search-modal/call_plan-new-modal.component';

import { DatatablesUtils } from 'app/_utils/datatables';

import { moveIn } from '../../router.animations';

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.css'],
  animations: [moveIn()],
})
export class RequestFormComponent extends BaseComponent implements OnInit {
  @Input() model: Request;
  @Input() returnUrl: string;
  @Input() returnUrlParams: any = {};
  @Input() previewEvent: any;

  steps: any[] = [];

  preRequestForm: PreRequestForm;

  states: any[];
  professions: any[];
  degrees: any[];
  specialties: any[];

  meetingTypes: MeetingType[];
  meetingType: MeetingType;

  requestForms: RequestForm[];

  priorities: any[];

  clients: Client[];
  dtOptionsClients: DataTables.Settings = {};
  client: Client;

  dedicatedQuestionTypes: string[];

  speakers: Speaker[];
  speakersExtended: any;
  dtOptionsSpeakers: DataTables.Settings = {};
  selectedSpeakers: Speaker[] = [];

  topics: Topic[];
  topic: Topic;

  minDate: Date | null;
  maxDate: Date | null;
  datePickerDisabled: Boolean;
  timezones: any[];
  event_time: Date;
  selectedEventDates: any[];

  speakerEvents: any[] = [];

  venues: Vendor[];
  dtOptionsVenues: DataTables.Settings = {};
  selectedVenues: Vendor[];

  caterers: Vendor[];
  dtOptionsCaterers: DataTables.Settings = {};
  caterer: Vendor;

  avEquipments: any[];
  selectedAvEquipments: any[];

  bsAttendeeSearchModalRef: BsModalRef;
  bsAttendeeNewModalRef: BsModalRef;

  toLastStep: boolean;
  priorityAutomaticallySelected: boolean;

  @Input() previewModalFlag: boolean;

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    private clientService: ClientService,
    private meetingTypeService: MeetingTypeService,
    private topicService: TopicService,
    private dropdownService: DropdownService,
    private requestFormService: RequestFormService,
    private preRequestFormService: PreRequestFormService,
    private vendorService: VendorService,
    private requestService: RequestService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private equipmentService: EquipmentService,
  ) {
    super(router, authenticationService, companyService);
    this.toLastStep = false;
  }

  ngOnInit() {
    this.dedicatedQuestionTypes = [];
    this.dropdownService.getDropdowns('CountryState,profession,attendee_degree,attendee_specialty').subscribe((lists) => {
      this.states = lists.CountryState;
      this.degrees = lists.attendee_degree;
      this.specialties = lists.attendee_specialty;
      this.professions = lists.profession;
    });
    this.dropdownService.getDropdownsForCompany(this.getCurrentCompanyId(), 'Timezone,RequestFormQuestionType').subscribe((list) => {
      this.timezones = list.Timezone;
      this.dedicatedQuestionTypes = list.RequestFormQuestionType.filter(x => x.dedicated_screen).map(x => x.id);
    });
    this.preRequestFormService.list({
      company_id: this.getCurrentCompanyId()
    }, {
      salesforce_id: this.getCurrentSalesforceId()
    }).subscribe((res) => {
      if (res && res.length) {
        this.preRequestForm = res[0];
      }
    });
    this.dtOptionsClients = DatatablesUtils.buildDatatablesSettings({
      columns: [
        { data: null, searchable: false, orderable: false },
        { data: 'last_name' },
        { data: 'first_name' },
        { data: null, orderable: false },
        { data: 'title' },
        { data: 'email' },
        { data: 'office_phone' },
      ],
      order: [1, 'asc'],
      processing: true,
      serverSide: true,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        this.clientService.listPage({
          company_id: this.getCurrentCompanyId()
        }, {
          salesforce_id: this.getCurrentSalesforceId(),
          area_required: true,
          can_request: true,
        }, dataTablesParameters)
          .subscribe(res => {
            this.clients = res.clients;
            if (this.model.client_id) {
              this.client = this.clients.find(c => c.id === this.model.client_id);
            }
            callback({
              draw: dataTablesParameters.draw,
              recordsTotal: res.total,
              recordsFiltered: res.total,
              data: []
            });
          });
      },
    });
    this.dtOptionsSpeakers = DatatablesUtils.buildDatatablesSettings({
      columns: [
        { data: null, searchable: false, orderable: false },
        { data: 'last_name' },
        { data: 'first_name' },
        { data: 'degrees', orderable: false },
        { data: 'city', orderable: false },
        { data: 'state', orderable: false },
        { data: 'affiliation', orderable: false },
      ],
      order: [1, 'asc'],
      processing: true,
      serverSide: true,
      paging: false,
      searching: false,
      info: false,
      ajax: (dataTablesParameters: any, callback) => {
        this.model.setEventDateTimeUTC(this.meetingType.duration_hours);
        this.requestService.getSpeakers({
          company_id: this.getCurrentCompanyId(),
        }, {
          salesforce_id: this.getCurrentSalesforceId(),
          meeting_type_id: this.meetingType.id,
          requested_topic_id: this.model.topic_id,
          event_start_at: this.model.request_datetimes[0] && this.model.request_datetimes[0].event_start_at ? this.model.request_datetimes[0].event_start_at.toISOString() : null,
          event_end_at: this.model.request_datetimes[0] && this.model.request_datetimes[0].event_end_at ? this.model.request_datetimes[0].event_end_at.toISOString() : null,
          requestable: true,
        }, dataTablesParameters)
          .subscribe(res => {
            this.speakers = res.speakers;
            callback({
              draw: dataTablesParameters.draw,
              recordsTotal: res.total,
              recordsFiltered: res.total,
              data: []
            });
          });
      },
    });
    this.dtOptionsVenues = DatatablesUtils.buildDatatablesSettings({
      columns: [
        { data: null, searchable: false, orderable: false },
        { data: 'name' },
        { data: 'city' },
        { data: 'state' },
        { data: 'zip' },
      ],
      order: [1, 'asc'],
      processing: true,
      serverSide: true,
      searching: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.vendorService.listPage({
          company_id: this.getCurrentCompanyId(),
        }, {
          type: 'venue',
          meeting_type_id: this.model.meeting_type_id,
          requestable: true,
        }, dataTablesParameters).subscribe(res => {
          this.venues = res.vendors;
          callback({
            draw: dataTablesParameters.draw,
            recordsTotal: res.total,
            recordsFiltered: res.total,
            data: []
          });
        });
      },
    });
    this.dtOptionsCaterers = DatatablesUtils.buildDatatablesSettings({
      columns: [
        { data: null, searchable: false, orderable: false },
        { data: 'name' },
        { data: 'city' },
        { data: 'state' },
        { data: 'zip' },
      ],
      order: [1, 'asc'],
      processing: true,
      serverSide: true,
      searching: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.vendorService.listPage({
          company_id: this.getCurrentCompanyId(),
        }, {
          type: 'venue,caterer',
          meeting_type_id: this.model.meeting_type_id,
          requestable: true,
        }, dataTablesParameters).subscribe(res => {
          this.caterers = res.vendors;
          if (this.model.caterer_id) {
            this.caterer = this.caterers.find(c => c.id === this.model.caterer_id);
          }
          callback({
            draw: dataTablesParameters.draw,
            recordsTotal: res.total,
            recordsFiltered: res.total,
            data: []
          });
        });
      },
    });
    if (this.model.client) {
      this.client = this.model.client;
    }
    if (this.model.request_speakers) {
      this.selectedSpeakers = this.model.request_speakers.map(rs => rs.speaker);
    }
    if (this.model.request_venues) {
      this.selectedVenues = this.model.request_venues.map(rv => rv.venue);
    }
    if (this.model.request_datetimes) {
      this.selectedEventDates = this.model.request_datetimes.map((rd) => {
        const datetime = (rd.event_date && rd.event_time) ? new Date(`${rd.event_date} ${rd.event_time}`) : null;
        this.event_time = datetime;
        return {
          event_date: datetime
        }
      });
    }
    if (this.model.av_equipments) {
      this.selectedAvEquipments = this.model.av_equipments;
      this.model.av_equipments = this.model.av_equipments.map(e => e.av_equipment_id);
    }
    if (this.model.meeting_type) {
      this.meetingType = this.model.meeting_type;
    }
    if (this.model.topic) {
      this.topic = this.model.topic;
    }
    // Go to First page
    if (this.model.id && !this.previewModalFlag) {
      this.nextStep('client');
      this.toLastStep = true;
      this.clientSelected(this.model.client);
    } else {
      this.nextStep('client');
    }
  }

  get currentStep() {
    return this.steps[this.steps.length - 1];
  }

  isCurrentStepQuestions() {
    return (typeof this.currentStep === 'number');
  }

  isStepQuestionsNotNextable() {
    if (this.previewModalFlag) {
      return false;
    }

    const attendeeScreen = this.model.screens[this.currentStep].questions.find(q => q.type_id === 'attendees');
    if (attendeeScreen) {
      return this.model.attendees && this.model.attendees.length < (attendeeScreen.min || 1);
    }
    const speakerScreen = this.model.screens[this.currentStep].questions.find(q => q.type_id === 'speakers');
    if (speakerScreen) {
      return this.selectedSpeakers && this.selectedSpeakers.length < (speakerScreen.min || 1);
    }
    const venueScreen = this.model.screens[this.currentStep].questions.find(q => q.type_id === 'venue');
    if (venueScreen) {
      return this.selectedVenues && this.selectedVenues.length < (venueScreen.min || 1);
    }
    const datetimeScreen = this.model.screens[this.currentStep].questions.find(q => q.type_id === 'event_start_at');
    if (datetimeScreen) {
      return this.model.request_datetimes && this.model.request_datetimes.filter(dt => dt.event_date).length < (datetimeScreen.min || 1) || this.hasDuplicatedDates();
    }
    for (let i = 0; i < this.model.screens[this.currentStep].questions.length; i++) {
      const question = this.model.screens[this.currentStep].questions[i];
      if (this.dedicatedQuestionTypes.includes(question.type_id)) {
        continue;
      }
      if (!question.optional && (!this.model.answers[this.currentStep] || !this.model.answers[this.currentStep][i])) {
        return true;
      }
    }
    return false;
  }

  getRequiredDatesNum() {
    return this.model.screens[this.currentStep].questions.find(q => q.type_id === 'event_start_at').min || 1;
  }

  findSkipQuestion(questions, answers, prefix) {
    let skip = false;
    let question = null;
    for (let i = 0; i < questions.length; i++) {
      const quest = questions[i];
      const answer = answers[prefix + i];
      if (quest.subQuestions) {
        const result = this.findSkipQuestion(quest.subQuestions, answers, i + '_sub_');
        skip = result.skip;
        question = result.question;
      }
      if ((quest.sub_type == 'IFYESSKIP' && answer == 'Yes') || (quest.sub_type == 'IFNOSKIP' && answer == 'No')) {
        skip = true;
        question = quest;
        break;
      }
    }
    return { skip, question };
  }

  nextNumberStep(step) {
    // check attendee estimation from meeting type
    if (this.model.screens[step]) {
      if (this.model.screens[step].questions[0].type_id === 'attendee_count_estimate') {
        const minHcp = this.model.priority === 'secondary_event' ? this.meetingType.secondary_min_hcp : this.meetingType.min_hcp;
        if (this.model.answers[step][0] < minHcp) {
          this.toastr.error(`Minimum required attendance is ${minHcp}`);
          return;
        }
      }
    }
    
    let nextScreen = null;
    let skip = false;
    let question;
    if (this.model.screens[step] != undefined && this.model.answers[step] != undefined) {
      const result = this.findSkipQuestion(this.model.screens[step].questions, this.model.answers[step], '');
      skip = result.skip;
      question = result.question;
    }
    if (skip) {
      nextScreen = question.skipScreen == 'END' ? question.skipScreen : parseInt(question.skipScreen);
      this.clearAnswers(step, nextScreen);
    } else if (step === -1) {
      if (this.model.screens.length) {
        nextScreen = 0;
      } else {
        nextScreen = 'END';
      }
    } else {
      const branches = this.model.screens[step].branches;
      if (!branches || branches.length === 0) {
        nextScreen = 'END';
      } else {
        let defaultBranch = null;
        let foundBranch = null;
        branches.forEach((branch) => {
          if (!foundBranch) {
            if (branch.answers) {
              let match = true;
              Object.keys(branch.answers).forEach((q) => {
                if (!this.model.answers[step] || this.model.answers[step][q] !== branch.answers[q]) {
                  match = false;
                }
              });
              if (match) {
                foundBranch = branch;
              }
            } else {
              defaultBranch = branch;
            }
          }
        });
        if (!foundBranch) {
          foundBranch = defaultBranch;
        }
        nextScreen = foundBranch ? foundBranch.next_screen : 'END';
      }
    }
    if (nextScreen === 'END' || (typeof nextScreen === 'number' && nextScreen >= this.model.screens.length)) {
      this.nextStep('preview');
    } else {
      this.nextStep(nextScreen);
    }
  }

  nextStep(step) {
    if (typeof step === 'number') {
      this.loadScreenQuestions(step, (s, questionType) => {
        this.steps.push(step);
        if (this.toLastStep) {
          this.nextClicked();
        }
      });
    } else {
      this.steps.push(step);
      if (this.toLastStep) {
        this.nextClicked();
      }
    }
  }

  prevStep() {
    this.steps.pop();
    if (!this.model.id && this.currentStep === 'meeting_type' && this.preRequestForm && this.preRequestForm.screens && this.preRequestForm.screens.length) {
      this.steps.pop();
    }
  }

  firstStep() {
    while (this.steps.length > 1) {
      this.steps.pop();
    }
  }

  nextClicked() {
    if (this.currentStep === 'client') {
      this.client && this.clientSelected(this.client);
    } else if (this.currentStep === 'meeting_type') {
      this.meetingType && this.meetingTypeSelected(this.meetingType);
    } else if (this.currentStep === 'priority') {
      this.model.priority && this.prioritySelected(this.model.priority);
    } else if (this.isCurrentStepQuestions() && (this.toLastStep || !this.isStepQuestionsNotNextable())) {
      this.nextNumberStep(this.currentStep);
    } else if (this.isCurrentPreRequestQuestions()) {
      this.nextPreRequestStep();
    } else {
      this.toLastStep = false;
    }
  }

  closeClicked() {
    if (this.previewEvent) {
      this.previewEvent.emit();
    }
  }

  lastStep() {
    this.toLastStep = true;
    this.nextClicked();
  }

  getScreenIndex(typeId) {
    for (let i = 0; i < this.model.screens.length; i++) {
      if (this.model.screens[i].questions.find(q => q.type_id === typeId)) {
        return i;
      }
    }
    return null;
  }

  moveTo(step) {
    while (this.steps.length > 1 && this.currentStep !== step) {
      this.steps.pop();
    }
    if (this.currentStep !== step) {
      this.nextClicked();
      if (this.currentStep !== step) {
        this.nextStep(step);
      }
    }
  }

  clientSelected(client) {
    this.client = client;
    this.model.client_id = client.id;
    this.model.timezone_id = client.timezone_id;
    this.meetingTypeService.list({
      company_id: this.getCurrentCompanyId(),
    }, {
      salesforce_id: this.getCurrentSalesforceId(),
      client_id: client.id,
      requestable: true,
    })
      .subscribe(result => {
        this.meetingTypes = result;
        if (this.model.meeting_type_id) {
          this.meetingType = this.meetingTypes.find(m => m.id === this.model.meeting_type_id);
        }
        this.nextStep('meeting_type');
        if (!this.model.id && this.meetingTypes.length) {
          if (this.meetingTypes.length === 1) {
            this.meetingTypeSelected(this.meetingTypes[0]);
          } else if (this.preRequestForm && this.preRequestForm.screens && this.preRequestForm.screens.length) {
            this.model.pre_request_form_id = this.preRequestForm.id;
            this.model.pre_request_screens = this.preRequestForm.screens;
            this.nextStep('pre_request_form/0');
          }
        }
      });
  }

  isCurrentPreRequestQuestions() {
    return this.currentStep && this.currentStep.toString().includes('pre_request');
  }

  currentPreRequestStep() {
    return parseInt(this.currentStep.split('/')[1], 10);
  }

  nextPreRequestStep() {
    const i = this.currentPreRequestStep();
    const screen = this.model.pre_request_screens[i];
    const branches = screen.branches || [];
    let meetingTypeId = null;
    branches.forEach((branch) => {
      if (!meetingTypeId) {
        if (branch.answers) {
          let match = true;
          Object.keys(branch.answers).forEach((q) => {
            if (!this.model.pre_request_answers[i] || this.model.pre_request_answers[i][q] !== branch.answers[q]) {
              match = false;
            }
          });
          if (match) {
            meetingTypeId = branch.meeting_type_id;
          }
        }
      }
    });
    if (!meetingTypeId && i + 1 === this.model.pre_request_screens.length) {
      meetingTypeId = this.preRequestForm.default_meeting_type_id;
    }
    if (meetingTypeId) {
      this.meetingTypeSelected(this.meetingTypes.find(m => m.id.toString() === meetingTypeId.toString()));
    } else {
      this.nextStep('pre_request_form/' + (i + 1));
    }
  }

  meetingTypeSelected(meetingType) {
    this.model.meeting_type_id = meetingType.id;
    this.meetingType = meetingType;
    this.requestFormService.search({
      company_id: this.getCurrentCompanyId()
    }, {
      salesforce_id: this.getCurrentSalesforceId(),
      meeting_type_id: this.model.meeting_type_id,
    }).subscribe(request_forms => {
      if (request_forms.length > 0) {
        this.requestForms = request_forms;
        const formPriorities = this.requestForms.map(f => f.priority);
        this.priorities = REQUEST_PRIORITIES.filter(p => formPriorities.includes(p.id));
      } else {
        this.toastr.error('Failed to find proper request forms!');
        return;
      }
      if (request_forms.length === 1) {
        this.prioritySelected(request_forms[0].priority);
        this.priorityAutomaticallySelected = true;
      } else {
        this.nextStep('priority');
        this.priorityAutomaticallySelected = false;
      }
    });
  }

  prioritySelected(priority) {
    this.model.priority = priority;
    const request_form = this.requestForms.find(f => f.priority === priority);
    if (request_form) {
      this.model.request_form_id = request_form.id;
      this.model.screens = request_form.screens || [];
      this.model.screens.forEach((screen) => {
        screen.questions.sort((a, b) => (a.rank || 0) - (b.rank || 0));
      });
    } else {
      this.toastr.error('Failed to find a proper request form!');
      return;
    }
    this.nextNumberStep(-1);
  }

  loadScreenQuestions(step, cb) {
    const questions = this.model.screens[step].questions;
    const questionType = questions[0].type_id;
    if (questions[0].type_id === 'av_equipments') {
      this.equipmentService.list({
        company_id: this.getCurrentCompanyId(),
        salesforce_id: this.getCurrentSalesforceId(),
      }).subscribe(res => {
        this.avEquipments = res.av_equipments;
        cb(step, questionType);
      });
    } else if (questions[0].type_id === 'topic') {
      this.topicService.list(
        { company_id: this.getCurrentCompanyId() },
        {
          salesforce_id: this.getCurrentSalesforceId(),
          ...this.model.request_speakers && { speaker_id: this.model.request_speakers.map(rs => rs.speaker_id) },
          meeting_type_id: this.model.meeting_type_id,
          requestable: true,
        }
      ).subscribe(topics => {
        this.topics = topics;
        if (this.model.topic_id) {
          this.topic = this.topics.find(t => t.id === this.model.topic_id);
        }
        cb(step, questionType);
      });
    } else if (questions[0].type_id === 'event_start_at') {
      for (let i = this.model.request_datetimes.length; i < questions[0].max; i++) {
        this.model.request_datetimes.push({});
        this.selectedEventDates.push({
          event_date: null
        });
      }
      this.datePickerDisabled = true;
      this.requestService.getTimeSlots({
        company_id: this.getCurrentCompanyId()
      }, {
        salesforce_id: this.getCurrentSalesforceId(),
        meeting_type_id: this.meetingType.id,
        speaker_id: this.model.request_speakers.map(rs => rs.speaker_id).join(','),
      }).subscribe(res => {
        this.datePickerDisabled = res.no_available_dates;
        this.minDate = res.min_date ? moment(res.min_date).toDate() : new Date();
        this.model.request_datetimes.forEach((rd) => {
          if (rd.event_start_at && rd.event_start_at < this.minDate!) {
            this.minDate = null;
          }
        });
        this.maxDate = res.max_date ? moment(res.max_date).toDate() : null;
        // TODO: disable time slots for res.events
        cb(step, questionType);
      });
    } else {
      cb(step, questionType);
    }
  }

  hasDuplicatedDates() {
    for (let i = 0; i < this.model.request_datetimes.length - 1; i++) {
      for (let j = i + 1; j < this.model.request_datetimes.length; j++) {
        if (this.model.request_datetimes[i].event_date && this.model.request_datetimes[i].event_date === this.model.request_datetimes[j].event_date) {
          return true;
        }
      }
    }
    return false;
  }

  dateSelected(index, date: Date) {
    if (date) {
      this.selectedEventDates[index].event_date = date;

      this.model.request_datetimes[index].event_date = moment(date).format('YYYY-MM-DD');

      let sameDateSelected = false;
      this.model.request_datetimes.forEach((rd, i) => {
        if (i !== index && rd.event_date === this.model.request_datetimes[index].event_date) {
          sameDateSelected = true;
        }
      });
      if (sameDateSelected) {
        this.toastr.error('You can not select the same date more than once.');
      }
    } else {
      this.selectedEventDates[index].event_date = null;
      this.model.request_datetimes[index].event_date = null;
    }
  }

  timeSelected() {
    this.model.request_datetimes.forEach((rd) => {
      rd.event_time = moment(this.event_time).format('HH:mm');
    });
  }

  topicSelected(topic) {
    this.model.topic_id = topic.id;
    this.topic = topic;
  }

  isSpeakerSelected(speaker) {
    return this.model.request_speakers.map(rs => rs.speaker_id).includes(speaker.id);
  }

  toggleSpeaker(speaker) {
    const speakerScreen = this.model.screens[this.currentStep].questions.find(q => q.type_id === 'speakers');
    if (this.isSpeakerSelected(speaker)) {
      const i = this.model.request_speakers.map(rs => rs.speaker_id).indexOf(speaker.id);
      this.model.request_speakers.splice(i, 1);
      this.selectedSpeakers.splice(i, 1);
    } else if (this.selectedSpeakers.length < (speakerScreen.max || 1)) {
      this.model.request_speakers.push({
        speaker_id: speaker.id,
      });
      this.selectedSpeakers.push(speaker);
    }
  }

  isVenueSelected(venue) {
    return this.model.request_venues.map(rv => rv.venue_id).includes(venue.id);
  }

  toggleVenue(venue) {
    const venueScreen = this.model.screens[this.currentStep].questions.find(q => q.type_id === 'venue');
    if (this.isVenueSelected(venue)) {
      const i = this.model.request_venues.map(rv => rv.venue_id).indexOf(venue.id);
      this.model.request_venues.splice(i, 1);
      this.selectedVenues.splice(i, 1);
    } else if (this.selectedVenues.length < (venueScreen.max || 1)) {
      this.model.request_venues.push({
        venue_id: venue.id,
      });
      this.selectedVenues.push(venue);
    }
  }

  catererSelected(caterer) {
    if (caterer === 'venue') {
      this.model.caterering_by_venue = true;
      this.model.caterer_id = null
      this.caterer = null;
    } else {
      this.model.caterering_by_venue = false;
      this.model.caterer_id = caterer.id;
      this.caterer = caterer;
    }
  }

  onChangeAvEquipments() {
    this.selectedAvEquipments = this.model.av_equipments.map(id => this.avEquipments.find(av => av.id === id));
  }

  newAudience() {
    this.bsAttendeeSearchModalRef = this.modalService.show(CallPlanSearchModalComponent, {
      initialState: {
        enableCreate: true,
        multiSelectable: true,
        states: this.states,
        companyId: this.getCurrentCompanyId(),
        divisionId: this.getSalesforce()?.division_id,
        salesforceId: this.model.salesforce_id,
      },
      class: 'modal-lg',
    });
    this.bsAttendeeSearchModalRef.content.event.subscribe(value => {
      if (value == 'new_attendee') {
        this.bsAttendeeNewModalRef = this.modalService.show(CallPlanNewModalComponent, {
          initialState: {
            states: this.states,
            professions: this.professions,
            degrees: this.degrees,
            specialties: this.specialties,
          },
          class: 'modal-lg'
        });
        this.bsAttendeeNewModalRef.content.event.subscribe(value => {
          this.bsAttendeeNewModalRef.hide();
          this.model.attendees.push(value);
        });
        this.bsAttendeeSearchModalRef.hide();
      } else {
        Array.prototype.push.apply(this.model.attendees, value);
      }
    });
  }

  onRemoveAudience(index) {
    this.model.attendees.splice(index, 1);
  }

  updateRequest() {
    this.model.setEventDateTimeUTC(this.meetingType.duration_hours);
    this.requestService.update(this.model, {
      company_id: this.getCurrentCompanyId()
    })
      .subscribe(response => {
        this.toastr.success('Updated!');
        this.navigate(this.router, this.returnUrl, this.returnUrlParams);
      }, err => {
        this.toastr.error(getErrorMessage(err, 'Failed to update!'));
      });
  }

  createRequest() {
    this.model.setEventDateTimeUTC(this.meetingType.duration_hours);
    this.requestService.create(this.model, {
      company_id: this.getCurrentCompanyId()
    }).subscribe(response => {
      this.toastr.success('Created!');
      this.model.id = response.id;
      this.navigate(this.router, this.returnUrl, this.returnUrlParams);
    }, err => {
      this.toastr.error(getErrorMessage(err, 'Failed to create!'));
    });
  }

  getTimezoneAbbr(timezone_id) {
    const timezone = this.timezones.find(t => t.id === timezone_id);
    return timezone ? timezone.abbr : '';
  }

  hasNonDedicatedQuestions(screen) {
    return this.dedicatedQuestionTypes && screen.questions.filter(q => !this.dedicatedQuestionTypes.includes(q.type_id)).length > 0;
  }

  clearAnswers(step, nextScreen) {
    if (nextScreen == 'END') {
      nextScreen = Object.keys(this.model.answers).length;
    }
    Object.keys(this.model.answers).forEach((key) => {
      const index = key.split('_')[0];
      if (index > step && index <= nextScreen) {
        delete this.model.answers[key];
      }
    });
  }
}
