import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { DatatablesUtils } from '../_utils/datatables';
import { Request } from '../_models';

export class Requests {
  requests: Request[];
  venues: any;
  total: number;
}

@Injectable()
export class RequestService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/requests`);
  }

  listPage(urlParams: any, params: any, dtParams: any): Observable<Requests> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<Requests>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    }).pipe(
      map(res => {
        const requests = new Requests();
        requests.requests = res.requests.map((r) => new Request().deserialize(r));
        requests.venues = res.venues;
        requests.total = res.total;
        return requests;
      })
    );
  }

  approve(request, urlParams: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${request.id}/approve`;
    return this.http.put<any>(url, request);
  }

  decline(request, urlParams: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${request.id}/decline`;
    return this.http.put<any>(url, {
      declined_reason: request.declined_reason
    });
  }

  getSpeakers(urlParams: any = {}, params: any = {}, dtParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/request_speakers`, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    });
  }

  getTimeSlots(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/request_time_slots`, urlParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams(apiParams)
    });
  }
}
