import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { Router } from '@angular/router';
import { BehaviorSubject } from "rxjs";

import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { AuthenticationService } from '../_services';

@Injectable({
  providedIn: 'root'
})

export class SocketService {
  joinedEvent = this.socket.fromEvent<string>('join');
  leftEvent = this.socket.fromEvent<string>('leave');
  invitedEvent = this.socket.fromEvent<any>('invite');

  // Webcast Room
  greenRoomStartEvent = this.socket.fromEvent<string>('green_room_start');
  globalRoomStartEvent = this.socket.fromEvent<string>('global_room_start');
  presentationStartEvent = this.socket.fromEvent<string>('presentation_start');
  presentationEndEvent = this.socket.fromEvent<string>('presentation_end');
  presentationEvent = this.socket.fromEvent<string>('presentation');
  concludeEvent = this.socket.fromEvent<string>('conclude');
  muteEvent = this.socket.fromEvent<string>('mute');
  // Chat room
  messageEvent = this.socket.fromEvent<any>('message');

  constructor(
    private socket: Socket,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) {
    this.socket.on('connect', () => {
      this.socket.emit('init', this.authenticationService.token());
    });
    this.invitedEvent.subscribe(request => {
      this.toastr.info(`${moment(request.when).format('MM/DD/YYYY, hh:mm A')}`, `Chatting from ${request.requestor.first_name} ${request.requestor.last_name}`, { closeButton: true, timeOut: 100000 })
        .onTap
        .pipe()
        .subscribe(() => this.router.navigate([`/chat/${request.chat_room_id}`], {
          queryParams: {
            company_id: request.payload.company_id,
            event_id: request.payload.event_id
          }
        }));
    });
  }

  connect() {
    this.socket.connect();
  }

  join(data) {
    this.socket.emit('join', data);
  }

  leave(token) {
    this.socket.emit('leave', token);
  }

  presentation(currentSlideIndex) {
    this.socket.emit('presentation', currentSlideIndex);
  }

  mute(status) {
    this.socket.emit('mute', status);
  }

  message(message) {
    this.socket.emit('message', message);
  }
}
