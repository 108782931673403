import { Base } from './_base.model';

export class MeetingType extends Base {
  id: number;
  name: string;
  rank: number;
  duration_hours: number;
  before_mins: number;
  after_start_mins: number;
  after_end_mins: number;
  min_hcp: number;
  min_attendees: number;
  primary_min_hcp: number;
  primary_min_attendees: number;
  secondary_min_hcp: number;
  secondary_min_attendees: number;
  shared_min_hcp: number;
  shared_min_attendees: number;
  attendee_signin_minutes: number;
  has_webcast_groups: boolean;
  others_may_join: boolean;
  others_may_join_anywhere: number;
  others_may_join_total: number;
  event_managed_by: string;

  venue_statuses: any[];
  av_equipments: any[];
  event_locations: any[];
  event_formats: any[];
  salesforce_meeting_types: any[];
  area_types: any[];
  active_all_salesforces: Boolean;
  active_all_client_levels: Boolean;
  client_levels: any[];

  constructor() {
    super();
    this.duration_hours = 2;
    this.before_mins = 30;
    this.after_start_mins = 15;
    this.after_end_mins = 30;
    this.min_hcp = 3;
    this.min_attendees = 3;
    this.primary_min_hcp = 3;
    this.primary_min_attendees = 3;
    this.secondary_min_hcp = 0;
    this.secondary_min_attendees = 0;
    this.shared_min_hcp = 1;
    this.shared_min_attendees = 1;
    this.others_may_join_anywhere = 0;
    this.others_may_join_total = 0;
  }
}
