import moment from 'moment';

import { Base } from './_base.model';

export class SpeakerTask extends Base {
  id: number;
  text: string;
  status: string;
  contract_expires_before: Date;
  topics_trained: number[];
  topics_not_trained: number[];
  topics_affirmed: number[];
  url: string;
  action: string;
  rank: number;

  deleted: boolean;

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.contract_expires_before) {
      this.contract_expires_before = moment(this.contract_expires_before).toDate();
    }
    return this;
  }
}

