<div *ngIf="component.type === 'section'" [ngClass]="{'d-none': !isVisible()}">
  <h3 *ngIf="component.label">{{component.label}}</h3>
  <p>
    <ng-template ngFor let-object let-i="index" [ngForOf]="objects">
      <app-esif-form-control [component]="child" [values]="values" [errors]="errors" [adminFlags]="adminFlags"
        [index]="i" [speaker]="speaker" [office]="office" [states]="states" [speakerSpecialties]="speakerSpecialties"
        [speakerSubSpecialties]="speakerSubSpecialties" *ngFor="let child of component.children">
      </app-esif-form-control>
    </ng-template>
  </p>
  <p>
    <button type="button" class="btn btn-primary" *ngIf="component.multiple" (click)="addObject()">+</button>
  </p>
</div>
<div *ngIf="component.type === 'subsection'" [ngClass]="{'d-none': !isVisible()}" style="padding-left: 20px;">
  <h5 *ngIf="component.label">{{component.label}}</h5>
  <p>
    <ng-template ngFor let-object let-i="index" [ngForOf]="objects">
      <app-esif-form-control [component]="child" [values]="values" [errors]="errors" [adminFlags]="adminFlags"
        [index]="i" [speaker]="speaker" [office]="office" [states]="states" [speakerSpecialties]="speakerSpecialties"
        [speakerSubSpecialties]="speakerSubSpecialties" *ngFor="let child of component.children">
      </app-esif-form-control>
      <div class="p-b-10 b-b mb-2">
        <button type="button" class="btn btn-danger" *ngIf="component.multiple && i > 0" (click)="removeObject()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </ng-template>
  </p>
  <p>
    <button type="button" class="btn btn-primary" *ngIf="component.multiple" (click)="addObject()">
      <i class="fa fa-plus"></i>
    </button>
  </p>
</div>
<h4 *ngIf="component.type === 'HEADER'" [ngClass]="{'d-none': !isVisible()}">
  {{component.label}}
</h4>
<p *ngIf="component.type === 'PARAGRAPH'" [ngClass]="{'d-none': !isVisible()}">
  {{component.label}}
</p>
<div *ngIf="isSelect()" class="mb-3" [ngClass]="{'d-none': !isVisible(),'has-error': hasError()}">
  <label class="form-label" [for]="key">{{component.label}}</label>
  <select [id]="key" class="form-control" [(ngModel)]="values[key]" (change)="selectionChanged($event.target.value)">
    <option *ngFor="let value of selectValues()" [value]="value">
      {{value}}
    </option>
  </select>
</div>
<div *ngIf="component.type === 'STATE'" class="mb-3" [ngClass]="{'d-none': !isVisible(),'has-error': hasError()}">
  <label class="form-label" [for]="key">{{component.label}}</label>
  <select [id]="key" class="form-control" [(ngModel)]="values[key]" (change)="selectionChanged($event.target.value)">
    <ng-template ngFor let-state [ngForOf]="states">
      <option [value]="state.code" *ngIf="state.country_id === 'US'">{{state.name}}</option>
    </ng-template>
  </select>
</div>
<div *ngIf="component.type === 'MULTIPLE_CHECKBOX'" class="mb-3" [ngClass]="{'d-none': !isVisible()}">
  <ng-template ngFor let-value let-i="index" [ngForOf]="component.values">
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" [(ngModel)]="values[key + i]">
      <label class="form-check-label">{{value}}</label>
    </div>
  </ng-template>
</div>
<div *ngIf="component.type === 'TEXT_FIELD'" class="mb-3" [ngClass]="{'d-none': !isVisible(), 'has-error': hasError()}">
  <label class="form-label" [for]="key">
    {{einSelected() ? 'Business ' : ''}}{{component.label}}
    <i class="fa fa-flag text-danger" *ngIf="isAdminFlags()"></i>
  </label>
  <input type="text" class="form-control" [id]="key" [(ngModel)]="values[key]">
</div>
<div *ngIf="component.type === 'TEXT_FIELD_WITH_NA'" class="mb-3"
  [ngClass]="{'d-none': !isVisible(), 'has-error': hasError()}">
  <label class="form-label" [for]="key">{{component.label}}</label>
  <input type="text" class="form-control" [id]="key" [(ngModel)]="values[key]">
  <div class="form-check form-check-inline">
    <input type="checkbox" class="form-check-input" value="n/a" [(ngModel)]="values[na_key]">
    <label class="form-check-label ms-2">N/A</label>
  </div>
</div>
<div *ngIf="component.type === 'COMMENTS'" class="mb-3" [ngClass]="{'d-none': !isVisible()}">
  <label class="form-label" [for]="key">{{component.label}}</label>
  <textarea [id]="key" class="form-control" [(ngModel)]="values[key]"></textarea>
</div>
<div *ngIf="component.type === 'YN'" class="mb-3" [ngClass]="{'d-none': !isVisible(), 'has-error': hasError()}">
  <label class="form-label me-5" [for]="key">{{component.label}}</label>
  <div class="form-check form-check-inline" *ngFor="let value of ['Yes', 'No'];index as i">
    <input type="radio" class="form-check-input" name="{{key}}" id="key_{{i}}" [value]="value"
      [(ngModel)]="values[key]">
    <label class="form-check-label" class="radio-inline">{{value}}</label>
  </div>
</div>
<div *ngIf="component.type === 'MULTIPLE_RADIO'" class="mb-3"
  [ngClass]="{'d-none': !isVisible(), 'has-error': hasError(), 'd-inline-block w-auto': component.inline}">
  <label class="form-label me-5" [for]="key">{{component.label}}</label>
  <ng-template ngFor let-value [ngForOf]='component.values'>
    <div class="form-check form-check-inline">
      <input type="radio" class="form-check-input" [name]="key" [value]="value" [(ngModel)]="values[key]">
      <label class="form-check-label">{{value}}</label>
    </div>
    <br *ngIf="component.multiline" />
  </ng-template>
</div>
<div *ngIf="component.type === 'RADIO'" class="mb-3" [ngClass]="{'d-none': !isVisible(), 'has-error': hasError()}">
  <div class="form-check form-check-inline">
    <input type="radio" class="form-check-input" [name]="key" [value]="component.value" [(ngModel)]="values[key]"
      (change)="radioSelected($event.value)">
    <label class="form-check-label">{{component.label || component.value}}</label>
  </div>
</div>
<div *ngIf="component.type === 'DATE'" class="mb-3" [ngClass]="{'d-none': !isVisible(), 'has-error': hasError()}">
  <label class="form-label" [for]="key">{{component.label}}</label>
  <input type="text" [name]="key" [(ngModel)]="values[key]" class="form-control datetimepicker" autocomplete="off"
    bsDatepicker>
</div>
<div *ngIf="component.type === 'FILE'" class="mb-3" [ngClass]="{'d-none': !isVisible(), 'has-error': hasError()}">
  <label class="form-label" [for]="key">{{component.label}}</label>
  <div *ngIf="values[key]"><a href="javascript:void(0);" (click)="download(values[key])">{{values[key].name}}
    </a></div>
  <input type="file" name="key" class="form-control dropify" (change)="onFileChange($event)" />
</div>
<div *ngIf="component.type === 'SSN'" class="mb-3" [ngClass]="{'d-none': !isVisible(), 'has-error': hasErrors(3)}">
  <label class="form-label">{{component.label}}</label>
  <input type="text" class="text-end" class="form-control d-inline-block w-auto" [(ngModel)]="values[key + '0']"
    size="3" maxlength="3">
  -
  <input type="text" class="text-end" class="form-control d-inline-block w-auto" [(ngModel)]="values[key + '1']"
    size="2" maxlength="2">
  -
  <input type="text" class="text-end" class="form-control d-inline-block w-auto" [(ngModel)]="values[key + '2']"
    size="4" maxlength="4">
</div>
<div *ngIf="component.type === 'SSN_CONFIRM'" class="mb-3"
  [ngClass]="{'d-none': !isVisible(), 'has-error': hasErrors(3)}">
  <label class="form-label">{{component.label}}</label>
  <input type="text" class="text-end" class="form-control d-inline-block w-auto" [(ngModel)]="values[key + '0']"
    size="3" maxlength="3">
  -
  <input type="text" class="text-end" class="form-control d-inline-block w-auto" [(ngModel)]="values[key + '1']"
    size="2" maxlength="2">
  -
  <input type="text" class="text-end" class="form-control d-inline-block w-auto" [(ngModel)]="values[key + '2']"
    size="4" maxlength="4">
  <p *ngIf="errorMessage">{{errorMessage}}</p>
</div>
<div *ngIf="component.type === 'EIN'" class="mb-3" [ngClass]="{'d-none': !isVisible(), 'has-error': hasErrors(2)}">
  <label class="form-label">{{component.label}}</label>
  <input type="text" class="text-end" class="form-control d-inline-block w-auto" [(ngModel)]="values[key + '0']"
    size="2" maxlength="2">
  -
  <input type="text" class="text-end" class="form-control d-inline-block w-auto" [(ngModel)]="values[key + '1']"
    size="7" maxlength="7">
</div>
<div *ngIf="component.type === 'EIN_CONFIRM'" class="mb-3"
  [ngClass]="{'d-none': !isVisible(), 'has-error': hasErrors(2)}">
  <label class="form-label">{{component.label}}</label>
  <input type="text" class="text-end" class="form-control d-inline-block w-auto" [(ngModel)]="values[key + '0']"
    size="2" maxlength="2">
  -
  <input type="text" class="text-end" class="form-control d-inline-block w-auto" [(ngModel)]="values[key + '1']"
    size="7" maxlength="7">
  <p *ngIf="errorMessage">{{errorMessage}}</p>
</div>
<p *ngIf="component.type === 'BUTTON_GENERATE_W9'" [ngClass]="{'d-none': !isVisible()}">
  <button type="button" class="btn btn-info" (click)="generateW9()"
    *ngIf="!values.w9_signed_at">{{component.label}}</button>
  <span *ngIf="values.w9_signed_at">W9 has been received.<br /></span>
  <span *ngIf="values.w9"><a href="javascript:void(0);" (click)="downloadW9(values.w9)" class="btn btn-success">Download
      W9</a><br /></span>
</p>
<br *ngIf="component.multiline" [ngClass]="{'d-none': !isVisible()}" />