<h4 class="mb-2">Shadow Settings</h4>
<h6 class="mb-1">Default Shadow Staffs</h6>
<table class="table">
  <thead>
    <tr>
      <th>Company</th>
      <th>Salesforce</th>
      <th>Shadow Staff</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let shadow of defaultShadowStaffs; index as i">
      <td>
        <ng-template [ngIf]="shadow.company_id">
          <ng-template ngFor let-company [ngForOf]="companies">
            <ng-template [ngIf]="shadow.company_id == company.id">
              {{company.name}}
            </ng-template>
          </ng-template>
        </ng-template>
        <ng-template [ngIf]="!shadow.company_id">
          Default
        </ng-template>
      </td>
      <td>
        <ng-template ngFor let-company [ngForOf]="companies">
          <ng-template ngFor let-salesforce [ngForOf]="company.salesforces">
            <ng-template [ngIf]="shadow.company_id == company.id && shadow.salesforce_id == salesforce.id">
              {{salesforce.name}}
            </ng-template>
          </ng-template>
        </ng-template>
      </td>
      <td>
        <ng-template ngFor let-staff [ngForOf]="staffs">
          <ng-template [ngIf]="shadow.shadow_id == staff.id">
            {{staff | fullName}}
          </ng-template>
        </ng-template>
      </td>
      <td>
        <button class="btn btn-danger" (click)="onDeleteStaffShadow(i)">
          <i class="fa fa-trash"></i>
        </button>
      </td>
    </tr>
    <tr>
      <td>
        <select name="company_id" class="form-control" [(ngModel)]="newStaffShadow.company_id">
          <option value="">Default</option>
          <option [value]="company.id" *ngFor="let company of companies">{{company.name}}</option>
        </select>
      </td>
      <td>
        <select name="salesforce_id" class="form-control" [(ngModel)]="newStaffShadow.salesforce_id">
          <ng-template ngFor let-company [ngForOf]="companies">
            <ng-template [ngIf]="newStaffShadow.company_id == company.id">
              <option [value]="salesforce.id" *ngFor="let salesforce of company.salesforces">
                {{salesforce.name}}
              </option>
            </ng-template>
          </ng-template>
        </select>
      </td>
      <td>
        <select name="shadow_id" class="form-control" [(ngModel)]="newStaffShadow.shadow_id">
          <option [value]="staff.id" *ngFor="let staff of staffs">{{staff | fullName}}</option>
        </select>
      </td>
      <td>
        <button class="btn btn-secondary" type="button" (click)="onAddStaffShadow()">
          <i class="fa fa-plus"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
<h6 class="mb-1">Shadow Schedules</h6>
<table class="table">
  <thead>
    <tr>
      <th>From</th>
      <th>To</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let shadow_schedule of shadowSchedules; index as i">
      <td>
        {{shadow_schedule.start_at | date: 'MM/dd/yyyy'}}
      </td>
      <td>
        {{shadow_schedule.end_at | date: 'MM/dd/yyyy'}}
      </td>
      <td>
        <button class="btn btn-danger" (click)="onDeleteShadowSchedule(i)">
          <i class="fa fa-trash"></i>
        </button>
      </td>
    </tr>
    <tr>
      <td>
        <input type="text" name="start_at" [(ngModel)]="newShadowSchedule.start_at" class="form-control datetimepicker"
          placeholder="From" autocomplete="off" bsDatepicker [bsConfig]="{ adaptivePosition: true }">
      </td>
      <td>
        <input type="text" name="end_at" [(ngModel)]="newShadowSchedule.end_at" class="form-control datetimepicker"
          placeholder="To" autocomplete="off" bsDatepicker [bsConfig]="{ adaptivePosition: true }">
      </td>
      <td>
        <button class="btn btn-secondary" type="button" (click)="onAddShadowSchedule()">
          <i class="fa fa-plus"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>