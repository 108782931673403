import { Base } from './_base.model';

export class CoreEvent extends Base {
  id: number;
  created_at: Date;
  common_event: any;
  open: boolean;
  ongoing: boolean;
  qa_verified: boolean;
  reconciled_at: Date;
  contact_staff: any;
  status_id: any;
  budget_status_id: any;

  static toCalendarEvent(obj: any): any {
    return {
      id: obj.id,
      title: obj.common_event.name,
      start: obj.common_event.start_at,
      end: obj.common_event.end_at,
    };
  }
}
