import moment from 'moment';
import { Base } from './_base.model';
import { SalesforceProgramPrefix } from './salesforce_program_prefix.model';

export class Salesforce extends Base {
  id: string;
  code: string;
  division_id: number;
  name: string;
  description: string;
  image: string;
  wide_image: string;
  waiting_room_video: string;
  green_room_video: string;
  navbar_color: string;
  status_id: string;
  active_at: Date;
  expire_at: Date;
  program_prefix: string;
  program_number: number;
  default_approval_group: any;
  request_speaker_pending_contract: boolean;
  request_speaker_pending_renewal: boolean;
  request_insufficient_funds: boolean;
  request_up_to_date: Date;
  av_company_id: number;
  travel_business_rules: string;
  speaker_travel_rules: string;
  ground_provided_min_miles: number;
  air_provided_min_miles: number;

  status: any;
  image_file: string | ArrayBuffer;
  wide_image_file: string | ArrayBuffer;
  waiting_video_file: string | ArrayBuffer;
  green_video_file: string | ArrayBuffer;
  topics: any[];
  meeting_types: any[];
  salesforce_meeting_types: any[];
  fiscal_years: any[];
  products: any[];
  attendee_degrees: any[];
  attendee_specialties: any[];
  program_prefixes: SalesforceProgramPrefix[];
  default_cc: string;
  event_more_info_message_agency: string;
  event_more_info_message_salesforce: string;

  travel_agent_id: string;
  travel_agent_future_id: string;
  travel_agent_future_start_at: Date;

  print_invitation_id: string;
  html_invitation_id: string;
  email_invitation_id: string;

  av_vendor_type: string;

  current_agency_id: string;

  constructor() {
    super();
    this.fiscal_years = [];
    this.program_prefixes = [];
  }

  get breadcrumbTitle(): string {
    return this.name;
  }

  get breadcrumbLink(): string {
    return this.uri();
  }

  uri(path = '') {
    return `/budgets/salesforce/${this.id}${path}`;
  }

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.active_at) {
      this.active_at = moment(this.active_at).toDate();
    }
    if (this.expire_at) {
      this.expire_at = moment(this.expire_at).toDate();
    }
    if (this.request_up_to_date) {
      this.request_up_to_date = moment(this.request_up_to_date).toDate();
    }
    if (this.travel_agent_future_start_at) {
      this.travel_agent_future_start_at = moment(this.travel_agent_future_start_at).toDate();
    }
    if (this.salesforce_meeting_types) {
      this.salesforce_meeting_types.forEach((salesforceMeetingType) => {
        if (salesforceMeetingType.start_date) {
          salesforceMeetingType.start_date = moment(salesforceMeetingType.start_date).toDate();
        }
        if (salesforceMeetingType.end_date) {
          salesforceMeetingType.end_date = moment(salesforceMeetingType.end_date).toDate();
        }
      });
    }
    return this;
  }
}
