import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class AttendeeRegistrationFieldsService extends BaseService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/attendee_registration_fields`);
  }
}