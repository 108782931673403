import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class AttendeeService extends BaseService {
  constructor(
    protected override http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/attendees`);
  }

  validateAttendees(urlParams: any, formData): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/validate_attendees`, urlParams);
    return this.http.post<any>(url, formData);
  }

  importAttendees(urlParams: any, formData): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/import_attendees`, urlParams);
    return this.http.post<any>(url, formData);
  }
}
