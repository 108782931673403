import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { CoreEvent } from '../_models';
import { DatatablesUtils } from '../_utils/datatables';
import { BaseService } from './base.service';

export class CoreEvents {
  events: CoreEvent[];
  total: number;
}

@Injectable()
export class CoreEventService extends BaseService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/core_events`);
  }

  listPage(urlParams: any, params: any, dtParams: any): Observable<CoreEvents> {
    const url = sprintf(`${this.baseUrl}/search`, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.post<CoreEvents>(url, {
      ...params,
      ...apiParams,
    });
  }
}
