import moment from 'moment';
import { Base } from './_base.model';

export class Invitation extends Base {
  id: string;
  type: string;
  salesforce_id: string;
  meeting_types: string[];
  topics: string[];
  subject: string;
  bgcolor: string;
  file: string;
  files: any;
  active: boolean;
  editor?: string;
  html?: string;
  unlayer_design: any;
  active_at: Date;
  expire_at: Date;
  created_at: Date;

  constructor() {
    super();
    this.html = '';
  }

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.active_at) {
      this.active_at = moment(this.active_at).toDate();
    }
    if (this.expire_at) {
      this.expire_at = moment(this.expire_at).toDate();
    }
    return this;
  }
}
