// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration=dev` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  COMPANY_NAME: 'Barkani',
  COMPANY_SITE: 'Barkani.com',
  socketUrl: 'https://omni-api-dev.barkani.com',
  apiBaseUrl: 'https://omni-api-dev.barkani.com/api',
  GOOGLE_OAUTH2_CLIENT_ID: '115315553953-20bsar37abe7tn3gr7oa0n07g1e81dq2.apps.googleusercontent.com',
  GOOGLE_MAP_API_KEY: 'AIzaSyCZLFo26a1OtPFbJwv_ubbUYLeaROQtHTw',
  FACEBOOK_APP_ID: '1017310152622713',
  APPLE_CLIENT_ID: 'com.barkani.omni',
  APPLE_REDIRECT_URI: 'https://omni-dev.barkani.com',
  AUTH0: {
    clientID: '4q19pzVJIbR9PQKVkzIyZWzR0RTPNhqQ',
    domain: 'barkani.us.auth0.com',
    // audience: 'localhost',
    redirectUri: 'http://localhost:4200/login',
  },
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
