import { Base } from './_base.model';

export class ApprovalGroupClientLevel extends Base {
  id: number;
  approval_group_id: number;
  client_level_id: number;
  client_sub_level_id: number;
  pending_status_label: string;
  rank: number;
}
