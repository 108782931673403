import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, AfterContentInit, Input, ViewChild } from '@angular/core';
import { SignatureFieldComponent } from 'app/_components/signature-field/signature-field.component';

declare var $: any;

@Component({
  selector: 'app-request-question',
  templateUrl: './request-question.component.html',
})
export class RequestQuestionComponent implements OnInit, AfterContentInit {
  @Input() question: any;
  @Input() answers: any;
  @Input() screenIndex: number;
  @Input() questionIndex: number;
  @Input() states: any[];
  @ViewChild(SignatureFieldComponent) public signatureField: SignatureFieldComponent;

  showedSubQuestion: boolean = false;

  ngOnInit() {
    if (!this.answers[this.screenIndex]) {
      this.answers[this.screenIndex] = {};
    }
    if (this.question.type_id === 'MULTIPLE_CHECK' && !this.answers[this.screenIndex][this.questionIndex]) {
      this.answers[this.screenIndex][this.questionIndex] = {};
    }
  }

  ngAfterContentInit() {
  }

  onChangeYNStatus(value) {
    if ((value == "Yes" && this.question.sub_type == "IFYESTHEN") || (value == 'No' && this.question.sub_type == 'IFNOTHEN')) {
      this.showedSubQuestion = true;
    } else {
      this.showedSubQuestion = false;
    }
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.answers[this.screenIndex][this.questionIndex] = {
          name: file.name,
          type: file.type,
          size: file.size,
          data: reader.result
        };
      };
    }
  }
}
