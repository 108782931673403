import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { DatatablesUtils } from '../_utils/datatables';

export class CallPlanContacts {
  call_plan_contacts: any[];
  total: number;
}

@Injectable()
export class CallPlanContactService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/call_plan_contacts`);
  }

  listPage(urlParams: any, params: any, dtParams: any): Observable<CallPlanContacts> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<CallPlanContacts>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    });
  }
}
