import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { sprintf } from 'sprintf-js';

@Injectable()
export class EventCreditCardService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/credit_cards`);
  }

  bulkCreate(body: any, urlParams: any, params?: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/credit_cards/bulk_create`, urlParams);
    return this.http.post<any>(url, body, {
      params: this.toHttpParams({
        ...params,
      })
    });
  }

  import(body: any, urlParams: any, params?: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/credit_cards/import`, urlParams);
    return this.http.post<any>(url, body, {
      params: this.toHttpParams({
        ...params,
      })
    });
  }

  getStripeCard(urlParams: any = {}, apiParams: any = {}) {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/credit_cards/%(card_id)s/stripe`, urlParams);
    return this.http.get<any[]>(url, { params: this.toHttpParams(apiParams) });
  }

  updateStripeCard(body: any, urlParams: any = {}, apiParams: any = {}) {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/credit_cards/%(card_id)s/stripe`, urlParams);
    return this.http.put<any>(url, body, {
      params: this.toHttpParams({
        ...apiParams,
      })
    });
  }
}
