import { Base } from './_base.model';

export class WebcastGroup extends Base {
  id: number;
  name: string;
  event_id: string;
  webcast_id: string;
  active: boolean;
  event_members: any[];

  constructor(name: string, event_id: string, webcast_id: string) {
    super();
    this.name = name;
    this.event_id = event_id;
    this.webcast_id = webcast_id;
    this.event_members = [];
  }
}