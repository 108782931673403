import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

@Injectable()
export class UserAlertService extends BaseService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/my/alerts`);
  }

  override delete(model: any, urlParams?: any, apiParams?: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${model.id}`;
    return this.http.post(url, model, {
      params: this.toHttpParams(apiParams)
    });
  }
}