import { Base } from './_base.model';

export class Checklist extends Base {
  id: number;
  text: string;
  event_card_category_id: string;
  active: boolean;
  choices: string;
  other: boolean;
  formatting: string;
  rank: number;
  url: string;
  assignee: string;
  scope: string;
  action: string;

  deleted: boolean;
}

