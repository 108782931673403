import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { EventSpeakerService } from 'app/_services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-speaker-confirm-modal',
  templateUrl: './speaker-confirm-modal.component.html',
})
export class SpeakerConfirmModalComponent implements OnInit {
  companyId: string;
  eventId: string;
  eventSpeaker: any;
  speakerSlots: number[];
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    public bsModalRef: BsModalRef,
    private eventSpeakerService: EventSpeakerService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    
  }

  updateStatus(status) {
    if (status == 'remove') {
      this.deleteSpeaker();
      return;
    }
    this.eventSpeakerService.update([{
      event_id: this.eventSpeaker.event_id,
      speaker_id: this.eventSpeaker.speaker_id,
      status,
    }], {
      company_id: this.companyId,
      event_id: this.eventId,
    }).subscribe(res => {
      this.eventSpeaker.status = status;        
      this.bsModalRef.hide();      
    });
  }

  updateSlot(slot) {
    this.eventSpeakerService.updateSlot({
      event_id: this.eventSpeaker.event_id,
      speaker_id: this.eventSpeaker.speaker_id,
      slot: this.eventSpeaker.slot
    }, {
      company_id: this.companyId,
      event_id: this.eventId,
    }).subscribe(res => {
      this.event.emit(res);
      this.bsModalRef.hide();
    });
  }

  deleteSpeaker() {
    this.eventSpeakerService.delete({id: this.eventSpeaker.speaker_id}, {
      company_id: this.companyId,
      event_id: this.eventId
    }).subscribe(res => {
      this.toastr.success('Success!');
      this.bsModalRef.hide();
      this.event.emit(res);
    }, err => {
      this.toastr.error('Error');
    });
  }
}
