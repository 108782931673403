import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';
import { environment } from "environments/environment";

import { BaseService } from "./base.service";

@Injectable()

export class WorkflowBudgetService extends BaseService {
  constructor (
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/workflow_budgets`)
  }

  setDefault (id, urlParams: any = {}, apiParams: any = {}): Observable<any[]> {
    const url = sprintf(this.baseUrl, urlParams) + `/${id}/default`;
    return this.http.put<any>(url, {}, {
      params: this.toHttpParams(apiParams),
    });
  }
}