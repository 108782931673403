<ngx-dropzone (change)="onFileChange($event)" [accept]="accept" multiple="false">
  <ngx-dropzone-label>Drag and drop a file here or click</ngx-dropzone-label>
  <ng-template [ngIf]="_file">
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="_file" *ngIf="accept.startsWith('image/')">
    </ngx-dropzone-image-preview>
    <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" [file]="_file" *ngIf="accept.startsWith('video/')">
    </ngx-dropzone-video-preview>
    <ngx-dropzone-preview ngProjectAs="ngx-dropzone-preview" [file]="_file"
      *ngIf="!accept.startsWith('image/') && !accept.startsWith('video/')">
      <ngx-dropzone-label>{{ _file.name }} ({{ _file.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ng-template>
</ngx-dropzone>