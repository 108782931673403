import { Component, OnInit, Input, AfterViewInit, } from '@angular/core';
import { CoreEventField } from 'app/_models';

declare var $: any;

@Component({
  selector: 'app-core-event-field',
  templateUrl: './core-event-field.component.html'
})
export class CoreEventFieldComponent implements OnInit, AfterViewInit {
  @Input() field: CoreEventField;
  @Input() lists: any;
  @Input() eventValues: any;
  @Input() isVisibleSpace: boolean;

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    if (this.field.is_half) {
      $(".half-field").parents('.field-item').addClass('col-3');
    } else {
      $(".full-field").parents('.field-item').addClass('col-6');
    }
  }

}