<nav id="navbar" class="navbar navbar-expand-lg navbar-light p-0">
  <a href="#" class="navbar-brand d-sm-inline-block text-center">
    <img src="/assets/img/login-logo.png" alt="{{COMPANY_NAME}}" />
  </a>
  <button class="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarWrapper"
    aria-controls="navbarWrapper" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div id="navbarWrapper" [style.backgroundColor]="getSalesforceNavbarColor() || false"
    class="collapse navbar-collapse">
    <ul id="top-menu" class="navbar-nav w-100 align-items-center p-2">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle me-4 pb-0 pt-0" data-bs-toggle="dropdown" href="javascript:void(0)"
          aria-expanded="false" id="accountSettingDropdown">
          <img src="{{user.photo}}" alt="user-img" width="36" *ngIf="user.photo" class="rounded-circle">
          <b class="d-none d-sm-inline-block ms-2">{{user | fullName}}</b><span class="caret"></span>
        </a>
        <ul class="dropdown-menu dropdown-user" aria-labelledby="accountSettingDropdown">
          <li>
            <div class="dropdown-item">
              <div class="user-img" *ngIf="user.photo"><img src="{{user.photo}}" alt="user" class="rounded-circle" />
              </div>
              <div class="d-inline-block">
                <h4>{{user | fullName}}</h4>
                <p class="text-muted">{{user.email}}</p>
                <a href="javascript:void(0)" class="close-btn" title="close">
                  <i class="fa fa-close"></i>
                </a>
              </div>
            </div>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li>
            <a class="dropdown-item" routerLink="/my/profile" title="Account Setting">
              <i class="ti-settings"></i> Account Setting
            </a>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li *ngIf="user.staff_id">
            <a (click)="changeShadow()" href="javascript:void(0);" class="dropdown-item"
              [ngClass]="{'text-danger': user.shadowed, 'text-info': !user.shadowed}">
              <i class="ti-hand-point-right"></i>&nbsp;<ng-template [ngIf]="!user.shadowed">Activate</ng-template>
              <ng-template [ngIf]="user.shadowed">Deactivate</ng-template>
              Shadow Schedule
            </a>
          </li>
          <li *ngIf="user.staff_id">
            <hr class="dropdown-divider">
          </li>
          <li>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()" title="Logout">
              <i class="fa fa-power-off"></i> Logout
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown me-2">
        <a title="menu" data-bs-toggle="dropdown" class="position-relative" href="javascript:void(0)"
          aria-expanded="false" id="mailboxDropdown">
          <i class="mdi mdi-gmail"></i>
          <span *ngIf="unreadNotificationsCount != 0"
            class="position-absolute top-0 start-100 translate-middle p-1 mt-1 bg-danger border border-light rounded-circle"></span>
          <!-- <div class="notify">
            <span class="heartbit"></span>
            <span class="point"></span>
          </div> -->
        </a>
        <ul class="dropdown-menu" aria-labelledby="mailboxDropdown">
          <li>
            <div *ngIf="unreadNotificationsCount == 0" class="dropdown-item">You have no new messages</div>
            <div *ngIf="unreadNotificationsCount != 0" class="dropdown-item">You have {{ unreadNotificationsCount }} new
              messages</div>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <div *ngIf="unreadNotifications.length == 0" class="text-center">
            No unread messages.
          </div>
          <div *ngIf="unreadNotifications.length != 0">
            <li *ngFor="let not of unreadNotifications">
              <div class="message-center">
                <a href="javascript:void(0)" class="dropdown-item"
                  title='{{ not.sender.first_name + " " + not.sender.last_name }}'>
                  <div class="user-img">
                    <img *ngIf="not.sender.photo" src="{{not.sender.photo}}" alt="user" class="img-circle">
                    <img *ngIf="!not.sender.photo" src="assets/img/user-1.png" alt="user" class="img-circle">
                    <span class="profile-status online float-end"></span>
                  </div>
                  <div class="mail-content">
                    <h5>{{ not.sender.first_name + " " + not.sender.last_name }}</h5>
                    <div class="mt-1">{{not.subject}}</div>
                    <span class="mail-desc">{{not.content}}</span>
                    <span class="time">{{ not.created_at | momentDate: 'MMMM D, YYYY hh:mm a z'}}</span>
                  </div>
                </a>
              </div>
            </li>
          </div>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li>
            <a class="dropdown-item text-center" routerLink="/my/notifications" title="See all notifications">
              <strong>See all notifications</strong>&nbsp;<i class="fa fa-angle-right"></i>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown">
        <a title="task icon" data-bs-toggle="dropdown" href="javascript:void(0)" aria-expanded="false" id="taskDropdown"
          class="position-relative">
          <i class="mdi mdi-check-circle"></i>
          <span *ngIf="userChecklist.length > 0"
            class="position-absolute top-0 start-100 translate-middle p-1 mt-1 bg-danger border border-light rounded-circle">
            <span class="visually-hidden">Tasks</span>
          </span>
          <!-- <div class="notify">
            <span class="heartbit"></span>
            <span class="point"></span>
          </div> -->
        </a>
        <ul class="dropdown-menu" aria-labelledby="taskDropdown">
          <li>
            <!-- <a class="dropdown-item" href="javascript:void(0)" title="Task 1">
              <div>
                <p>
                  <strong>Task 1</strong>
                  <span class="ms-5 text-muted">40% Complete</span>
                </p>
                <div class="progress active">
                  <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="40" aria-valuemin="0"
                    aria-valuemax="100" style="width: 40%">
                    <span class="visually-hidden">40% Complete (success)</span>
                  </div>
                </div>
              </div>
            </a> -->
            <div *ngIf="userChecklist.length == 0" class="dropdown-item">You have no tasks</div>
            <div *ngIf="userChecklist.length != 0 && authenticationService.is_staff_or_agency_staff()"
              class="dropdown-item">You have {{ userChecklist.length }} task(s)
            </div>
            <div *ngIf="userChecklist.length != 0 && authenticationService.is_client_corporate()" class="dropdown-item">
              You have {{ userChecklist.length }} Pending Request(s)
            </div>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li>
            <a *ngIf="authenticationService.is_staff_or_agency_staff()" class="dropdown-item text-center"
              routerLink="/my/checklists" href="javascript:void(0)" title="See All Tasks">
              <strong>See All Tasks</strong>
              <i class="fa fa-angle-right"></i>
            </a>
            <a *ngIf="authenticationService.is_client_corporate()" class="dropdown-item text-center"
              routerLink="/my/checklists" href="javascript:void(0)" title="See All Pending Requests">
              <strong>See All Pending Requests</strong>
              <i class="fa fa-angle-right"></i>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="companies && companies.length" class="nav-item dropdown ms-auto me-3"
        [ngClass]="{'dropstart': !getCurrentCompanyId()}">
        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" aria-expanded="false"
          id="companyDropdown" title="Company/Bureau">
          <i class="fa fa-sliders"></i> Company/Bureau
        </a>
        <ul class="dropdown-menu company-menu" aria-labelledby="companyDropdown">
          <ng-template ngFor let-company [ngForOf]="companies">
            <li class="dropdown-submenu" *ngIf="authenticationService.is_staff() || company.salesforces.length > 0">
              <a href="javascript:void(0);" class="dropdown-item" tabindex="-1">
                <i class="fa fa-building fa-fw"></i>
                {{company.name}}
                <i class="fa fa-angle-right"></i>
              </a>
              <ul class="dropdown-menu" *ngIf="!company.has_division">
                <li *ngFor="let salesforce of company.salesforces">
                  <a href="javascript:void(0);" class="dropdown-item"
                    (click)="selectSalesforce(company.id, company.name, salesforce.id, salesforce.name)">
                    <i class="fa fa-product-hunt fa-fw"></i>
                    {{salesforce.name}}
                    <div class="checkbox">
                      <input class="approval-check" name="default-salesforce-{{company.id}}-{{salesforce.id}}"
                        type="checkbox"
                        [checked]="company.id === user.default_company_id && salesforce.id === user.default_salesforce_id"
                        (change)="setDefaultSalesforce(company.id, salesforce.id)" (click)="stopPropagation($event)">
                      <label for="default-salesforce-{{company.id}}-{{salesforce.id}}"></label>
                    </div>
                  </a>
                </li>
              </ul>
              <ul class="dropdown-menu" *ngIf="company.has_division">
                <li class="dropdown-submenu" *ngFor="let division of company.divisions">
                  <a href="javascript:void(0);" class="dropdown-item" tabindex="-1">
                    <i class="fa fa-building fa-fw"></i>
                    {{division.name}}
                    <i class="fa fa-angle-right"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <ng-template ngFor let-salesforce [ngForOf]='company.salesforces'>
                      <li *ngIf="salesforce.division_id===division.id">
                        <a href="javascript:void(0);" class="dropdown-item"
                          (click)="selectSalesforce(company.id, company.name, salesforce.id, salesforce.name)">
                          <i class="fa fa-product-hunt fa-fw"></i>
                          {{salesforce.name}}
                          <div class="checkbox">
                            <input class="approval-check" name="default-salesforce-{{company.id}}-{{salesforce.id}}"
                              type="checkbox"
                              [checked]="company.id === user.default_company_id && salesforce.id === user.default_salesforce_id"
                              (change)="setDefaultSalesforce(company.id, salesforce.id)"
                              (click)="stopPropagation($event)">
                            <label for="default-salesforce-{{company.id}}-{{salesforce.id}}"></label>
                          </div>
                        </a>
                      </li>
                    </ng-template>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-template>
        </ul>
      </li>
      <li class="nav-item brand-img me-3" *ngIf="companies && companies.length && getSalesforceImage()">
        <img src="{{getSalesforceImage()}}" alt="">
      </li>
      <li *ngIf="companies && companies.length && getCurrentCompanyId()" class="nav-item me-3 text-nowrap">
        <strong>{{ getCompanyName() }} - {{ getSalesforceName() }}</strong>
      </li>
      <li *ngIf="companies && companies.length && getCurrentCompanyId()" class="nav-item">
        <form (ngSubmit)="onSubmit()" class="d-md-block">
          <div class="input-group">
            <input type="text" name="search" [(ngModel)]="search" placeholder="Search..." class="form-control">
            <button class="btn btn-secondary">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </li>
    </ul>
    <ul id="main-menu" class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
          title="Dashboard">
          <i class="mdi mdi-av-timer fa-fw" data-icon="v"></i>
          <span class="d-md-inline">Dashboard <span class="fa fa-angle-down"></span></span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" routerLink="/dashboard" title="Home" routerLinkActive="active">
              <img src="/assets/img/nav_icons/chart-home.png">
              <span class="d-md-inline">Home</span>
            </a>
          </li>
          <li *ngIf="getCurrentCompanyId()">
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/charts/brand')" title="Chart-Brand"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/bureau.png">
              <span class="d-md-inline">Chart-Brand</span>
            </a>
          </li>
          <li *ngIf="getCurrentCompanyId()">
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/charts/company')" title="Chart-Company"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/chart-company.png">
              <span class="d-md-inline">Chart-Company</span>
            </a>
          </li>
          <li *ngIf="getCurrentCompanyId()">
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/charts/global')" title="Chart-Global"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/chart-global.png">
              <span class="d-md-inline">Chart-Global</span>
            </a>
          </li>
          <li *ngIf="getCurrentCompanyId()">
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/maps/event')" title="Chart-Global"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/chart-event.png">
              <span class="d-md-inline">Map Events</span>
            </a>
          </li>
          <li *ngIf="getCurrentCompanyId()">
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/maps/speaker')" title="Map Events"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/chart-speakers.png">
              <span class="d-md-inline">Map Speakers</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- EVENTS INFO SECTION STARTS HERE -->
      <li class="nav-item" *ngIf="getCurrentCompanyId()">
        <a class="nav-link" [routerLink]="getCompanySalesforceUrl('/events/bureau')" title="Events" id="event-icon"
          routerLinkActive="active">
          <i class="mdi mdi-wechat fa-fw" data-icon="v"></i>
          <span class="d-md-inline">Events <span class="fa fa-angle-down"></span></span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/events/bureau')" title="Bureau"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/bureau.png">
              <span class="d-md-inline">Bureau</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/requests')" title="Pending Requests"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/pending-request.png">
              <span class="d-md-inline">Pending Requests</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/overview')" title="Overview"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/preview.png">
              <span class="d-md-inline">Overview</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/events/calendar')" title="Calendar"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/calendar.png">
              <span class="d-md-inline">Calendar</span>
            </a>
          </li>
          <li *ngIf="authenticationService.is_staff() || authenticationService.is_agency_staff()">
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/events/public-calendar')"
              title="Public Calendar" routerLinkActive="active">
              <img src="/assets/img/nav_icons/public-calendar.png">
              <span class="d-md-inline">Public Calendar</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/events/monitor-live')" title="Monitor Live"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/monitor-live.png">
              <span class="d-md-inline">Monitor Live</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/forums')" title="Forums"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/forum.png">
              <span class="d-md-inline">Forums</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/non-bureau/events')" title="Non-Bureau"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/non-bureau.png">
              <span class="d-md-inline">Non-Bureau</span>
            </a>
          </li>
        </ul>

      </li>
      <!-- EVENTS INFO SECTION ENDS HERE -->

      <li class="nav-item" *ngIf="getCurrentCompanyId()">
        <a class="nav-link" [routerLink]="getCompanySalesforceUrl('/reports')" routerLinkActive="active"
          title="Resources">
          <i class="mdi mdi-clipboard-text fa-fw" data-icon="v"></i>
          <span class="d-md-inline">Resources <span class="fa fa-angle-down"></span>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/resource/presentations')" title="Presentations"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/presentation.png">
              <span class="d-md-inline">Presentations</span>
            </a>
          </li>

          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/reports')" title="Reports"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/reports.png">
              <span class="d-md-inline">Reports</span>
            </a>
          </li>

          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/reports-custom')" title="Reports - Custom"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/custom-reports.png">
              <span class="d-md-inline">Reports – Custom</span>
            </a>
          </li>

          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/resource/library')"
              title="Resource Library" routerLinkActive="active">
              <img src="/assets/img/nav_icons/resource-library.png">
              <span class="d-md-inline">Resource Library</span>
            </a>
          </li>

          <li>
            <a class="dropdown-item" [routerLink]="'/tools/topic'" [queryParams]="{ company_id: getCurrentCompanyId() }"
              title="Topics" routerLinkActive="active">
              <img src="/assets/img/nav_icons/presentation.png">
              <span class="d-md-inline">Topics</span>
            </a>
          </li>

          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/training-modules')"
              title="Training Modules" routerLinkActive="active">
              <img src="/assets/img/nav_icons/training-modules.png">
              <span class="d-md-inline">Training Modules</span>
            </a>
          </li>

          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/venues')" title="Venue Directory"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/venue-directory.png">
              <span class="d-md-inline">Venue Directory</span>
            </a>
          </li>

          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/profiles/venues')"
              title="Venue Nominations" routerLinkActive="active">
              <img src="/assets/img/nav_icons/venue-nominations.png">
              <span class="d-md-inline">Venue Nominations</span>
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item" *ngIf="getCurrentCompanyId()">
        <a class="nav-link" title="Payments" [routerLink]="getCompanySalesforceUrl('/finance/budgets')"
          [queryParams]="{fiscal_year: fiscalYearId}" routerLinkActive="active">
          <i class="mdi mdi-cash-multiple fa-fw" data-icon="v"></i>
          <span class="d-md-inline">Finance <span class="fa fa-angle-down"></span></span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" title="Balance" [routerLink]="getCompanySalesforceUrl('/finance/balance')"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/balance.png">
              <span class="d-md-inline">Balance </span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" title="Budgets" [routerLink]="getCompanySalesforceUrl('/finance/budgets')"
              [queryParams]="{fiscal_year: fiscalYearId}" routerLinkActive="active">
              <img src="/assets/img/nav_icons/budget.png">
              <span class="d-md-inline">Budgets</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" title="Payments" [routerLink]="getCompanySalesforceUrl('/finance/payments')"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/payment.png">
              <span class="d-md-inline">Payments </span>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a class="nav-link" title="People" routerLink="/profiles" routerLinkActive="active">
          <i class="mdi mdi-account-settings fa-fw"></i>
          <span class="d-md-inline">People <span class="fa fa-angle-down"></span></span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="getCurrentCompanyId()">
            <a class="dropdown-item" title="Attendees" [routerLink]="getCompanySalesforceUrl('/profiles/attendees')"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/attendees.png">
              <span class="d-md-inline">Attendees</span>
            </a>
          </li>
          <li *ngIf="getCurrentCompanyId()">
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/profiles/call_plans')" title="Speakers"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/call-plans.png">
              <span class="d-md-inline">Call Plans</span>
            </a>
          </li>
          <li *ngIf="!authenticationService.is_agency_staff() && getCurrentCompanyId()">
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/profiles/hcps')" title="HCP List"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/hcp-audience.png">
              <span class="d-md-inline">HCP Audience</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" title="KOL Contracting" [routerLink]="getCompanySalesforceUrl('/kol-contracting')"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/kol-contracting.png">
              <span class="d-md-inline">KOL Contracting</span>
            </a>
          </li>
          <!-- <li>
            <a class="dropdown-item"
              title="KOL Management"
              [routerLink]="getCompanySalesforceUrl('/kol-management')"
              routerLinkActive="active"
            >
              <i class="mdi mdi-chart-arc fa-fw"></i>
              <span class="d-md-inline">KOL Management </span>
            </a>
          </li> -->
          <!-- <li>
            <a class="dropdown-item" title="KOL Nominations" [routerLink]="getCompanySalesforceUrl('/kol-nominations')"
              routerLinkActive="active">
              <i class="mdi mdi-chart-arc fa-fw"></i>
              <span class="d-md-inline">KOL Nominations </span>
            </a>
          </li> -->
          <li>
            <a class="dropdown-item" [routerLink]="getCompanySalesforceUrl('/profiles/speakers')" title="Speakers"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/speakers.png">
              <span class="d-md-inline">Speakers</span>
            </a>
          </li>
          <li *ngIf="!authenticationService.is_agency_staff() && getCurrentCompanyId() && getCurrentAgencyId()">
            <a class="dropdown-item" [routerLink]="'/tools/agency/' + getCurrentAgencyId() +'/staff'" title="Staff"
              routerLinkActive="active">
              <img src="/assets/img/nav_icons/staff.png">
              <span class="d-md-inline">Staff</span>
            </a>
          </li>
          <li *ngIf="authenticationService.is_agency_staff()">
            <a class="dropdown-item" [routerLink]="'/tools/agency/' + authenticationService.staff_agency_id() +'/staff'"
              title="Staff" routerLinkActive="active">
              <img src="/assets/img/nav_icons/staff.png">
              <span class="d-md-inline">Staff</span>
            </a>
          </li>
          <li *ngIf="authenticationService.is_staff()">
            <a class="dropdown-item" routerLink="/profiles" title="Universe" routerLinkActive="active">
              <img src="/assets/img/nav_icons/universe.png">
              <span class="d-md-inline">Universe</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item" *ngIf="authenticationService.is_staff() || authenticationService.is_agency_staff()">
        <a class="nav-link" title="Tools" routerLink="/tools" title="Manage Company & Branding"
          routerLinkActive="active">
          <i class="mdi mdi-settings fa-fw" data-icon="v"></i>
          <span class="d-md-inline">Tools
            <!-- <span class="fa fa-angle-down"></span> -->
          </span>
        </a>
        <!-- <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" routerLink="/tools/company" title="Manage Company & Branding"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <i class="fa fa-building fa-fw"></i>
              <span class="d-md-inline">Manage Company & Branding</span>
            </a>
          </li>
          <li class="dropdown-submenu">
            <a class="dropdown-item" title="Manage Product, Topics & Content" href="javascript:void(0);">
              <i class="fa-fw mdi mdi-tooltip-edit"></i>
              <span class="d-md-inline">Manage Product, Topics & Content <span class="fa fa-angle-right"></span></span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" title="Medical" [routerLink]="getCompanySalesforceUrl('/tools/salesforce')"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <i class="fa-fw mdi mdi-tooltip-edit"></i>
                  <span class="d-md-inline">Medical</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" title="Promotional" href="javascript:void(0)">
                  <i class="fa-fw mdi mdi-tooltip-edit"></i>
                  <span class="d-md-inline">Promotional</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="dropdown-item" title="Manage Projects" href="javascript:void(0)">
              <i class="fa-fw fa fa-product-hunt"></i>
              <span class="d-md-inline">Manage Projects</span>
            </a>
          </li>
          <li class="dropdown-submenu">
            <a class="dropdown-item" title="Manage Users" href="javascript:void(0)">
              <i class="fa fa-user fa-fw"></i>
              <span class="d-md-inline">Manage Users <span class="fa fa-angle-right"></span></span>
            </a>
            <ul class="dropdown-menu">
              <li class="dropdown-submenu">
                <a class="dropdown-item" title="Company" href="javascript:void(0)">
                  <i class="fa fa-building fa-fw"></i>
                  <span class="d-md-inline">Company <span class="fa fa-angle-right"></span></span>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item" title="Manage Sales Force Hiearchy" href="javascript:void(0);">
                      <i class="fa-fw mdi mdi-poll"></i>
                      <span class="d-md-inline">Manage Sales Force Hiearchy</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" title="Manage Home Office" href="javascript:void(0);">
                      <i class="fa fa-home fa-fw"></i>
                      <span class="d-md-inline">Manage Home Office</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="dropdown-submenu">
                <a class="dropdown-item" title="Speaker" href="javascript:void(0)">
                  <i class="fa fa-user fa-fw"></i>
                  <span class="d-md-inline">Speaker <span class="fa fa-angle-right"></span></span>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item" title="Manage Speaker Business Rules" href="javascript:void(0)">
                      <i class="fa fa-user fa-fw"></i>
                      <span class="d-md-inline">Manage Speaker Business Rules</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" title="Manage Speaker Profiles" href="javascript:void(0)">
                      <i class="fa fa-user fa-fw"></i>
                      <span class="d-md-inline">Manage Speaker Profiles</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a class="dropdown-item" title="HCP" href="javascript:void(0);">
                  <i class="fa fa-user fa-fw"></i>
                  <span class="d-md-inline">HCP</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="dropdown-item" title="Manage Event Types"
              [routerLink]="getCompanySalesforceUrl('/tools/meeting_type')">
              <i class="fa fa-tasks fa-fw"></i>
              <span class="d-md-inline">Manage Event Types</span>
            </a>
          </li>
          <li class="dropdown-submenu">
            <a class="dropdown-item" title="Manage Events" href="javascript:void(0)">
              <i class="fa fa-comments fa-fw"></i>
              <span class="d-md-inline">Manage Events <span class="fa fa-angle-right"></span></span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" title="Event Business Rules" href="javascript:void(0)">
                  <i class="fa fa-comments fa-fw"></i>
                  <span class="d-md-inline">Event Business Rules</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" title="Event Types" href="javascript:void(0)">
                  <i class="fa fa-comments fa-fw"></i>
                  <span class="d-md-inline">Event Types</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="dropdown-item" title="Manage Speaker Nomination/Contracts" href="javascript:void(0)">
              <i class="fa-fw mdi mdi-poll"></i>
              <span class="d-md-inline">Manage Speaker Nomination/Contracts</span>
            </a>
          </li>
          <li class="dropdown-submenu">
            <a class="dropdown-item" title="Manage Notifications" href="javascript:void(0)">
              <i class="fa fa-bell fa-fw"></i>
              <span class="d-md-inline">Manage Notifications <span class="fa fa-angle-right"></span></span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" title="Actions Required" href="javascript:void(0)">
                  <i class="fa fa-bell fa-fw"></i>
                  <span class="d-md-inline">Actions Required</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" title="Notifications" href="javascript:void(0)">
                  <i class="fa fa-bell fa-fw"></i>
                  <span class="d-md-inline">Notifications</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="dropdown-item" title="Manage Budgets" href="javascript:void(0)">
              <i class="fa fa-money fa-fw"></i>
              <span class="d-md-inline">Manage Budgets</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" title="Manage Expense Categories" href="javascript:void(0)">
              <i class="fa fa-usd fa-fw"></i>
              <span class="d-md-inline">Manage Expense Categories</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" title="Manage Documents" href="javascript:void(0)">
              <i class="fa fa-file-excel-o fa-fw"></i>
              <span class="d-md-inline">Manage Documents</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" title="Manage Samples" href="javascript:void(0)">
              <i class="fa-fw mdi mdi-poll"></i>
              <span class="d-md-inline">Manage Samples</span>
            </a>
          </li>
        </ul> -->
      </li>
    </ul>
  </div>
</nav>
