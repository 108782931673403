import { Component, OnInit } from '@angular/core';

import { COMPANY_NAME, COMPANY_SITE } from 'app/app.constants';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit {
  COMPANY_NAME = COMPANY_NAME;
  COMPANY_SITE = COMPANY_SITE;
  constructor() { }

  ngOnInit() {
  }

}
