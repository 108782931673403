import { Component, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { getErrorMessage } from 'app/_utils/helpers';

import {
  BaseService,
  S3Service,
  S3File,
} from 'app/_services';

@Component({
  selector: 'app-s3-uploader',
  templateUrl: './s3-uploader.component.html',
})
export class S3UploaderComponent {
  @Input() s3PutUrlService: BaseService = null;
  @Input() s3PutUrlServiceParams: any = {};
  @Input() s3PutUrlServicePayload: any = {};
  @Input() updateS3Url: boolean = false;
  @Output() uploaded: EventEmitter<S3File> = new EventEmitter<S3File>();
  accept: string;
  constructor(
    private elementRef: ElementRef,
    private toastr: ToastrService,
    private s3Service: S3Service,
  ) {
    this.accept = this.elementRef.nativeElement.getAttribute('accept');
  }

  _file: File | null = null;
  _uploading: boolean = false;
  _uploadingPercentage: number = 0;

  onFileChange(event) {
    if (event.addedFiles && event.addedFiles.length) {
      this._file = event.addedFiles[0];
    }
  }

  uploadToS3() {
    this._uploading = true;
    this._uploadingPercentage = 0;
    this.s3PutUrlService.getS3PutUrl({
      ...this.s3PutUrlServicePayload,
      file: {
        name: this._file?.name,
        type: this._file?.type,
      },
    }, this.s3PutUrlServiceParams).subscribe((s3File: S3File) => {
      this.s3Service.putObject(s3File.url, this._file).subscribe((event) => {
        if (event.ok) {
          this._file = null;
          this._uploading = false;
          this.toastr.success('Uploaded!');
          if (this.updateS3Url) {
            this.s3PutUrlService.updateS3Url({
              ...this.s3PutUrlServicePayload,
              file: { key: s3File.key },
            }, this.s3PutUrlServiceParams).subscribe((s3File: S3File) => {
              this.uploaded.emit(s3File);
            });
          } else {
            this.uploaded.emit(s3File);
          }
        } else {
          this._uploadingPercentage = Math.round(event.loaded * 10000 / event.total) / 100.0;
          if (this._uploadingPercentage >= 100) this._uploadingPercentage = 99.99;
        }
      }, err => {
        this._uploading = false;
        this.toastr.error(getErrorMessage(err));
      });
    }, err => {
      this._uploading = false;
      this.toastr.error(getErrorMessage(err));
    });
  }
}
