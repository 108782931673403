import { Base } from './_base.model';

export class ForumResource extends Base {
  id: number;
  forum_id: number;
  description: string;
  filename: string;
  mimetype: string;
  s3path: string;
  uploaded_by: number;
  uploaded_at: Date;

  deserialize(obj: any) {
    Object.assign(this, obj);
    return this;
  }

  isImage() {
    return ['image/png', 'image/jpeg'].indexOf(this.mimetype) >= 0;
  }
}
