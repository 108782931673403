import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})

export class MeetingService {

  constructor(private http: HttpClient) {}

  createMeeting(token: string): Observable<string> {
    const apiUrl = "https://api.videosdk.live/v2/rooms";
    const headers = new HttpHeaders({
      authorization: token,
      "Content-Type": "application/json",
    });

    return this.http
      .post<{ roomId: string }>(apiUrl, {}, { headers })
      .pipe(map((response) => response.roomId));
  }

  validateMeeting(token: string, meetingId: string): Observable<boolean> {
    const url = `https://api.videosdk.live/v2/rooms/validate/${meetingId}`;
    const headers = new HttpHeaders({
      authorization: token,
      "Content-Type": "application/json",
    });

    return this.http
      .get<{ roomId: string }>(url, {
        headers,
      })
      .pipe(map((response) => response.roomId === meetingId));
  }

  ngOnInit() {}
}