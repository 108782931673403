import { Base } from './_base.model';

export class Evaluation extends Base {
  id: number;
  name: string;
  appearance_id: number;

  deleted: boolean;
  evaluation_questions: any[];
  meeting_types: number[];
  topics: number[];
  fiscal_years: string[];

  constructor() {
    super();
    this.evaluation_questions = [];
  }

  deserialize(obj: any) {
    Object.assign(this, obj);
    this.meeting_types = obj.evaluation_meeting_types.map(em => em.meeting_type_id);
    this.topics = obj.evaluation_topics.map(em => em.topic_id);
    this.fiscal_years = obj.evaluation_fiscal_years.map(em => em.fiscal_year_id);
    return this;
  }
}

