import { Base } from './_base.model';

export class RecommendedFor extends Base {
  id: number;
  salesforce_ids: string[];
  archived: boolean;
  rank: number;
  constructor() {
    super();
  }
}
