import {
  Component,
  forwardRef,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-color-picker',
  template: `<input #colorInput type="text" class="form-control demo" data-control="hue">`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('colorInput') colorInput: ElementRef;

  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;
  private colorPicker: any;

  get model() {
    return this._model;
  }

  set(value: any) {
    this._model = value;
    if (this.onChange) {
      this.onChange(this._model);
    }
  }

  public writeValue(value: any): void {
    this._model = value;
    if (this.colorPicker) {
      this.colorPicker.minicolors('value', this._model);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngAfterViewInit() {
    const self = this;
    this.colorPicker = $(this.colorInput.nativeElement).minicolors({
      control: 'hue',
      format: 'hex',
      inline: false,
      letterCase: 'lowercase',
      theme: 'bootstrap',
      change: function (value) {
        self.set(value);
      }
    });
  }
}
