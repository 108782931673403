import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class EventSpeakerSignService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/speaker_sign`);
  }

  override update(model: any, urlParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${model.speaker_id}`;
    return this.http.put<any>(url, model);
  }
}