import { Base } from './_base.model';
import { ContactLicense } from './contact_license.model';
import { ContactAddress } from './contact_address.model';
import { Assistant } from './assistant.model';

export class Contact extends Base {
  id: string;
  company_id: string;
  call_plan_id: number;
  clientid: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  suffix: string;
  gender: string;
  degrees: string[];
  other_degree: string;
  profession: string;
  travel_preferences: any[];
  vendor_code: string;
  npi_no: string;
  npi_no_missing: string;
  npi_registry: any;
  salutation: string;
  title: string;
  home_phone: string;
  cell_phone: string;
  email: string;
  pager_number: string;
  not_reportable: string;
  office2_sameas: string;
  mailing_sameas: string;
  voice_mail: string;
  send_to: string;
  emergency_phone: string;
  emergency_contact: string;
  general_comment: string;
  invitations: boolean;
  reminders: boolean;
  w9_received: boolean;
  w9_received_on: Date;
  fmv_exception: string;
  plan_exception: string;
  tax_id: string;
  ffs_name: string;
  expenses_name: string;
  preferred_communication_methods: string[];
  sendcheck_sameas: string;
  speaker_trained: string[];
  recommended_for: string[];
  speaker_level_id: string;
  speaker_type: string;
  clinical_investigator_trials: string[];
  blocked_dates: string;
  comments: string;
  affiliation: string;
  education: string;
  med_school: string;
  residency: string;
  fellowship: string;
  committees: string;
  societies: string;
  positions_held: string;
  addl_languages: string;
  certification: string;
  huron_specialty: string;
  specialties: string[];
  subspecialty: string;
  subspecialties: string[];
  other_subspecialties: string;
  other_specialties: string;
  personal_data: string;
  me_number: string;
  current_positions: string;
  conflicts: string;
  clinical_trials: string;
  research_interests: string;
  publications: string;
  airport_preferences: string;
  plane_seat_preference: string;
  frequent_flyer_information: string;
  hotel_room_preferences: string;
  dietary_restrictions: string;
  special_needs: string;
  speaker_cap: number;
  default_address: any;
  licenses: ContactLicense[];
  accept_terms_at: Date;
  user: any;
  office: any;
  office2: any;
  mailing: any;
  sendcheck: any;
  addresses: ContactAddress[];
  meta: any;
  hcp_type: string;
  hce_id: string;
  mdm_id: string;
  ims_id: string;
  licensed_in_mt: boolean;
  licensed_in_vt: boolean;
  licensed_outside_us: boolean;
  medical_professional: boolean;
  hcp_flag: boolean;
  government_employee: boolean;
  assistant: Assistant;
  taxonomy: string;
  bio: any;
  cv: any;
  photo: any;
  opt_out: boolean;
  plaid_status: boolean;

  deserialize(obj: any) {
    Object.assign(this, obj);
    return this;
  }

  full_name(): string {
    return ['first_name', 'middle_name', 'last_name'].map(x => this[x]).filter(Boolean).join(' ') || 'N/A';
  }

  long_full_name(): string {
    return [this.full_name(), this.degrees].filter(Boolean).join(', ') || 'N/A';
  }

  last_name_first(): string {
    return ['last_name', 'first_name'].map(x => this[x]).filter(Boolean).join(', ') || 'N/A';
  }

  name_chars(): string {
    return ['first_name', 'last_name'].filter(x => this[x]).map(x => this[x][0]).join('');
  }
}
