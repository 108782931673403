<div class="modal-header">
  <h5 class="modal-title" *ngIf="step===0">Please use the below template for importing attendees</h5>
  <h5 class="modal-title" *ngIf="step===1">Imported attendees</h5>
  <h5 class="modal-title" *ngIf="step===2">Imported result</h5>
  <button type="button" class="btn btn-danger float-end" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="modal-body row">
  <div class="col-sm-12 mb-3" *ngIf="step===0">
    <div class="mb-3">
      <a href="assets/file/Attendee Import Template.xlsx" class="btn btn-primary"><i class="fa fa-download"></i>
        Download Template</a>
    </div>
    <div>
      <form [formGroup]="uploadForm">
        <div class="mb-3">
          <input type="file" class="form-control" (click)="onClick($event)" (change)="onFileChange($event)" formControlName="xlsx_file"
            class="dropify" accept=".xlsx" multiple="false" #xlsxFileInput>
        </div>
      </form>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="errText">
      {{errText}}
    </div>
  </div>
  <div class="col-sm-12 mb-3" *ngIf="step===1">
    <label class="form-check-label" for="hostConfrimed">
      <input type="checkbox" class="form-check-input" id="hostConfrimed" [(ngModel)]="hostConfrimed"> Mark as Registered by Host
    </label>
    <button class="btn btn-success float-end" (click)="onComplete()">Complete Attendee Import</button>
  </div>
  <div class="col-sm-12 mb-3" style="overflow-x: auto;">
    <table class="table table-striped" *ngIf="xlsxData.length && step!==2">
      <thead>
        <tr>
          <th>Last Name</th>
          <th>First Name</th>
          <th>Middle Name</th>
          <th>Address 1</th>
          <th>Address 2</th>
          <th>City</th>
          <th>State</th>
          <th>Zip</th>
          <th>Country</th>
          <th>Specialty</th>
          <th>Other Specialty</th>
          <th>NPI</th>
          <th>License State</th>
          <th>License #</th>
          <th>Telephone</th>
          <th>Email</th>
          <th>Suffix</th>
          <th>Credentials</th>
          <th>Professional Type</th>
          <th>Other Credentials</th>
          <th>Client ID</th>
          <th>Affiliation</th>
        </tr>
      </thead>
      <tbody *ngIf="step===0">
        <tr *ngFor="let item of xlsxData; index as i">
          <td class="glyphicon glyphicon-warning-sign">{{item['Last Name']}}</td>
          <td>{{item['First Name']}}</td>
          <td>{{item['Middle Name']}}</td>
          <td>{{item['Address 1']}}</td>
          <td>{{item['Address 2']}}</td>
          <td>{{item['City']}}</td>
          <td>{{item['State']}}</td>
          <td>{{item['Zip']}}</td>
          <td>{{item['Country']}}</td>
          <td>{{item['Specialty']}}</td>
          <td>{{item['Other Specialty']}}</td>
          <td>{{item['NPI']}}<i class="material-icons small-icon" *ngIf="checkDuplicates(item, 'NPI')">error_outline</i>
          </td>
          <td>{{item['License State']}}<i class="material-icons small-icon"
              *ngIf="checkDuplicates(item, 'License State') && checkDuplicates(item, 'License #')">error_outline</i></td>
          <td>{{item['License #']}}<i class="material-icons small-icon"
              *ngIf="checkDuplicates(item, 'License State') && checkDuplicates(item, 'License #')">error_outline</i></td>
          <td>{{item['Telephone']}}</td>
          <td>{{item['Email']}}</td>
          <td>{{item['Suffix']}}</td>
          <td>{{item['Credentials']}}</td>
          <td>{{item['Professional Type']}}</td>
          <td>{{item['Other Credentials']}}</td>
          <td>{{item['Client ID']}}</td>
          <td>{{item['Affiliation']}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="step===1">
        <tr *ngFor="let attendee of importedAttendees">
          <td>{{attendee.last_name}}</td>
          <td>{{attendee.first_name}}</td>
          <td>{{attendee.middle_name}}</td>
          <td>{{attendee.address.address1}}</td>
          <td>{{attendee.address.address2}}</td>
          <td>{{attendee.address.city}}</td>
          <td>{{attendee.address.state}}</td>
          <td>{{attendee.address.zip}}</td>
          <td>{{attendee.address.country}}</td>
          <td>{{attendee.specialties?.join(',')}}</td>
          <td>{{attendee.other_specialties}}</td>
          <td>{{attendee.npi_no}}</td>
          <td>{{attendee.licenses[0]?.license}}</td>
          <td>{{attendee.licenses[0]?.state}}</td>
          <td>{{attendee.cell_phone}}</td>
          <td>{{attendee.email}}</td>
          <td>{{attendee.suffix}}</td>
          <td>{{attendee.degrees?.join(',')}}</td>
          <td>{{attendee.profession}}</td>
          <td>{{attendee.other_degree}}</td>
          <td>{{attendee.clientid}}</td>
          <td>{{attendee.address.affiliation}}</td>
        </tr>
      </tbody>
    </table>
    <table class="table table-striped" *ngIf="step===2">
      <thead>
        <tr>
          <th>Last Name</th>
          <th>First Name</th>
          <th>City</th>
          <th>State</th>
          <th>NPI</th>
          <th>Import</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let attendee of importedResult">
          <td>{{attendee.last_name}}</td>
          <td>{{attendee.first_name}}</td>
          <td>{{attendee.address.city}}</td>
          <td>{{attendee.address.state}}</td>
          <td>{{attendee.npi_no}}</td>
          <td [ngClass]="{'text-danger': attendee.limited}"><b>{{attendee.limited ? 'Failed' : 'Success'}}</b></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-sm-12 mb-3 text-center">
    <button class="btn btn-primary" (click)="importData()" [disabled]="!bImport" *ngIf="step===0">Import</button>
    <button class="btn btn-success float-end" (click)="onComplete()" *ngIf="step===1">Complete Attendee Import</button>
    <button class="btn btn-primary float-end" (click)="bsModalRef.hide()" *ngIf="step===2">Close</button>
  </div>
</div>
