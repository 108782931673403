import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { callingCountries } from 'country-data-list';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }]
})

export class PhoneInputComponent implements ControlValueAccessor {
  @Input() required: boolean = false;
  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;
  countryDatas: any[] = [];
  phoneNumber: string;
  phoneCountryCode: string = '+1';

  constructor() {
    this.countryDatas = callingCountries.all.map(item => ({ name: item.name, phone_code: item.countryCallingCodes[0] }));
  }

  writeValue(value: any) {
    this._model = value;
    if (this._model) {
      this.dividePhoneNumber();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  updatePhoneNumber() {
    this._model = `${this.phoneCountryCode} ${this.phoneNumber}`;
    this.onChange(this._model);
  }

  dividePhoneNumber() {
    if (this._model) {
      const i = this._model.lastIndexOf(' ');
      if (i >= 0) {
        this.phoneNumber = this._model.substring(i + 1);
        this.phoneCountryCode = this._model.substring(0, i);
      } else {
        this.phoneNumber = this._model;
        this.phoneCountryCode = '';
      }
    } else {
      this.phoneNumber = '';
      this.phoneCountryCode = '';
    }
  }
}
