import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IMAGES } from '../app.constants';

import { AuthenticationService, CompanyService } from '../_services';

import { getErrorMessage, getDetailedErrorMessage, getFieldErrorMessage, getCompanySalesforceUrl } from 'app/_utils/helpers';

@Component({
  selector: 'app-base',
  template: `<div></div>`,
})
export class BaseComponent {
  public IMAGES: any;

  constructor(
    protected router: Router,
    protected authenticationService: AuthenticationService,
    protected companyService: CompanyService,
  ) {
    this.IMAGES = IMAGES;
  }

  getTimezoneId() {
    return this.authenticationService.timezone_id();
  }

  getCurrentCompanyId() {
    return this.authenticationService.current_company_id();
  }

  getCurrentCompanyName() {
    return this.authenticationService.current_company_name();
  }

  getCurrentSalesforceId() {
    return this.authenticationService.current_salesforce_id();
  }

  getCurrentSalesforceName() {
    return this.authenticationService.current_salesforce_name();
  }

  getCompany() {
    return this.companyService.getCompany(this.getCurrentCompanyId());
  }

  getCompanyName() {
    const company = this.getCompany();
    return company ? company.name : '';
  }

  getSalesforce() {
    return this.companyService.getSalesforce(this.getCurrentCompanyId(), this.getCurrentSalesforceId());
  }

  getSalesforceName() {
    const salesforce = this.getSalesforce();
    return salesforce ? salesforce.name : '';
  }

  getCompanyModuleUrl(module) {
    return `${module}.${this.getCompanyName()}`;
  }

  getCompanySalesforceModuleUrl(module) {
    return `${module}.${this.getCompanyName()}.${this.getSalesforceName()}`;
  }

  getTitle(item) {
    return this.getCurrentSalesforceName() + '-' + item;
  }

  url(path = '') {
    return this.router.url.split('?')[0] + path;
  }

  // getCurrentFiscalYear() {
  //   return this.companyService.getCurrentFiscalYear(this.getCurrentCompanyId());
  // }

  // getCurrentFiscalYearId() {
  //   const fiscalYear = this.getCurrentFiscalYear();
  //   return fiscalYear ? fiscalYear.id : null;
  // }

  // getCurrentFiscalYearTitle() {
  //   const fiscalYear = this.getCurrentFiscalYear();
  //   return fiscalYear ? fiscalYear.title : '';
  // }

  refresh(router, queryParams: any = {}) {
    const url = decodeURIComponent(router.url.split('?')[0]);
    router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() =>
      router.navigate([url], { queryParams })
    );
  }

  getErrorMessage(err, message = '') {
    return getErrorMessage(err, message);
  }

  getDetailedErrorMessage(err, message = '') {
    return getDetailedErrorMessage(err, message);
  }

  getFieldErrorMessage(err, message = '') {
    return getFieldErrorMessage(err, message);
  }

  getCompanySalesforceUrl(url, companyName: string = '', salesforceName: string = ''): string {
    if (!companyName) {
      companyName = this.getCurrentCompanyName();
    }
    if (!salesforceName) {
      salesforceName = this.getCurrentSalesforceName();
    }
    return getCompanySalesforceUrl(url, companyName, salesforceName);
  }

  navigate(router, url: string, params = {}, companyName: string = '', salesforceName: string = '') {
    if (!companyName) {
      companyName = this.getCurrentCompanyName();
    }
    if (!salesforceName) {
      salesforceName = this.getCurrentSalesforceName();
    }
    router.navigate([getCompanySalesforceUrl(url, companyName, salesforceName)], params);
  }

  navigateRelative(route, url, params = {}) {
    this.router.navigate([url], { relativeTo: route, ...params });
  }

  getEventUrl(eventId: string, url: string = '', companyName: string = '', salesforceName: string = '') {
    return this.getCompanySalesforceUrl("/events/" + eventId + (url ? '/' + url : ''), companyName, salesforceName);
  }

  getNonBureauEventUrl(eventId: string, url: string = '', companyName: string = '', salesforceName: string = '') {
    return this.getCompanySalesforceUrl("/non-bureau/events/" + eventId + (url ? '/' + url : ''), companyName, salesforceName);
  }

  extendLists(list: any[], values: string[]) {
    values.forEach(value => {
      if (value && !list.map(r => r.value).includes(value)) {
        list.push({ value });
      }
    });
    return list;
  }

  extendList(list: any[], value: string) {
    if (value && !list.map(r => r.value).includes(value)) {
      list.push({ value });
    }
    return list;
  }

  openInPopup(url: string, width: number = 500, height: number = 500) {
    window.open(url, "popup", `width=${width},height=${height}`);
  }

  openImageInPopup(imageUrl: string) {
    this.openContentPopup(`<img src="${imageUrl}" style="max-width: 100%; max-height: 100%;">`);
  }

  openContentPopup(content: string, width: number = 500, height: number = 500) {
    const myWindow = window.open("", "", `width=${width},height=${height},top=150,left=150`);
    if (myWindow) myWindow.document.write(content);
  }

  openTasksPopup(eventId, card) {
    let url = this.getEventUrl(eventId, 'tasks-popup');
    if (card) {
      url = url + '?card=' + card;
    }
    this.openInPopup(url, 800, 500);
  }
}
