import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'commaList' })
export class CommaListPipe implements PipeTransform {
  transform(input: any): string {
    if (!input) {
      return input;
    }
    return input.join(', ');
  }
}
