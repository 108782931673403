import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: '[appOneChecked]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: OneCheckedValidatorDirective,
    multi: true
  }]
})
export class OneCheckedValidatorDirective implements Validator {
  @Input() requiredCheckboxGroups: string = '';
  validate(control: AbstractControl): ValidationErrors | null {
    let result: any = null;
    if (this.requiredCheckboxGroups) {
      this.requiredCheckboxGroups.split(',').forEach((groupName) => {
        const checked = Object.keys(control.value).filter((field) => field.startsWith(groupName) && control.get(field)?.value).length;
        if (!checked) { // no one checked
          result = result || {};
          result[groupName] = true; // invalid
        }
      });
    }
    return result;
  }
}