import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { BaseComponent } from 'app/_components/base.component';

import { AuthenticationService, CompanyService } from 'app/_services';

import { REQUEST_FORM_SUB_TYPE } from 'app/app.constants';

@Component({
  selector: 'app-request-question-row-create',
  templateUrl: './request-question-row-create.component.html',
})
export class RequestQuestionRowCreateComponent extends BaseComponent implements OnInit {
  REQUEST_FORM_SUB_TYPE = REQUEST_FORM_SUB_TYPE;
  @Input() priority: string;
  @Input() questions: any[];
  @Input() newQuestion: any;
  @Input() questionTypes: any[];
  @Input() dedicatedQuestionTypes: any[];
  @Input() nextScreens: any[];
  @Input() depth: number;

  subQuestion: any;

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    private toastr: ToastrService,
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit() {

  }

  resetNewQuestion() {
    this.newQuestion = {
      type_id: null,
      sub_type: null,
      text: '',
      packages: null,
      equipments: null,
      min: 1,
      max: 1,
      skipScreen: -1,
      subQuestions: []
    };
  }

  onAddNewQuestion() {
    if (this.newQuestion.type_id) {
      if (this.newQuestion.min) this.newQuestion.min = +this.newQuestion.min;
      if (this.newQuestion.max) this.newQuestion.max = +this.newQuestion.max;
      this.questions.push(this.newQuestion);
      this.newQuestion.rank = this.questions.length;
      this.resetNewQuestion();
    } else {
      this.toastr.error('Question Type is missing!');
    }
  }

  hasDedicatedQuestion() {
    return !!this.questions.find(q => this.dedicatedQuestionTypes.includes(q.type_id));
  }
}
