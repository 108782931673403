import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { BaseComponent } from 'app/_components/base.component';
import { AuthenticationService, CompanyService, VendorService, DropdownService } from 'app/_services';
import { Vendor } from 'app/_models';
import { VendorEditFormComponent } from '../vendor-edit-form/vendor-edit-form.component';

@Component({
  selector: 'app-vendor-edit-modal',
  templateUrl: './vendor-edit-modal.component.html'
})
export class VendorEditModalComponent extends BaseComponent implements OnInit {
  @ViewChild('vendorEditForm') vendorEditForm: VendorEditFormComponent;
  public event: EventEmitter<any> = new EventEmitter();
  vendorId: number;
  vendor: Vendor;
  states: any[];
  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private vendorService: VendorService,
    private dropdownService: DropdownService,
  ) {
    super(router, authenticationService, companyService);
    this.vendor = new Vendor();
  }

  public ngOnInit() {
    this.dropdownService.getDropdowns('CountryState').subscribe((lists) => {
      this.states = lists.CountryState;
    });
    this.vendorService.get(this.vendorId, { company_id: this.getCurrentCompanyId() }).subscribe((vendor) => {
      this.vendor = vendor;
    });
  }

  onSubmit() {
    this.vendorService.update(this.vendor, { company_id: this.getCurrentCompanyId() })
      .subscribe(vendor => {
        this.toastr.success('Updated!');
        this.bsModalRef.hide();
        this.event.emit(vendor);
      }, err => {
        this.toastr.error(this.getErrorMessage(err));
      });
  }
}
