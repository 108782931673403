<div class="mb-3">
  <div [class]="questionClass" *ngIf="!noQuestionText && question.type_id!=='HEADER' && question.type_id!=='PARAGRAPH'">
    {{question.text}}
  </div>
  <div *ngIf="question.type_id==='TF'">
    <select name="answer_{{question.id}}" class="form-control" [(ngModel)]="answers[question.id]" [disabled]="disabled">
      <option [ngValue]="value" *ngFor="let value of ['', 'True', 'False']">{{value}}</option>
    </select>
  </div>
  <div *ngIf="question.type_id==='YN' || question.type_id==='food_served' || question.type_id==='collaborate'">
    <div class="form-check" *ngFor="let value of ['', 'Yes', 'No'];index as i">
      <input type="radio" class="form-check-input" name="answer_{{question.id}}" [value]="value"
        [(ngModel)]="answers[question.id]" id="answer_{{question.id}}_{{i}}">
      <label class="form-check-label" for="answer_{{question.id}}_{{i}}"> {{value === '' ? 'N/A' : value}}</label>
    </div>
  </div>
  <div *ngIf="question.type_id==='CHECKBOX'">
    <div class="form-check">
      <input type="checkbox" class="form-check-input" name="answer_{{question.id}}" value="true"
        [(ngModel)]="answers[question.id]">
      <label class="form-check-label" for="answer_{{question.id}}"></label>
    </div>
  </div>
  <div *ngIf="question.type_id==='MEAL_OPT_OUT'">
    <div class="form-check">
      <input type="checkbox" class="form-check-input" name="answer_{{question.id}}" value="true"
        [(ngModel)]="answers[question.id]">
      <label class="form-check-label" for="answer_{{question.id}}"></label>
    </div>
  </div>
  <div *ngIf="question.type_id==='RATING'">
    <rating [(ngModel)]="answers[question.id]" max="5"></rating>
  </div>
  <div *ngIf="question.type_id==='COMMENTS' || question.type_id==='TEXT_AREA'">
    <textarea rows="4" cols="60" name="answer_{{question.id}}" class="form-control"
      [(ngModel)]="answers[question.id]"></textarea>
  </div>
  <div *ngIf="question.type_id==='TEXT_FIELD'">
    <input type="text" name="answer_{{question.id}}" class="form-control" [(ngModel)]="answers[question.id]">
  </div>
  <div *ngIf="question.type_id==='PHONE_NUMBER'">
    <app-phone-input name="answer_{{question.id}}" [(ngModel)]="answers[question.id]"></app-phone-input>
  </div>
  <div *ngIf="question.type_id==='MULTIPLE_RADIO'">
    <ng-template ngFor let-value let-i="index" [ngForOf]='choices'>
      <div class="form-check" *ngIf='value'>
        <input type="radio" class="form-check-input" name="answer_{{question.id}}_{{i}}" [value]="value"
          [(ngModel)]="answers[question.id]" id="answer_{{question.id}}_{{i}}" [disabled]="disabled">
        <label class="form-check-label" for="answer_{{question.id}}_{{i}}"> {{value}}</label>
      </div>
    </ng-template>
  </div>
  <div *ngIf="question.type_id==='MULTIPLE_CHECK'">
    <ng-select [items]='choices' [multiple]="true" [(ngModel)]="answers[question.id]">
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <input type="checkbox" [ngModel]="item.selected" name="answer_{{question.id}}_{{index}}" [disabled]="disabled">
        {{item}}
      </ng-template>
    </ng-select>
  </div>
  <div *ngIf="question.type_id==='DROPDOWN'">
    <select name="answer_{{question.id}}" class="form-control" [(ngModel)]="answers[question.id]">
      <option [ngValue]="value" *ngFor='let value of choices'>{{value}}</option>
    </select>
  </div>
  <div *ngIf="question.type_id==='SIGNATURE'">
    <div fxLayout="row">
      <button type="button" (click)="signatureField.clear()" [disabled]="!answers[question.id]">Clear</button>
    </div>
    <div fxLayout="row">
      <app-signature-field [(ngModel)]="answers[question.id]"></app-signature-field>
    </div>
  </div>
  <div *ngIf="question.type_id==='NUMBER' || question.type_id==='speaker_count'">
    <input type="text" name="answer_{{question.id}}" class="form-control" [(ngModel)]="answers[question.id]">
  </div>
  <div class="{{questionHeaderClass}} b-none m-t-10" *ngIf="question.type_id==='HEADER'">
    {{question.text}}
  </div>
  <p *ngIf="question.type_id==='PARAGRAPH'">
    {{question.text}}
  </p>
  <div
    *ngIf="question.type_id==='AMOUNT' || question.type_id==='attendee_count_estimate' || question.type_id==='client_count_estimate'">
    <input type="text" name="answer_{{question.id}}" class="form-control" [(ngModel)]="answers[question.id]">
  </div>
  <div *ngIf="question.type_id==='DATE'">
    <input type="text" name="answer_{{question.id}}" [(ngModel)]="answers[question.id]"
      class="form-control datetimepicker" autocomplete="off" bsDatepicker>
  </div>
  <div *ngIf="question.type_id==='FILE'">
    <div *ngIf="answers[question.id]">{{answers[question.id].name}}</div>
    <input type="file" name="answer_{{question.id}}" class="dropify" (change)="onFileChange($event)" />
  </div>
  <div *ngIf="question.other">
    <textarea rows="4" cols="60" name="answer_other_{{question.id}}" class="form-control"
      [(ngModel)]="answers[question.id + '_other']"></textarea>
  </div>
</div>
