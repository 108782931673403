<div>
  <input #fileInput class="form-control mb-3" type="file" (change)="fileChangeEvent($event)" />
  <image-cropper *ngIf="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png"
    resizeToWidth="100" resizeToHeight="100" [imageChangedEvent]="imageChangedEvent" (imageLoaded)="imageLoaded()"
    (imageCropped)="imageCropped($event)"></image-cropper>
  <div *ngIf="current && cropperReady">
    <a href="javascript:void(0)" title="reset" class="btn btn-secondary btn-sm" (click)="reset()">
      <i class="fa fa-times"></i>
    </a>
  </div>
  <div *ngIf="current && !cropperReady">
    <img [src]="current" class="mw-100" />
    <div class="my-2">
      <a [href]="current" title="download" class="btn btn-primary btn-sm" target="_blank">
        <i class="fa fa-download"></i>
      </a>
      <a href="javascript:void(0)" title="delete" class="btn btn-danger btn-sm" (click)="deleteCurrent()">
        <i class="fa fa-trash"></i>
      </a>
    </div>
  </div>
</div>