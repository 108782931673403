import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { QuestionTooltip } from 'app/_models';

@Injectable()
export class QuestionTooltipService extends BaseService {
  public tooltips: QuestionTooltip[];

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/question_tooltips`);
  }

  override list(apiParams: any = {}, apiCall: boolean = false): Observable<QuestionTooltip[]> {
    if (this.tooltips && !apiCall && !(apiParams.all || apiParams.status)) {
      return new Observable((observer) => {
        observer.next(this.tooltips);
      });
    }
    return super.list({}, apiParams)
      .pipe(
        tap((tooltips) => {
          if (!(apiParams.all || apiParams.status)) {
            this.tooltips = tooltips;
          }
        }),
      );
  }

  getTooltip(tooltip_id: string): QuestionTooltip | null {
    if (this.tooltips) {
      for (const tooltip of this.tooltips) {
        if (tooltip.id.toString() === tooltip_id.toString()) {
          return new QuestionTooltip().deserialize(tooltip);
        }
      }
    }
    return null;
  }
}
