import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class EvaluationService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/evaluations`);
  }

  listDefaults(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${this.baseUrl}/default`, urlParams);
    return this.http.get(url, {
      params: this.toHttpParams(apiParams)
    });
  }

  updateDefaults(model: any, urlParams: any = {}): Observable<any> {
    const url = sprintf(`${this.baseUrl}/default`, urlParams);
    return this.http.post<any>(url, model);
  }
}
