import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { Event, EventProfile } from '../_models';
import { DatatablesUtils } from '../_utils/datatables';
import { BaseService } from './base.service';

export class Events {
  events: Event[];
  total: number;
}

@Injectable()
export class EventService extends BaseService {
  private _currentEvent: Event;

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events`);
  }

  listPage(urlParams: any, params: any, dtParams: any): Observable<Events> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.post<Events>(url, {
      ...apiParams,
      ...params,
    });
  }

  listPrimary(urlParams: any, params: any): Observable<any[]> {
    const url = sprintf(`${this.baseUrl}/primary`, urlParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams(params)
    });
  }

  listCalendar(apiParams: any): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/events/calendar`;
    return this.http.get<any>(url, {
      params: this.toHttpParams(apiParams)
    });
  }

  get(id: string, urlParams: any = {}, apiParams: any = {}): Observable<Event> {
    return super.get(id, urlParams, apiParams)
      .pipe(
        tap(event => this._currentEvent = new Event().deserialize(event)),
      );
  }

  getDetails(id: string, urlParams: any = {}, apiParams: any = {}): Observable<Event> {
    return super.get(`${id}/details`, urlParams, apiParams)
      .pipe(
        tap(event => {
          const fullEvent = { ...this._currentEvent, ...event };
          this._currentEvent = new Event().deserialize(fullEvent);
        }),
      );
  }

  getProfile(id: string, urlParams: any = {}, apiParams: any = {}): Observable<EventProfile> {
    return super.get(`${id}/profile`, urlParams, apiParams);
  }

  updateProfile(model: any, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${model.id}/profile`;
    return this.http.put<any>(url, model, {
      params: this.toHttpParams(apiParams),
    });
  }

  getOthersMayJoin(id: string, urlParams: any = {}): Observable<any> {
    return super.get(`${id}/others_may_join`, urlParams);
  }

  getAttendeeInformation(id: string, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    return super.get(`${id}/attendee-information`, urlParams, apiParams);
  }

  getSecondaryEvents(id: string, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${id}/secondary_events`;
    return this.http.get<any>(url, {
      params: this.toHttpParams(apiParams),
    });
  }

  updateAttendeeInformation(model: any, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${model.id}/attendee-information`;
    return this.http.put<any>(url, model, {
      params: this.toHttpParams(apiParams),
    });
  }

  refreshWorkflow(id: string, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    return super.get(`${id}/refresh_workflow`, urlParams, apiParams);
  }

  clearCurrentEvent() {
    this._currentEvent = null;
  }

  getCurrentEvent(): Event {
    return this._currentEvent;
  }

  replicate(args: any, urlParams: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${args.id}/replicate`;
    return this.http.post<any>(url, args);
  }

  requestChatRoom(eventId: any, urlParams: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${eventId}/chat`;
    return this.http.post<any>(url, eventId);
  }

  getOpenedInvite(urlParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl + `/%(event_id)s/invitations/read`, urlParams);
    return this.http.get<any>(url);
  }

  importEvents(urlParams: any, formData): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/import?salesforce_id=${urlParams['salesforce_id']}`;
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
