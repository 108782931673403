<div class="modal-header">
  <h3 class="modal-title">Edit {{title | titlize}}</h3>
  <button type="button" class="btn btn-danger float-end" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="allSelectable">
    <input type="checkbox" class="form-check-input" [(ngModel)]="selectedAll" (change)="selectAll()">
    <label class="form-label">&nbsp;&nbsp;All</label>
  </div>
  <div class="mb-2" *ngFor="let item of list; index as i">
    <input type="checkbox" class="form-check-input" (change)="selectItem(i, $event.target.checked)"
      [checked]="selected?.indexOf(item[this.bindValue]) != -1">
    <label class="form-label">&nbsp;&nbsp;{{item[this.bindLabel]}}</label>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary float-end" aria-label="Close" (click)="onSave()">
    Save
  </button>
</div>