<div class="col-12">
	<ol class="breadcrumb">
		<li *ngFor="let breadcrumb of breadcrumbs; let last = last" class="breadcrumb-item"
			[ngClass]="{'active': last}">
			<a *ngIf="!hasLink(breadcrumb)" href="javascript:void(0);"
				title="{{getBreadcrumbTitle(breadcrumb)}}">{{getBreadcrumbTitle(breadcrumb)}}</a>
			<a *ngIf="hasLink(breadcrumb)" [routerLink]="getCompanySalesforceUrl(getBreadcrumbLink(breadcrumb))"
				title="{{getBreadcrumbTitle(breadcrumb)}}">{{getBreadcrumbTitle(breadcrumb)}}</a>
		</li>
	</ol>
</div>