import { Component, Input, forwardRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-dropzone',
  templateUrl: './file-dropzone.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileDropzoneComponent),
      multi: true,
    },
  ],
})
export class FileDropzoneComponent implements ControlValueAccessor {
  accept: string;
  constructor(
    private elementRef: ElementRef,
  ) {
    this.accept = this.elementRef.nativeElement.getAttribute('accept');
  }

  _fileData: any;
  _file: File;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;

  public writeValue(value: any): void {
    this._fileData = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.addedFiles && event.addedFiles.length) {
      this._file = event.addedFiles[0];
      reader.readAsDataURL(this._file);
      reader.onload = () => {
        this._fileData = reader.result;
        this.onChange(this._fileData);
      };
    }
  }
}
