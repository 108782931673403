import { Base } from './_base.model';

export class EventNotification extends Base {
  id: number;
  event_id: string;
  user_id: string;
  subject: string;
  content: string;
  event_sub_status_id: string;
  event_status_id: string;
  created_at: Date;
}
