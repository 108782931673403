import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService, CompanyService } from '../_services';

import { getErrorMessage } from 'app/_utils/helpers';
import { BaseComponent } from 'app/_components/base.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ResetPasswordComponent extends BaseComponent implements OnInit {
  password: string;
  confirm_password: string;
  loading = false;
  success = false;
  error = '';

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    private route: ActivatedRoute,
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit() {

  }

  resetPassword() {
    if (this.password === this.confirm_password) {
      this.route.queryParams.subscribe(params => {
        this.loading = true;
        this.authenticationService.resetPassword(params.code, this.password)
          .subscribe(result => {
            this.loading = false;
            this.error = '';
            this.success = true;
          }, err => {
            this.loading = false;
            this.error = getErrorMessage(err);
          });
      });
    } else {
      this.error = "Passwords don't match";
    }
  }
}
