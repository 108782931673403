import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
  transform(
    value: string | Date,
    format: string = 'MMM D, YYYY',
    timezone: string = 'America/New_York'
  ): string {
    if (!value) {
      return 'N/A';
    }
    return moment(value).tz(timezone).format(format);
  }
}
