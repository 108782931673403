<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="btn btn-danger float-end" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="!newVendor">
    <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
      <div class="row">
        <div class="col-2">
          <label class="form-label" for="name">Name</label>
          <input type="text" formControlName="name" class="form-control">
        </div>
        <div class="col-3">
          <label class="form-label" for="type_id">Type</label>
          <select formControlName="type_id" class="form-control">
            <ng-template ngFor let-vendorType [ngForOf]="vendorTypes">
              <option [value]="vendorType.id">{{vendorType.label}}</option>
            </ng-template>
          </select>
        </div>
        <div class="col-2">
          <label class="form-label" for="city">City</label>
          <input type="text" formControlName="city" class="form-control">
        </div>
        <div class="col-2">
          <label class="form-label" for="state">State</label>
          <select formControlName="state" class="form-control">
            <ng-template ngFor let-state [ngForOf]="states">
              <option [value]="state.code" *ngIf="state.country_id === 'US'">{{state.name}}</option>
            </ng-template>
          </select>
        </div>
        <div class="col-3">
          <label class="form-label">&nbsp;</label><br />
          <button class="btn btn-primary">Search</button>
          <button type="button" class="btn btn-secondary" *ngIf="enableCreate" (click)="createNew()">Create New</button>
        </div>
      </div>
    </form>
    <br />
    <table class="table table-striped" *ngIf="vendors">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Type</th>
          <th>City</th>
          <th>State</th>
        </tr>
      </thead>
      <tbody *ngIf="vendors.length">
        <tr *ngFor="let vendor of vendors">
          <td>
            <input type="checkbox" *ngIf="multiSelectable" [checked]="isSelected(vendor)"
              (change)="toggleVendor(vendor)">
            <input type="radio" name="selected" *ngIf="!multiSelectable" (change)="toggleVendor(vendor)">
          </td>
          <td>{{vendor.name}}</td>
          <td>{{vendor.type?.label}}</td>
          <td>{{vendor.city}}</td>
          <td>{{vendor.state}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="vendors.length === 0">
        <tr>
          <td colspan="6" align="center">No matching records found</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="catereringByVenueCheckbox">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" [(ngModel)]="catereringByVenue" id="catereringByVenue">
        <label class="form-check-label" for="catereringByVenue">
          Catering Provided By Location
        </label>
      </div>
    </div>
    <div *ngIf="(vendors && vendors.length) || catereringByVenueCheckbox" class="text-center">
      <button type="button" class="btn btn-primary" (click)="submitSelected()"
        [disabled]="!catereringByVenue && !hasSelected()">
        Submit
      </button>
    </div>
  </div>
  <form #vendorForm="ngForm" (ngSubmit)="vendorForm.form.valid && onCreateSubmit()" *ngIf="newVendor">
    <app-vendor-edit-form #vendorEditForm [states]="states" [vendorTypes]="allVendorTypes" [vendorStatuses]="vendorStatuses"
      [vendor]="newVendor"></app-vendor-edit-form>
    <div class="text-center">
      <button type="submit" class="btn btn-primary" [disabled]="!vendorForm.form.valid || !vendorEditForm.formGroup.valid">Save</button>
      <button type="button" class="btn btn-secondary" (click)="backToSearch()">Back</button>
    </div>
  </form>
</div>