import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class BalanceTransactionService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/balance_transactions`);
  }
  
  getCompanyBalance(urlParams: any) {
    const url = sprintf(this.baseUrl + '/balance', urlParams);
    return this.http.get<any>(url);
  }
}