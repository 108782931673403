import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()

export class ChatService extends BaseService {
  constructor (
    protected http: HttpClient
  ) {
    super(http, `${environment.apiBaseUrl}/chats`);
  }
  join(id: string, urlParams?: any, params?: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${id}`;
    return this.http.put<any>(url, {}, {
      params: this.toHttpParams({
        ...params,
      })
    });
  }
  getUsersByRoomId(id: string, urlParams?: any, params?: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${id}`;
    return this.http.get<any>(url, {
      params: this.toHttpParams({
        ...params,
      })
    });
  }
  sendSupportMessage(urlParams: any = {}, data: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${this.baseUrl}/%(room_id)s/messages`, urlParams);
    return this.http.post<any>(url, data);
  }
}
