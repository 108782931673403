import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class CustomReportService extends BaseService {

  constructor(
    protected override http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/custom_reports`);
  }

  download(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${urlParams.salesforce_id}/${urlParams.id}/download`;
    return this.http.get<any>(url, apiParams);
  }

  downloadCodingReport(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${urlParams.id}/download`;
    return this.http.get<any>(url, apiParams);
  }
}
