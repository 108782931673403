import { Base } from './_base.model';

export class Vendor extends Base {
  id: number;
  agency_id: string;
  name: string;
  code: string;
  type_id: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
  contact_name: string;
  phone: string;
  web_address: string;
  tax_id: string;
  fax: string;
  email: string;
  archived: boolean;
  valet_parking: boolean;
  off_street_parking: boolean;
  franch_parent_id: string;
  status_id: string;
  vendor_company_status_id: string;
  vendor_agency_status_id: string;
  contact_phone: string;
  contact_email: string;
  contact_phone_after_hours: string;
  contacts: any[];
  rooms: any[];
  packages: any[];
  equipments: any[];
  childs: any[];
}
