import { AfterViewInit, OnInit, Component, Input } from "@angular/core";

declare var $: any;

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})

export class CountdownTimerComponent implements OnInit, AfterViewInit {
  @Input() startAt: string;
  hours: any;
  minutes: any;
  seconds: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.timer(this.startAt);
  }

  timer(endTime) {
    const currentDate = new Date();
    const targetTime = new Date(endTime);
    const difference = targetTime.getTime() - currentDate.getTime();
    this.seconds = Math.floor(difference / 1000);
    this.minutes = Math.floor(this.seconds / 60);
    this.hours = Math.floor(this.minutes / 60);
    this.hours %= 24;
    this.minutes %= 60;
    this.seconds %= 60;
    this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;
    this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
    this.hours = this.hours < 10 ? '0' + this.hours : this.hours;
    $('#hours').html(this.hours);
    $('#mins').html(this.minutes);
    $('#seconds').html(this.seconds);
    setInterval(this.timer, 1000, endTime);
  }
}