import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class EsifFormService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/esif_forms`);
  }

  getW9(id, meta, urlParams: any): Observable<Blob> {
    const url = sprintf(this.baseUrl, urlParams) + `/${id}/w9`;
    return this.http.put<Blob>(url, meta, {
      responseType: 'blob' as 'json'
    });
  }

  getPDF(id, values, urlParams: any): Observable<Blob> {
    const url = sprintf(this.baseUrl, urlParams) + `/${id}/pdf`;
    return this.http.put<Blob>(url, values, {
      responseType: 'blob' as 'json'
    });
  }

  setDefault(id, urlParams: any, apiParams: any = {}): Observable<Blob> {
    const url = sprintf(this.baseUrl, urlParams) + `/default`;
    return this.http.post<any>(url, { id }, {
      params: this.toHttpParams(apiParams),
    });
  }
}
