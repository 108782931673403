import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  // HashLocationStrategy,
  LocationStrategy,
  DatePipe,
  DecimalPipe,
  CurrencyPipe,
  PercentPipe,
  PathLocationStrategy,
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { ToastrModule } from 'ngx-toastr';
import { UserIdleModule } from 'angular-user-idle';
import { SocketIoModule } from 'ngx-socket-io';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from '@abacritt/angularx-social-login';

import { environment } from 'environments/environment';

// Auth
import { AuthGuard } from './_guards/auth.guard';
import { PendingChangesGuard } from './_guards/pending-changes.guard';
import { AuthInterceptor } from './_interceptors/auth.interceptor';

// Services
import {
  AuthenticationService,
  ProfileService,
  CompanyService,
  AgencyService,
  BudgetService,
  CompanyBudgetService,
  SalesforceService,
  SalesforceBudgetService,
  TopicService,
  EventService,
  DropdownService,
  CompanyDropdownSettingService,
  LanguageService,
  MeetingTypeService,
  QuestionService,
  EvaluationService,
  ChecklistService,
  WorkflowService,
  SpeakerTaskService,
  SalesforceTaskService,
  RequestFormService,
  RequestFormDefaultValueService,
  PreRequestFormService,
  SalesforceMeetingTypeService,
  ApprovalGroupService,
  StaffService,
  ClientService,
  SpeakerService,
  AreaService,
  AreaBudgetService,
  VendorService,
  RequestService,
  SettingService,
  FiscalYearService,
  DefaultBudgetService,
  ContactService,
  ContactNoteService,
  FileService,
  GlobalFileService,
  CallPlanService,
  CallPlanContactService,
  AttendeeService,
  HcpService,
  EventStatusService,
  AreaTypeClientLevelService,
  MeetingTypeClientLevelService,
  EventStatusChangeService,
  EventBudgetService,
  EventBudgetStatusChangeService,
  EventRescheduleService,
  EventVenueService,
  EventCatererService,
  EventChatMessageService,
  EventAvCompanyService,
  EventTrainingService,
  EventTrainingGroupService,
  CompanyProfileService,
  EquipmentService,
  SpeakerAgreementService,
  SpeakerValidationService,
  SpeakerValidationNoteService,
  SpeakerValidationFileService,
  EventSpeakerService,
  EventPaymentService,
  PaymentService,
  EventNearbyService,
  BudgetTransferService,
  BalanceTransactionService,
  EventCardService,
  DownloadFormService,
  EventDownloadFormService,
  ReportService,
  CustomReportService,
  EventCreditCardService,
  ActionService,
  NotificationService,
  TriggerService,
  UploadFormService,
  AvEquipmentService,
  VendorAvEquipmentService,
  CompanyAvEquipmentService,
  SalesforceAvEquipmentService,
  SalesforceSummaryService,
  EventEquipmentService,
  EventNoteService,
  EventNotificationService,
  EventFileService,
  EventChecklistService,
  EventEvaluationService,
  EventClientAnswerService,
  EventSpeakerAnswerService,
  AttendeeAnswerService,
  CallPlanCustomFieldService,
  ContactCustomFieldService,
  SpeakerCustomFieldService,
  AttendeeCustomFieldService,
  AttendeeRegistrationAnswerService,
  EventCollaborationService,
  ProductService,
  VendorRoomService,
  WorkflowTemplateService,
  DownloadFormGlobalService,
  ContactSubmissionService,
  EsifFormService,
  SpeakerRateColService,
  SpeakerRateRowService,
  SpeakerRateDefaultService,
  SpeakerLevelService,
  EmailTemplateService,
  StaticContentService,
  ContactFieldService,
  SpeakerEsifFormService,
  ForumService,
  StaffChecklistService,
  SupportContactService,
  AlertService,
  UsageService,
  UserUsageTrackService,
  UploadFormClientLevelService,
  ClientLevelService,
  SalesforceFiscalYearService,
  ChartService,
  EventSpeakerPaymentService,
  CoreEventFieldService,
  CoreEventFormService,
  CoreEventService,
  UserService,
  UserNotificationService,
  UserChecklistService,
  PresentationService,
  PresentationCustomService,
  PresentationQrcodeService,
  PresentationAnswerService,
  ContactAddressService,
  ContactLicenseService,
  ContactAssistantService,
  HonorariaRateColService,
  HonorariaRateRowService,
  HonorariaRateDefaultService,
  CompanySalesforceAgencyService,
  CompanySalesforceTravelAgentService,
  AreaTypeService,
  EventSpeakerTravelService,
  EventSpeakerStayService,
  EventViewRequestService,
  SpeakerAgreementFieldService,
  HourlyRateService,
  UserAlertService,
  EventPresentationService,
  EventClientSignService,
  EventSpeakerSignService,
  EventInquiryService,
  EventWebcastService,
  EventWebcastGroupService,
  SocketService,
  InvitationService,
  EventInvitationService,
  UniverseService,
  VendorAgencyService,
  EventTokenService,
  AttendeeRegistrationFieldsService,
  S3Service,
  HcpValidationSourceService,
  WorkflowBudgetService,
  ClientEventViewService,
  ClientExpenseViewService,
  ClientFacultyViewService,
  ClientReportViewService,
  TrainingModuleService,
  EventSpeakerCarService,
  GlobalTriggerService,
  GlobalEmailTemplateService,
  GlobalNotificationService,
  CompanyUploadFormService,
  ChatService,
  Auth0Service,
  UploadFormMeetingTypeService,
  AttendeeRestrictionService,
  JobInstanceService,
  JobTypeService,
  EventTourService,
  CommonReportService,
  RecommendedForService,
  QuestionTooltipService,
  CountryService,
  CountryStateService,
  PublicTaskService,
  MeetingService
} from './_services';

// Components
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from 'app/_components/shared.module';

import { TitlizePipe } from './_pipes/titlize.pipe';

import {
  SiteLayoutComponent,
  SiteHeaderComponent,
  SiteFooterComponent,
  LoginLayoutComponent,
} from './_layouts';

import { USER_IDLE_CONFIG } from './app.constants';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password.component';
import { NotFoundComponent } from './404/not-found.component';
import { StaticPageComponent } from './static/static-page.component';
import { UserIdleModalComponent } from './user-idle-modal.component';
import { RedirectComponent } from './redirect.component';
import { DummyComponent } from './dummy.component';
import { LogoutComponent } from './logout.component';

@NgModule({
  declarations: [
    AppComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    LoginLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserIdleModalComponent,
    RedirectComponent,
    NotFoundComponent,
    StaticPageComponent,
    DummyComponent,
    LogoutComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    AppRoutingModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    UserIdleModule.forRoot(USER_IDLE_CONFIG),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
    }),
    SocketIoModule.forRoot({
      url: environment.socketUrl,
      options: {
        autoConnect: false,
        transports: [ 'websocket' ],
      },
    }),
    SocialLoginModule,
  ],
  entryComponents: [ UserIdleModalComponent ],
  providers: [
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    PercentPipe,
    TitlizePipe,

    AuthenticationService,
    AuthGuard,
    PendingChangesGuard,
    ProfileService,
    CompanyService,
    AgencyService,
    BudgetService,
    CompanyBudgetService,
    SalesforceService,
    SalesforceBudgetService,
    TopicService,
    EventService,
    DropdownService,
    CompanyDropdownSettingService,
    LanguageService,
    MeetingTypeService,
    QuestionService,
    EvaluationService,
    ChecklistService,
    WorkflowService,
    SpeakerTaskService,
    SalesforceTaskService,
    RequestFormService,
    RequestFormDefaultValueService,
    PreRequestFormService,
    SalesforceMeetingTypeService,
    ApprovalGroupService,
    StaffService,
    ClientService,
    SpeakerService,
    AreaService,
    AreaBudgetService,
    VendorService,
    VendorAgencyService,
    RequestService,
    SettingService,
    FiscalYearService,
    DefaultBudgetService,
    ContactService,
    ContactNoteService,
    FileService,
    GlobalFileService,
    CallPlanService,
    CallPlanContactService,
    AttendeeService,
    HcpService,
    EventStatusService,
    AreaTypeClientLevelService,
    MeetingTypeClientLevelService,
    EventStatusChangeService,
    EventBudgetService,
    EventBudgetStatusChangeService,
    EventRescheduleService,
    EventVenueService,
    EventCatererService,
    EventChatMessageService,
    EventAvCompanyService,
    EventCreditCardService,
    EventTrainingService,
    EventTrainingGroupService,
    CompanyProfileService,
    EquipmentService,
    SpeakerAgreementService,
    SpeakerValidationService,
    SpeakerValidationNoteService,
    SpeakerValidationFileService,
    EventSpeakerService,
    EventPaymentService,
    PaymentService,
    EventNearbyService,
    BudgetTransferService,
    BalanceTransactionService,
    EventCardService,
    DownloadFormService,
    EventDownloadFormService,
    ReportService,
    CustomReportService,
    ActionService,
    NotificationService,
    TriggerService,
    UploadFormService,
    CompanyUploadFormService,
    AvEquipmentService,
    VendorAvEquipmentService,
    CompanyAvEquipmentService,
    SalesforceAvEquipmentService,
    SalesforceSummaryService,
    EventEquipmentService,
    EventNoteService,
    EventNotificationService,
    EventFileService,
    EventChecklistService,
    EventEvaluationService,
    EventClientAnswerService,
    EventSpeakerAnswerService,
    AttendeeAnswerService,
    CallPlanCustomFieldService,
    ContactCustomFieldService,
    SpeakerCustomFieldService,
    AttendeeCustomFieldService,
    AttendeeRegistrationAnswerService,
    EventCollaborationService,
    ProductService,
    VendorRoomService,
    WorkflowTemplateService,
    DownloadFormGlobalService,
    ContactSubmissionService,
    EsifFormService,
    SpeakerRateColService,
    SpeakerRateRowService,
    SpeakerRateDefaultService,
    HonorariaRateColService,
    HonorariaRateRowService,
    HonorariaRateDefaultService,
    SpeakerLevelService,
    EmailTemplateService,
    StaticContentService,
    ContactFieldService,
    SpeakerEsifFormService,
    ForumService,
    StaffChecklistService,
    SupportContactService,
    AlertService,
    UsageService,
    UserUsageTrackService,
    UploadFormClientLevelService,
    UploadFormMeetingTypeService,
    AttendeeRestrictionService,
    ClientLevelService,
    SalesforceFiscalYearService,
    CompanySalesforceAgencyService,
    CompanySalesforceTravelAgentService,
    ChartService,
    EventSpeakerPaymentService,
    CoreEventFieldService,
    CoreEventFormService,
    CoreEventService,
    UserService,
    UserNotificationService,
    UserChecklistService,
    PresentationService,
    PresentationCustomService,
    PresentationQrcodeService,
    PresentationAnswerService,
    ContactAddressService,
    ContactLicenseService,
    ContactAssistantService,
    AreaTypeService,
    EventSpeakerTravelService,
    EventSpeakerStayService,
    EventViewRequestService,
    SpeakerAgreementFieldService,
    HourlyRateService,
    UserAlertService,
    EventPresentationService,
    EventClientSignService,
    EventSpeakerSignService,
    EventInquiryService,
    EventWebcastService,
    EventWebcastGroupService,
    InvitationService,
    EventInvitationService,
    SocketService,
    UniverseService,
    EventTokenService,
    AttendeeRegistrationFieldsService,
    S3Service,
    HcpValidationSourceService,
    WorkflowBudgetService,
    ClientEventViewService,
    ClientExpenseViewService,
    ClientReportViewService,
    ClientFacultyViewService,
    TrainingModuleService,
    EventSpeakerCarService,
    GlobalEmailTemplateService,
    GlobalNotificationService,
    GlobalTriggerService,
    JobInstanceService,
    JobTypeService,
    CommonReportService,
    ChatService,
    Auth0Service,
    EventTourService,
    RecommendedForService,
    QuestionTooltipService,
    CountryService,
    CountryStateService,
    PublicTaskService,
    MeetingService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.GOOGLE_OAUTH2_CLIENT_ID
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FACEBOOK_APP_ID),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule { }
