import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Salesforce } from '../_models';
import { BaseService } from './base.service';

@Injectable()
export class SalesforceService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/salesforces`);
  }

  create(company_id: string, salesforce: Salesforce): Observable<any> {
    return super.create(salesforce, { company_id: company_id }).pipe(
      map(res => new Salesforce().deserialize(res))
    );
  }

  update(company_id: string, salesforce: any): Observable<any> {
    return super.update(salesforce, { company_id: company_id }).pipe(
      map(res => new Salesforce().deserialize(res))
    );
  }
}
