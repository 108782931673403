<div class="row" *ngIf="!dedicatedQuestionTypes.includes(question.type_id) && answers">
  <div class="col-3" *ngIf="question.text">
    <div [ngStyle]="{'padding-left':depth*20}">{{question.text}}</div>
  </div>
  <div class="col-3" *ngIf="answers[screenIndex][questionIndex]">
    <pre
      *ngIf="question.type_id === 'COMMENTS' || question.type_id === 'TEXT_AREA'">{{answers[screenIndex][questionIndex]}}</pre>
    <ng-template [ngIf]="question.type_id === 'MULTIPLE_CHECK'">
      <div *ngFor="let an of answers[screenIndex][questionIndex] | keyvalue">{{an.key}}</div>
    </ng-template>
    <span
      *ngIf="!(question.type_id === 'COMMENTS' || question.type_id === 'TEXT_AREA' || question.type_id === 'MULTIPLE_CHECK')">{{answers[screenIndex][questionIndex]}}</span>
  </div>
</div>
<ng-template ngFor let-subQ let-i="index" [ngForOf]="question.subQuestions">
  <app-request-question-preview [question]="subQ" [answers]="answers" [screenIndex]="screenIndex"
    [questionIndex]="questionIndex + '_sub_' + i" [dedicatedQuestionTypes]="dedicatedQuestionTypes" [depth]="depth+1">
  </app-request-question-preview>
</ng-template>