<section class="login-register row">
  <div class="login-sidebar col-md-5">
    <a href="javascript:void(0)" id="logo">
      <img src="/assets/img/login-logo.png" alt="{{COMPANY_NAME}}" />
    </a>
    <div id="text">
      <h1 class="mb-5">Our Mission</h1>
      <h1 class="mb-5">To improve healthcare<br>through communication</h1>
      <h3 class="description">
        Lorem ipsum dolor sit amet,<br>
        consectetur adipiscing elit, sed do<br>
        eiusmod tempor incididunt ut<br>
        labore et olore magna aliqua.
      </h3>
    </div>
    <footer>
      &copy; 2023 {{COMPANY_SITE}}
    </footer>
  </div>
  <div class="login-box col-md-7">
    <div id="navbar" class="text-end mb-5">
      <a href="javascript:void(0)">About Us</a>
      <a href="javascript:void(0)">How It Works</a>
      <a href="javascript:void(0)">Products</a>
      <a href="javascript:void(0)">Contact Us</a>
    </div>
    <router-outlet></router-outlet>
    <img id="login_graphic" src="/assets/img/login-graphic.png">
  </div>
</section>