import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { DatatablesUtils } from '../_utils/datatables';

@Injectable()
export class UsageService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/usages`);
  }

  getDetail(userId: string, params: any, dtParams: any): Observable<any> {
    const url = this.baseUrl + `/${userId}`;
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    });
  }
}
