import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
  ],
})
export class FileInputComponent implements ControlValueAccessor {
  @Input() options = Array<any>();

  _file: any;
  _filename: string | null;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;

  public writeValue(value: any): void {
    this._file = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this._filename = file.name;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this._file = file;
        this.onChange(this._file);
      };
    }
  }

  onFileRemove() {
    this._file = null;
    this._filename = null;
    this.onChange(this._file);
  }
}
