import { Component } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: '[appLoadingButton]', // eslint-disable-line
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent {
  loader = this.loadingBar.useRef();
  constructor(
    private loadingBar: LoadingBarService,
  ) {
  }
}
