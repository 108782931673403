import { Base } from './_base.model';

export class Product extends Base {
  id: number;
  code: string;
  name: string;
  description: string;
  image: string;
  wide_image: string;
  navbar_color: string;
  archived: boolean;
  isi: string;

  image_file: string | ArrayBuffer;
  wide_image_file: string | ArrayBuffer;
}
