<div class="modal-body">
  <h5 class="text-primary">
    <!-- Your login session has been idle for {{idle / 60}} minutes.
    Your session will automatically expire after <span class="badge">{{timeout - count}}</span> seconds.
    Please click the "Cancel" button below to continue your session. -->
    Are you still there?
    The platform will time out after {{(idle + timeout) / 60}} minutes of inactivity.
  </h5>
  <p>
    <button type="button" class="btn btn-primary" (click)="onCancel()">I'm still here</button>
  </p>
</div>