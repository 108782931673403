import moment from 'moment';
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as script from 'scriptjs';

import { LOGIN_GOTO_QUERY_PARAM } from 'app/app.constants';
import { environment } from 'environments/environment';

export const loadScript = function (url: string, callback: any = undefined) {
  script(url, callback);
};

export const loadAppleAuthScript = function (callback) {
  loadScript('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', callback);
};

export const loadGoogleMapScript = function (callback: any = undefined) {
  loadScript(`https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_MAP_API_KEY}`, callback);
};

export const getErrorMessage = function (err, message = '') {
  return err.error && err.error.error && err.error.error.message ? err.error.error.message : message;
};

export const getDetailedErrorMessage = function (err, message = '') {
  return err.error && err.error.error && err.error.error.errors ? err.error.error.errors[0].message : message;
};

export const getFieldErrorMessage = function (err, message = '') {
  if (err.error && err.error.error && err.error.error.fieldErrors) {
    const fieldErrors = Object.values(err.error.error.fieldErrors);
    if (fieldErrors.length) {
      return capitalizeFirstLetter(fieldErrors[0]);
    }
  }
  return message;
};

export const getCompanySalesforceUrl = function (url: string, companyName: string, salesforceName: string): string {
  if (url === '' || url === '/') {
    return [companyName, salesforceName].join('.') + '/dashboard/';
  }
  if (url.startsWith('/')) {
    url = url.substr(1);
  }
  const parts = url.split('/');
  parts[0] = [companyName, salesforceName].join('.') + '/' + parts[0];
  parts.unshift('');
  return parts.join('/');
};

export const getChecklistDueAt = function (checklist, event) {
  const dueWhen = checklist.due_when;
  const dueWhat = checklist.due_what;
  const dueDays = checklist.due_days || 0;
  if (event && dueWhen && dueWhat) {
    let what = null;
    if (dueWhat === 'request') {
      what = event.request ? event.request.approved_at : event.created_at;
    } else if (dueWhat === 'event' && event.common_event) {
      what = event.common_event.start_at;
    } else if (dueWhat && checklist[dueWhat]) {
      what = checklist[dueWhat].completed_at;
    }
    if (what) {
      if (dueWhen === 'before') {
        return moment(what).subtract(dueDays, 'days').toDate();
      }
      if (dueWhen === 'after') {
        return moment(what).add(dueDays, 'days').toDate();
      }
    }
  }
  return null;
};

export const buildFormData = function (data = {}) {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if (data[key] instanceof Date) {
      formData.append(key, data[key].toISOString());
    } else if (data[key] instanceof Object) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key] === false) {
      formData.append(key, data[key]);
    } else if (data[key] === '') {
      formData.append(key, data[key]);
    } else if (data[key]) {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

export const getGotoQueryParams = function (url: string) {
  const [url1, query] = url.split("?");
  if (query && query.toLowerCase().includes(LOGIN_GOTO_QUERY_PARAM.toLowerCase())) {
    url = url1;
  }
  const result = {};
  if (url !== '' && url !== '/' && url !== '/login') {
    result[LOGIN_GOTO_QUERY_PARAM] = url;
  }
  return result;
}

export const mapUploadProgress = map((event: HttpEvent<any>): HttpProgressEvent | HttpResponse<any> | undefined => {
  switch (event.type) {
    case HttpEventType.UploadProgress:
      return event;
    case HttpEventType.Response:
      return event;
  }
  return undefined;
});

export const getColumnIndexes = function (length: number, columns: number): number[] {
  const ret: number[] = [];
  const n = Math.floor(length / columns);
  for (let i = 0; i < columns; i++) {
    const prev = i === 0 ? 0 : ret[i - 1];
    if (i < length % columns) {
      ret.push(prev + n + 1);
    } else {
      ret.push(prev + n);
    }
  }
  return ret;
};

export const getPastMonthFirstDate = function () {
  return new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
};

export const getLastSixFirstDate = function () {
  return new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1);
};

export const getLast12MonthFirstDate = function () {
  return new Date(new Date().getFullYear(), new Date().getMonth() - 12, 1);
};

export const getCurrentYearFirstDate = function () {
  return new Date(new Date().getFullYear(), 0, 1);
};

export const getCurrentYearTodaysDate = function () {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 0);
};

export const getCurrentYearLastDay = function () {
  return new Date(new Date().getFullYear(), 12, 0);
};

export const getLastYearFirstDay = function () {
  return new Date(new Date().getFullYear() - 1, 0, 1);
};

export const getLastYearLastDay = function () {
  return new Date(new Date().getFullYear() - 1, 12, 0);
};

export const getDateFilterMenuItems = function () {
  return [
    { label: 'Past Month', start_date: getPastMonthFirstDate(), end_date: getCurrentYearTodaysDate() },
    { label: 'Past 6 Month', start_date: getLastSixFirstDate(), end_date: getCurrentYearTodaysDate() },
    { label: 'Past 12 Month', start_date: getLast12MonthFirstDate(), end_date: getCurrentYearTodaysDate() },
    { label: 'Current Year', start_date: getCurrentYearFirstDate(), end_date: getCurrentYearLastDay() },
    { label: 'Prior Year', start_date: getLastYearFirstDay(), end_date: getLastYearLastDay() },
  ];
};

export const getCurrentQuarter = function (startMonth) {
  const currentMonth = new Date().getMonth();
  if (currentMonth >= startMonth) {
    return Math.floor((currentMonth - startMonth) / 3) + 1;
  } else {
    return Math.floor((currentMonth - startMonth + 12) / 3) + 1;
  }
};

export const capitalizeFirstLetter = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};