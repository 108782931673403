import { Component, AfterViewInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})

export class NotFoundComponent implements AfterViewInit {
  ngAfterViewInit() {
    $("html").css('height', '100%');
    $("body").css('height', '100%');
  }
}
