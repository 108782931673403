import { Base } from './_base.model';

export class TriggerCondition extends Base {
  id: number;
  checklists: any[] = [];
  reverse_checklists: any[] = [];
  condition_meeting_types: any[] = [];
  upload_forms: any[] = [];
  event_statuses: any[] = [];
  all_meeting_types: boolean;
}
