import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HttpEventType } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as XLSX from "xlsx";
import { getErrorMessage } from 'app/_utils/helpers';

import {
  AuthenticationService,
  CompanyService,
  AttendeeService,
} from 'app/_services';
import { COMPANY_STEPS, COMPANY_STEP_LINKS } from 'app/app.constants';
@Component({
  selector: 'app-attedee-import-modal',
  templateUrl: './attendee-import-modal.component.html',
  styleUrls: ['./attendee-import-modal.component.scss']
})
export class AttendeeImportModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  COMPANY_STEP_LINK = COMPANY_STEP_LINKS.filter((s) => s.step === COMPANY_STEPS.SPEAKER)[0];
  companyId: string;
  eventId: string;
  uploadForm: FormGroup;
  @ViewChild('xlsxFileInput') xlsxFileInput;
  errText: string = '';
  xlsxData: any[] = [];
  importedAttendees: any[] = [];
  importedResult: any[] = [];
  npiErrorItems: any[] = [];
  licenseErrorItems: any[] = [];
  bImport: Boolean = false;
  step: number = 0;

  hostConfrimed: boolean = false;
  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private attendeeService: AttendeeService,
  ) {
    this.uploadForm = this.fb.group({
      xlsx_file: ['', Validators.required],
    });
  }
  ngOnInit() {

  }
  importData() {
    this.attendeeService.validateAttendees({
      company_id: this.companyId,
      event_id: this.eventId,
    }, this.xlsxData).subscribe((res) => {
      this.uploadForm.reset();
      this.importedAttendees = res;
      this.step = 1;
    }, (err) => {
      this.toastr.error(getErrorMessage(err));
    });
  }
  onComplete() {
    this.attendeeService.importAttendees({
      company_id: this.companyId,
      event_id: this.eventId,
    }, {
      attendees: this.importedAttendees,
      hostConfirmed: this.hostConfrimed
    }).subscribe((res) => {
      this.importedResult = res;
      this.step = 2;
      this.event.emit();
    }, (err) => {
      this.toastr.error(getErrorMessage(err));
    });
  }
  onFileChange(evt: any) {
    this.xlsxData = [];
    this.npiErrorItems = [];
    this.licenseErrorItems = [];
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length > 1) {
      alert('Multiple files are not allowed');
      return;
    }
    else {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        let obj = [];
        obj = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
        // Print the Excel Data
        const [headers, ...data] = obj;
        this.xlsxData = data.map(row => Object.fromEntries(headers.map((header, i) => [header, row[i]])));
        if (this.xlsxData) {
          this.xlsxData.forEach((item) => {
            if (this.checkDuplicates(item, 'NPI')) {
              this.npiErrorItems.push(item);
            }
            if (this.checkDuplicates(item, 'License State') && this.checkDuplicates(item, 'License #')) {
              this.licenseErrorItems.push(item);
            }
          })
          if (!this.npiErrorItems.length && !this.licenseErrorItems.length) {
            this.errText = '';
            this.bImport = true;
          } else {
            this.errText = `The following data appears more than once:
                             NPI: ${this.npiErrorItems.length},
                             License : ${this.licenseErrorItems.length} `;
            this.bImport = false;
          }
        } else {
          alert('no data to display!');
        }
      }
      reader.readAsBinaryString(target.files[0]);
    }
  }
  onClick(evt: any) {
    evt.target.value = null;
  }
  checkDuplicates(item: any, field: string): boolean {
    const value = item[field];
    const duplicates = this.xlsxData.filter(x => x[field] === value);
    return duplicates.length > 1;
  }
}
