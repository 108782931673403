import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class ApprovalGroupService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/request_forms/%(request_form_id)s/approval_groups`);
  }
}
