<div class="row mt-2 drag-box">
  <div class="col-2">
    <div style="padding-left: {{20*depth}}px">{{question.type_id}}</div>
  </div>
  <div class="col-2">
    <div>{{question.sub_type}}</div>
    <div>{{getScreen()?.name}}</div>
  </div>
  <div class="col-5">
    <textarea name="question-text" class="form-control" [(ngModel)]="question.text"></textarea>
    <div *ngIf="question.type_id && question.type_id.includes('MULTI') && question.choices">
      <b>Choices:</b>
      <ng-template ngFor let-value [ngForOf]='question.choices.split("\n")'>
        <div>{{value}}</div>
      </ng-template>
    </div>
  </div>
  <div class="col-2">
    <div *ngIf="question.type_id === 'event_start_at' || question.type_id === 'venue'">
      <div class="mb-1">
        <label class="form-label me-2">Options</label>
        <select class="form-control w-auto d-inline-block" name="question_max" [(ngModel)]="question.max">
          <option [value]="v" *ngFor="let v of [1,2,3]">{{v}}</option>
        </select>
      </div>
      <div class="mb-1">
        <label class="form-label me-2">Required</label>
        <select class="form-control w-auto d-inline-block" name="question_min" [(ngModel)]="question.min">
          <option [value]="v" *ngFor="let v of [1,2,3]">{{v}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="question.type_id === 'attendees'">
      <label class="form-label">Required at Submission</label>
      <input type="number" name="question_min" [(ngModel)]="question.min" class="form-control" />
    </div>
    <div *ngIf="question.type_id === 'speakers'">
      <div class="mb-1">
        <label class="form-label me-2">Options</label>
        <select class="form-control w-auto d-inline-block" name="question_max" [(ngModel)]="question.max">
          <option [value]="v" *ngFor="let v of [1,2,3,4,5]">{{v}}</option>
        </select>
      </div>
      <div class="mb-1">
        <label class="form-label me-2">Required</label>
        <select class="form-control w-auto d-inline-block" name="question_min" [(ngModel)]="question.min">
          <option [value]="v" *ngFor="let v of [0,1,2,3,4,5]">{{v}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="question.type_id === 'speaker_count'">
      <select class="form-control" name="question_max" [(ngModel)]="question.max">
        <option [value]="v" *ngFor="let v of [1,2,3,4,5,6,7,8,9,10]">{{v}}</option>
      </select>
    </div>
    <div class="form-check" *ngIf="question.type_id === 'YN'">
      <input type="checkbox" class="form-check-input" name="question_na" [(ngModel)]="question.na">
      <label class="form-check-label" for="question_na">N/A</label>
    </div>
    <div class="form-check" *ngIf="needOptionalCheckbox(question)">
      <input type="checkbox" class="form-check-input" name="question_optional" [(ngModel)]="question.optional">
      <label class="form-check-label" for="question_optional">Optional</label>
    </div>
  </div>
  <div class="col-1">
    <div class="drag-handle" cdkDragHandle>
      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
        <path
          d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
        </path>
        <path d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    </div>
    <button type="button" class="btn btn-danger" (click)="Delete()">
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>
<div *ngIf="question.sub_type=='IFYESTHEN' || question.sub_type == 'IFNOTHEN'" cdkDropList
  (cdkDropListDropped)="drop($event)">
  <ng-template ngFor let-subq let-i="index" [ngForOf]='question.subQuestions'>
    <app-request-question-row-edit [priority]="priority" [questions]="question.subQuestions" [question]="subq" [questionTypes]="questionTypes"
      [dedicatedQuestionTypes]="dedicatedQuestionTypes" [depth]="depth+1" [nextScreens]="nextScreens"
      (delete)="onDeleteQ(i)" cdkDrag [cdkDragData]="subq"></app-request-question-row-edit>
  </ng-template>
  <app-request-question-row-create [priority]="priority" [questions]="question.subQuestions" [newQuestion]="newQuestion"
    [questionTypes]="questionTypes" [dedicatedQuestionTypes]="dedicatedQuestionTypes" [nextScreens]="nextScreens"
    [depth]="depth+1"></app-request-question-row-create>
</div>