import { Base } from './_base.model';

export class CoreEventField extends Base {
  id: string;
  label: string;
  type: string;
  list_name: string;
  section: number;
  rank: number;
  active: boolean;
  required: boolean;
  multiple: boolean;
  visible_in_list: boolean;
  is_half: boolean;

  constructor() {
    super();
  }

  static getSpaceField(): CoreEventField {
    const spaceField: CoreEventField = new CoreEventField();
    spaceField.deserialize({
      id: 'space',
      label: 'Space',
      type: 'text',
      list_name: null,
      section: null,
      rank: null,
      active: true,
      required: false,
      multiple: false,
      visible_in_list: true,
      is_half: false
    });
    return spaceField;
  }

  static getStaticFields(includeSpace = true): CoreEventField[] {
    const nameField: CoreEventField = new CoreEventField();
    nameField.deserialize({
      id: 'name',
      label: 'Name',
      type: 'text',
      list_name: null,
      section: null,
      rank: 1,
      active: true,
      required: true,
      multiple: false,
      visible_in_list: true,
      is_half: false
    });
    const startAtField: CoreEventField = new CoreEventField();
    startAtField.deserialize({
      id: 'start_at',
      label: 'Start Date',
      type: 'date',
      list_name: null,
      section: null,
      rank: 2,
      active: true,
      required: true,
      multiple: false,
      visible_in_list: true,
      is_half: false
    });
    const endAtField: CoreEventField = new CoreEventField();
    endAtField.deserialize({
      id: 'end_at',
      label: 'End Date',
      type: 'date',
      list_name: null,
      section: null,
      rank: 3,
      active: true,
      required: true,
      multiple: false,
      visible_in_list: true,
      is_half: false
    });
    const ret = [nameField, startAtField, endAtField];
    if (includeSpace) {
      ret.push(CoreEventField.getSpaceField());
    }
    return ret;
  }
}