import { Component, OnInit, AfterContentInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import * as am4core from "@amcharts/amcharts4/core";
import { XYChart, PieChart, CategoryAxis, ValueAxis, ColumnSeries, PieSeries } from "@amcharts/amcharts4/charts";

import { BaseComponent } from "app/_components/base.component";
import { AuthenticationService, CompanyService, PresentationAnswerService } from "app/_services";

declare var $: any;

@Component({
  selector: 'app-question-answers',
  templateUrl: './question-answers.component.html',
})
export class QuestionAnswersComponent extends BaseComponent implements OnInit, AfterContentInit {
  @Input() question: any;
  @Input() eventId: any;
  choices: string[] = [];
  answers: any;

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    private presentationAnswerService: PresentationAnswerService
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit() {
    this.choices = this.question.choices ? this.question.choices.split("\n") : [];

    if (this.question.type_id == "RATING" && !this.question.choices) {
      this.choices = ["1", "2", "3", "4", "5"];
    }

    if (this.question.type_id == "TF" && !this.question.choices) {
      this.choices = ["True", "False"];
    }

    if (this.question.type_id == "YN" && !this.question.choices) {
      this.choices = ["Yes", "No"];
    }

    this.presentationAnswerService.get(this.question.id, {
      company_id: this.getCurrentCompanyId(),
      event_id: this.eventId,
    }).subscribe(answers => {
      this.answers = answers;
      this.drawCharts();
    });
  }

  ngAfterContentInit() {

  }

  drawCharts() {
    am4core.addLicense("ch-custom-attribution");
    
    var chartData = this.choices.map(choice => ({ choice, count: this.answers[choice] ?? 0 }));

    switch (this.question.scope) {
      case 'bar':
        var barChart = am4core.create("question-answers-chart", XYChart);
        barChart.data = chartData;

        // Create axes
        var categoryAxis = barChart.xAxes.push(new CategoryAxis());
        categoryAxis.dataFields.category = "choice";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        // categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
        //   if (target.dataItem && target.dataItem.index & 2 == 2) {
        //     return dy + 25;
        //   }
        //   return dy;
        // });

        var valueAxis = barChart.yAxes.push(new ValueAxis());

        // Create series
        var series = barChart.series.push(new ColumnSeries());
        series.dataFields.valueY = "count";
        series.dataFields.categoryX = "choice";
        series.name = "Count";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        break;
      case 'donut':
      case 'pie':
      case 'pipe':
        var pieChart = am4core.create("question-answers-chart", PieChart);
        pieChart.data = chartData;
        
        // Add and configure Series
        var pieSeries = pieChart.series.push(new PieSeries());
        pieSeries.dataFields.value = "count";
        pieSeries.dataFields.category = "choice";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        pieChart.hiddenState.properties.radius = am4core.percent(0);
        break;
    }
  }
}
