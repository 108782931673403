import { Component, Input, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PopoverDirective } from "ngx-bootstrap/popover";

import { BaseComponent } from "app/_components/base.component";
import { AuthenticationService, CompanyService, VendorService, DropdownService } from "app/_services";
import { Vendor } from 'app/_models';

@Component({
  selector: 'app-vendor-popover',
  templateUrl: './vendor-popover.component.html',
})
export class VendorPopoverComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() vendorId: string;
  @Input() link: string;
  @ViewChild('pop') pop: PopoverDirective;
  vendor: Vendor;
  vendorTypes: any[];
  vendorStatuses: any[];
  popoverLinkId: string;
  popoverContainerId: string;

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    private vendorService: VendorService,
    private dropdownService: DropdownService,
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit(): void {
    this.popoverLinkId = `popover-link-${this.vendorId}`;
    this.popoverContainerId = `popover-container-${this.vendorId}`;
    this.dropdownService.getDropdowns('VendorType,VendorStatus').subscribe((lists) => {
      this.vendorTypes = lists.VendorType;
      this.vendorStatuses = lists.VendorStatus;
    });
  }

  ngAfterViewInit() {
    $(document).on('click', (e: any) => {
      if (this.pop.isOpen) {
        const container = $(`#${this.popoverLinkId},#${this.popoverContainerId}`);
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          this.pop.hide();
        }
      }
    });
  }

  onClick() {
    this.vendorService.get(this.vendorId, { company_id: this.getCurrentCompanyId() }).subscribe((vendor) => {
      this.vendor = vendor;
    });
    this.pop.toggle();
  }
}