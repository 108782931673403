import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DataTablesModule } from 'angular-datatables';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

// Ngx Modules
import { RatingModule } from 'ngx-bootstrap/rating';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatCheckboxModule } from '@angular/material/checkbox';

// Pipes
import { MomentDatePipe } from '../_pipes/moment-date.pipe';
import { MomentDurationPipe } from '../_pipes/moment-duration.pipe';
import { TitlizePipe } from 'app/_pipes/titlize.pipe';
import { FullNamePipe } from 'app/_pipes/full-name.pipe';
import { InitialNamePipe } from 'app/_pipes/initial-name.pipe';
import { CommaListPipe } from 'app/_pipes/comma-list.pipe';
import { FullNameDegreesPipe } from 'app/_pipes/full-name-degrees.pipe';
import { AreaFullNamePipe } from 'app/_pipes/area-full-name.pipe';
import { FullLastNamePipe } from 'app/_pipes/full-last-name.pipe';
import { NameCharsPipe } from 'app/_pipes/name-chars.pipe';
import { AddressLinesPipe } from 'app/_pipes/address-line.pipe';
import { FirstMiddleNamePipe } from 'app/_pipes/first-middle-name.pipe';
import { CreditCardNumberPipe } from 'app/_pipes/credit-card-number.pipe';

// Validators
import { MultiEmailsValidatorDirective } from 'app/_validators/multi-emails-validator.directive';
import { OneCheckedValidatorDirective } from 'app/_validators/one-checked-validator.directive';
import { DateGreaterThanValidatorDirective } from 'app/_validators/date-greater-than.directive';

// Common Controls
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { QuestionComponent } from './question/question.component';
import { SignatureFieldComponent } from './signature-field/signature-field.component';
import { CollapablePanelComponent } from './collapable-panel/collapable-panel.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { FileInputComponent } from './file-input/file-input.component';
import { FileDropzoneComponent } from './file-dropzone/file-dropzone.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { FileIconComponent } from './file-icon/file-icon.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { S3UploaderComponent } from './s3-uploader/s3-uploader.component';
import { PhotoSelectorComponent } from './photo-selector/photo-selector.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { NPIInputComponent } from './npi-input/npi-input.component';

// Common Components
import { BaseComponent } from './base.component';
import { EsifFormControlComponent } from './esif-form-control/esif-form-control.component';
import { CoreEventFieldComponent } from './core-event-field/core-event-field.component';
import { RequestFormComponent } from './request-form/request-form.component';
import { RequestQuestionComponent } from './request-question/request-question.component';
import { RequestQuestionRowCreateComponent } from './request-row/request-question-row-create.component';
import { RequestQuestionRowEditComponent } from './request-row/request-question-row-edit.component';
import { RequestQuestionPreviewComponent } from './request-question-preview/request-question-preview.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { VendorEditFormComponent } from './vendor-edit-form/vendor-edit-form.component';
import { VendorSearchModalComponent } from './vendor-search-modal/vendor-search-modal.component';
import { VendorEditModalComponent } from './vendor-edit-modal/vendor-edit-modal.component';
import { VendorPopoverComponent } from './vendor-popover/vendor-popover.component';
import { PersonPopoverComponent } from './person-popover/person-popover.component';
import { ShadowSettingsComponent } from './shadow-settings/shadow-settings.component';
import { QuestionTooltipComponent } from './question-tooltip/question-tooltip.component';

// Modals
import { MultiSelectModalComponent } from './multi-select-modal/multi-select-modal.component';
import { CallPlanSearchModalComponent } from './call_plan-search-modal/call_plan-search-modal.component';
import { CallPlanNewModalComponent } from './call_plan-search-modal/call_plan-new-modal.component';
import { AttendeeImportModalComponent } from './attendee-import/attendee-import-modal.component';
import { LoadingModalComponent } from './loading-modal/loading-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    DragDropModule,
    AngularSignaturePadModule,
    NgSelectModule,
    RatingModule.forRoot(),
    TooltipModule.forRoot(),
    DataTablesModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    ProgressbarModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    ButtonsModule.forRoot(),
    SortableModule.forRoot(),
    PopoverModule.forRoot(),
    NgxDropzoneModule,
    LoadingBarModule,
    ImageCropperModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    // MatCheckboxModule,
  ],
  declarations: [
    // Pipes
    MomentDatePipe,
    MomentDurationPipe,
    TitlizePipe,
    FullNamePipe,
    CommaListPipe,
    FullNameDegreesPipe,
    AreaFullNamePipe,
    FullLastNamePipe,
    InitialNamePipe,
    NameCharsPipe,
    AddressLinesPipe,
    FirstMiddleNamePipe,
    CreditCardNumberPipe,
    // Validators
    MultiEmailsValidatorDirective,
    OneCheckedValidatorDirective,
    DateGreaterThanValidatorDirective,
    // Common Controls
    BreadcrumbComponent,
    QuestionComponent,
    SignatureFieldComponent,
    CollapablePanelComponent,
    CheckboxGroupComponent,
    FileInputComponent,
    FileDropzoneComponent,
    ColorPickerComponent,
    FileIconComponent,
    LoadingButtonComponent,
    ConfirmationModalComponent,
    S3UploaderComponent,
    PhotoSelectorComponent,
    PhoneInputComponent,
    NPIInputComponent,
    // Common Components
    BaseComponent,
    EsifFormControlComponent,
    CoreEventFieldComponent,
    RequestFormComponent,
    RequestQuestionComponent,
    RequestQuestionRowCreateComponent,
    RequestQuestionRowEditComponent,
    RequestQuestionPreviewComponent,
    CountdownTimerComponent,
    VendorEditFormComponent,
    VendorSearchModalComponent,
    VendorEditModalComponent,
    VendorPopoverComponent,
    PersonPopoverComponent,
    ShadowSettingsComponent,
    QuestionTooltipComponent,
    // Modals
    CallPlanSearchModalComponent,
    CallPlanNewModalComponent,
    AttendeeImportModalComponent,
    MultiSelectModalComponent,
    LoadingModalComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    DragDropModule,
    TimepickerModule,
    AngularSignaturePadModule,
    NgSelectModule,
    RatingModule,
    TooltipModule,
    DataTablesModule,
    BsDatepickerModule,
    BsDropdownModule,
    AccordionModule,
    TabsModule,
    ProgressbarModule,
    TypeaheadModule,
    ButtonsModule,
    SortableModule,
    PopoverModule,
    NgxDropzoneModule,
    LoadingBarModule,
    ImageCropperModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    // MatCheckboxModule,
    // Pipes
    MomentDatePipe,
    MomentDurationPipe,
    TitlizePipe,
    FullNamePipe,
    CommaListPipe,
    FullNameDegreesPipe,
    InitialNamePipe,
    AreaFullNamePipe,
    FullLastNamePipe,
    NameCharsPipe,
    AddressLinesPipe,
    FirstMiddleNamePipe,
    CreditCardNumberPipe,
    // Validators,
    MultiEmailsValidatorDirective,
    OneCheckedValidatorDirective,
    DateGreaterThanValidatorDirective,
    // Common Controls
    BreadcrumbComponent,
    QuestionComponent,
    SignatureFieldComponent,
    CollapablePanelComponent,
    CheckboxGroupComponent,
    FileInputComponent,
    FileDropzoneComponent,
    ColorPickerComponent,
    FileIconComponent,
    LoadingButtonComponent,
    ConfirmationModalComponent,
    S3UploaderComponent,
    PhotoSelectorComponent,
    PhoneInputComponent,
    NPIInputComponent,
    // Common Components
    BaseComponent,
    EsifFormControlComponent,
    CoreEventFieldComponent,
    RequestFormComponent,
    RequestQuestionComponent,
    RequestQuestionRowCreateComponent,
    RequestQuestionRowEditComponent,
    RequestQuestionPreviewComponent,
    CountdownTimerComponent,
    VendorEditFormComponent,
    VendorSearchModalComponent,
    VendorEditModalComponent,
    VendorPopoverComponent,
    PersonPopoverComponent,
    ShadowSettingsComponent,
    LoadingModalComponent,
    QuestionTooltipComponent,
    // Modals
    CallPlanSearchModalComponent,
    CallPlanNewModalComponent,
    AttendeeImportModalComponent,
    MultiSelectModalComponent,
  ],
  providers: [
    BsDatepickerConfig,
  ]
})

export class SharedModule {
  constructor(private bsDatepickerConfig: BsDatepickerConfig) {
    this.bsDatepickerConfig.showWeekNumbers = false;
  }
}

