import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class CountryStateService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/states`);
  }

  getStates(countryId: string) {
    const url = `${environment.apiBaseUrl}/states`;
    return this.http.get<any>(url, {
      params: this.toHttpParams({ country_id: countryId })
    });
  }
}
