<div class="modal-header">
  <h4 class="modal-title">Edit Venue</h4>
  <button type="button" class="btn btn-danger float-end" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form #venueForm="ngForm" (ngSubmit)="venueForm.form.valid && onSubmit()">
    <app-vendor-edit-form #vendorEditForm [states]="states" [vendor]="vendor"></app-vendor-edit-form>
    <div class="form-group text-end">
      <button class="btn btn-primary" [disabled]="!venueForm.form.valid || !vendorEditForm.formGroup.valid">Save</button>
      <button type="button" class="btn btn-light" (click)="bsModalRef.hide()">Cancel</button>
    </div>
  </form>
</div>