<form #resetPasswordForm="ngForm" (ngSubmit)="resetPasswordForm.form.valid && resetPassword()">
  <h1>Reset Password</h1>
  <div>
    <div id="orange_separator"></div>
  </div>
  <p *ngIf="success" class="alert alert-success m-t-40">
    Successfully updated.<br>
    <a href="/login">Click here to login.</a>
  </p>
  <div *ngIf="!success">
    <div *ngIf="error" class="alert alert-danger m-t-40">{{error}}</div>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <input type="password" placeholder="Password" [(ngModel)]="password" class="form-control" name="password"
            required />
        </div>
        <div class="form-group">
          <input type="password" placeholder="Confirm Password" [(ngModel)]="confirm_password" class="form-control"
            name="confirm_password" required />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <button class="btn btn-info m-l-0" [disabled]="loading">Update</button>
        </div>
        <p class="text-end m-t-10">
          <a href="/login">Back to Login</a>
        </p>
      </div>
    </div>
  </div>
</form>