import moment from 'moment';

import { Base } from './_base.model';

export class ContactLicense extends Base {
  id: number;
  contact_id: string;
  license: string;
  state: string;
  expired_at: Date;
  status: string;

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.expired_at) {
      this.expired_at = moment(this.expired_at).toDate();
    }
    return this;
  }
}
