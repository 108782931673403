import moment from 'moment';

import { Base } from './_base.model';

export class Topic extends Base {
  id: number;
  name: string;
  version: string;
  info: string;
  description: string;
  topic_type_id: string;
  archived: boolean;
  valid_from: Date;
  valid_to: Date;
  salesforce_id: string;
  requestable: boolean;
  untrained_requestable: boolean;
  parent_id: string;

  topic_type: any;
  meeting_types: any[];
  products: any[];

  full_name: string;

  constructor() {
    super();
    this.requestable = true;
  }

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.valid_from) {
      this.valid_from = moment(this.valid_from).toDate();
    }
    if (this.valid_to) {
      this.valid_to = moment(this.valid_to).toDate();
    }
    return this;
  }
}
