import { Base } from './_base.model';

export class Workflow extends Base {
  id: number;
  name: string;
  active: boolean;
  deleted: boolean;
  meeting_types: any[];
  checklists: any[];
}
