import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CompanyService } from './_services';
import { BaseComponent } from './_components/base.component';

@Component({
  selector: 'app-redirect',
  template: '',
})
export class RedirectComponent extends BaseComponent {
  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
  ) {
    super(router, authenticationService, companyService);
    if (this.authenticationService.id()) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
