import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class EventSpeakerService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/speakers`);
  }

  override update(model: any, urlParams?: any, apiParams?: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams);
    return this.http.put<any>(url, model, {
      params: this.toHttpParams(apiParams),
    });
  }

  updateSlot(model: any, urlParams?: any, apiParams?: any): Observable<any> {
    const url = sprintf(this.baseUrl + '/slot', urlParams);
    return this.http.put<any>(url, model, {
      params: this.toHttpParams(apiParams)
    });
  }
}
