import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'firstMiddleName' })
export class FirstMiddleNamePipe implements PipeTransform {
  transform(input: any): string {
    if (!input) {
      return input;
    }
    let name = '';
    if (input.first_name) {
      name = input.first_name;
    }
    if (input.middle_name) {
      name += ' ' + input.middle_name;
    }
    return name;
  }
}