import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';

@Injectable()
export class EventInquiryService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/inquiries`);
  }

  addMessage(model: any, urlParams?: any, apiParams?: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/inquiries/%(inquiry_id)s/messages`, urlParams);
    return this.http.post<any>(url, model, {
      params: this.toHttpParams(apiParams)
    });
  }

  deleteMessage(urlParams?: any, apiParams?: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/inquiries/%(inquiry_id)s/messages/%(message_id)s`, urlParams);
    return this.http.delete<any>(url,{
      params: this.toHttpParams(apiParams)
    });
  }

  addFile(model: any, urlParams?: any, apiParams?: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/inquiries/%(inquiry_id)s/files`, urlParams);
    return this.http.post<any>(url, model, {
      params: this.toHttpParams(apiParams)
    });
  }

  deleteFile(urlParams?: any, apiParams?: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/inquiries/%(inquiry_id)s/files/%(file_id)s`, urlParams);
    return this.http.delete<any>(url,{
      params: this.toHttpParams(apiParams)
    });
  }

  notify(model:any, urlParams?: any, apiParams?: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/inquiries/%(inquiry_id)s/notify`, urlParams);
    return this.http.post<any>(url, model, {
      params: this.toHttpParams(apiParams)
    });
  }
  
}
