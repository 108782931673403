import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PopoverDirective } from "ngx-bootstrap/popover";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { BaseComponent } from "app/_components/base.component";
import { AuthenticationService, ClientService, CompanyService, SpeakerService, StaffService, EventService } from "app/_services";
import { SpeakerConfirmModalComponent } from '../../events/speaker/speaker-confirm-modal.component';

declare var $: any;

@Component({
  selector: 'app-person-popover',
  templateUrl: './person-popover.component.html',
})
export class PersonPopoverComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() type: string;
  @ViewChild('pop') pop: PopoverDirective;
  person: any;
  popoverLinkId: string;
  popoverContainerId: string;
  SpeakerConfirmModalRef: BsModalRef;

  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    companyService: CompanyService,
    private staffService: StaffService,
    private speakerService: SpeakerService,
    private clientService: ClientService,
    private modalService: BsModalService,
    private eventService: EventService
  ) {
    super(router, authenticationService, companyService);
  }

  ngOnInit(): void {
    this.popoverLinkId = `popover-link-${this.id}`;
    this.popoverContainerId = `popover-container-${this.id}`;
  }

  ngAfterViewInit() {
    $(document).on('click', (e: any) => {
      if (this.pop.isOpen) {
        const container = $(`#${this.popoverLinkId},#${this.popoverContainerId}`);
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          this.pop.hide();
        }
      }
    });
  }

  onClick() {
    if (this.type == 'staff') {
      this.staffService.get(this.id).subscribe((staff) => {
        this.person = staff;
      });
    } else if (this.type == 'area_manager') {
      this.clientService.get(parseInt(this.id), { company_id: this.getCurrentCompanyId() }).subscribe((manager) => {
        this.person = manager;
      });
    } else if (this.type == 'speaker') {
      this.speakerService.get(this.id, { company_id: this.getCurrentCompanyId() }).subscribe((res) => {
        this.person = res.speaker;
      });
    }
    this.pop.toggle();
  }

  getUrl() {
    if (this.type == 'staff') {
      if (this.person?.agency_id) {
        return '/tools/agency/' + this.person?.agency_id + '/staff/' + this.person?.id + '/edit';
      } else {
        return '/tools/staff/' + this.person?.id + '/edit';
      }
    } else if (this.type == 'area_manager') {
      return this.getCompanySalesforceUrl('/tools/area/manager');
    } else if (this.type == 'speaker') {
      return this.getCompanySalesforceUrl('/profiles/speakers/' + this.person?.id);
    }
    return '';
  }

  get event() {
    return this.eventService.getCurrentEvent();
  }

  speakerStatus() {
    this.SpeakerConfirmModalRef = this.modalService.show(SpeakerConfirmModalComponent, {
      initialState: {
        companyId: this.getCurrentCompanyId(),
        eventId: this.event.id,
        eventSpeaker: this.getEventSpeaker(),
        speakerSlots: this.getSpeakerSlots(),
      }
    });
    this.SpeakerConfirmModalRef.content.event.subscribe(result => {
      this.event.speakers = result;
    });
    this.pop.toggle();
  }

  getEventSpeaker() {
    const [speaker] = this.event.speakers.filter((s) => s.id === this.id);
    return speaker.EventSpeaker;
  }

  getSpeakerSlots() {
    return this.event.speakers.map((s) => s.EventSpeaker.slot).sort();
  }
}