import { Base } from './_base.model';

export class ContactAddress extends Base {
  id: number;
  title: string;
  contact_id: string;
  address_type: string;
  affiliation: string;
  department: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  phone: string;
  fax: string;
  email: string;

  constructor(address_type = '') {
    super();
    this.address_type = address_type;
    this.country = 'US';
  }

  getFullAddress() {
    let ret = this.address1;
    if (this.address2) {
      ret += ' ' + this.address2;
    }
    if (this.address3) {
      ret += ' ' + this.address3;
    }
    ret += ', ' + this.city + ', ' + this.state;
    if (this.zip) {
      ret += ' ' + this.zip;
    }
    return ret;
  }
}
