import { Base } from './_base.model';

export class Question extends Base {
  id: number;
  text: string;
  type_id: string;
  scope: string;
  active: boolean;
  choices: string;
  other: boolean;
  formatting: string;
  always_required: boolean;
  required_if_question_id: number;
  required_if_value: string | null;
  required_if_fields: string[] | null;
  required_if_dropdown_names: string[] | null;
  exclusive_question_id: number;
  visibility_condition_applied: boolean;
  visibility_command: string;
  visibility_condition_subject_id: number;
  visibility_condition_operator: string;
  visibility_condition_value: string;
  strong_disagree_label: string;
  strong_agree_label: string;
  field_id: string;

  deleted: boolean;
  archived: boolean;
  type: any;

  constructor() {
    super();
    this.type = {};
  }
}

