<a [popover]="personPopover" id="{{popoverLinkId}}" placement="bottom" href="javascript:void(0)" class="flag-popup"
  (click)="onClick()" triggers="" #pop="bs-popover">
  <ng-content></ng-content>
</a>
<ng-template #personPopover>
  <div class="row" id="{{popoverContainerId}}">
    <div class="col-auto">
      <img src="{{person?.photo ? person.photo : 'assets/img/user-profile.png'}}" width="100px"
        class="rounded-circle m-2">
    </div>
    <div class="col">
      <div>
        {{person | fullNameDegrees}}
      </div>
      <div>
        {{person?.title}}
      </div>
      <div>
        {{person?.affiliation}}
      </div>
      <div>
        <label>Ph: </label> {{person?.default_address?.phone}}
      </div>
      <div>
        <label>Cell: </label> {{person?.cell_phone}}
      </div>
      <div>
        {{person?.email}}
      </div>
      <div>
        <a [routerLink]="getUrl()" class="btn btn-primary">Edit</a>
        <a *ngIf="type == 'speaker'" href="javascript:void(0);" (click)="speakerStatus()" class="btn btn-secondary">Change Status</a>
      </div>
    </div>
  </div>
</ng-template>