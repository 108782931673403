import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Company, Salesforce } from '../_models';
import { BaseService } from './base.service';
import { COMPANY_STEP_LINKS } from 'app/app.constants';

@Injectable()
export class CompanyService extends BaseService {
  public companies: Company[];

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies`);
  }

  list(apiParams: any = {}, apiCall: boolean = false): Observable<Company[]> {
    if (this.companies && !apiCall && !(apiParams.all || apiParams.status)) {
      return new Observable((observer) => {
        observer.next(this.companies);
      });
    }
    return super.list({}, apiParams)
      .pipe(
        tap((companies) => {
          if (!(apiParams.all || apiParams.status)) {
            this.companies = companies;
          }
        }),
      );
  }

  get(id: string): Observable<Company> {
    return super.get(`${id}/company`)
      .pipe(
        map(res => new Company().deserialize(res))
      );
  }

  update(company: any): Observable<Company> {
    const url = this.baseUrl + `/${company.id}/company`;
    return this.http.put<any>(url, company).pipe(
      map(res => new Company().deserialize(res))
    );
  }

  acceptStripeTOS(id: string) {
    const url = this.baseUrl + `/${id}/company/stripe`;
    return this.http.put<any>(url, {
      tos_acceptance: true
    });
  }

  stripeConnect(id: string, body: any): Observable<any> {
    const url = this.baseUrl + `/${id}/company/stripe_connect`;
    return this.http.post<any>(url, body);
  }

  getStatus(company_id: string): Observable<any> {
    const url = this.baseUrl + `/${company_id}/status`;
    return this.http.get<any>(url, {});
  }

  getCompany(company_id: string): Company | null {
    if (this.companies) {
      for (const company of this.companies) {
        if (company.id.toString() === company_id.toString()) {
          return new Company().deserialize(company);
        }
      }
    }
    return null;
  }

  getSalesforce(company_id: string, salesforce_id: string): Salesforce | null {
    if (this.companies) {
      for (const company of this.companies) {
        if (company_id && company.id.toString() === company_id.toString()) {
          for (const salesforce of company.salesforces) {
            if (salesforce.id.toString() === salesforce_id) {
              return new Salesforce().deserialize(salesforce);
            }
          }
        }
      }
    }
    return null;
  }

  completeStep(company_id: string, value: string, router: Router | null = null, nextStep: number = 1) {
    const url = this.baseUrl + `/${company_id}/company/complete_step`;
    this.http.put<any>(url, { value }).subscribe(res => {
      if (router) {
        const step = COMPANY_STEP_LINKS.map((s) => s.step).indexOf(value) + nextStep;
        router.navigate(['/tools/company'], { queryParams: { status: 'pending', step } });
      }
    });
  }

  // getCurrentFiscalYear(company_id: string): any {
  //   if (this.companies) {
  //     for (const company of this.companies) {
  //       if (company.id === company_id) {
  //         return company.current_fiscal_year;
  //       }
  //     }
  //   }
  //   return null;
  // }
}
