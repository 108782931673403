import { Base } from './_base.model';

export class ReportUser extends Base {
  id!: number;
  file!: string;
  reset_at!: Date;
  url: string;

  constructor() {
    super();
  }
}
