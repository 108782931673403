import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class ForumService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/forums`);
  }

  getPersonnel(urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/personnel`, urlParams);
    return this.http.get<any>(url);
  }

  addPersonnel(clients: any[], urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/personnel`, urlParams);
    return this.http.post<any>(url, {
      clients: clients
    });
  }

  updatePersonnel(client: any, urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/`
      + `${client.forum_id}/personnel/${client.client_id}`, urlParams);
    return this.http.put<any>(url, client);
  }

  getParticipants(urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/participants`, urlParams);
    return this.http.get<any>(url);
  }

  addParticipants(contacts: any[], urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/participants`, urlParams);
    return this.http.post<any>(url, {
      contacts: contacts
    });
  }

  updateParticipant(participant: any, urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/`
      + `${participant.forum_id}/participants/${participant.contact_id}`, urlParams);
    return this.http.put<any>(url, participant);
  }

  getTopics(urlParams: any, params: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/topics`, urlParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams(params)
    });
  }

  addTopic(topic: any, urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/topics`, urlParams);
    return this.http.post<any>(url, topic);
  }

  getModules(urlParams: any, params: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/modules`, urlParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams(params)
    });
  }

  addModule(module: any, urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/modules`, urlParams);
    return this.http.post<any>(url, module);
  }

  updateModule(module: any, urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/${module.forum_id}/modules/${module.id}`, urlParams);
    return this.http.put<any>(url, module);
  }

  getSurveys(urlParams: any, params: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/surveys`, urlParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams(params)
    });
  }

  addSurvey(survey: any, urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/surveys`, urlParams);
    return this.http.post<any>(url, survey);
  }

  updateSurvey(survey: any, urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/${survey.forum_id}/surveys/${survey.id}`, urlParams);
    return this.http.put<any>(url, survey);
  }

  getResources(urlParams: any, params: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/resources`, urlParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams(params)
    });
  }

  addResource(resource: any, urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/resources`, urlParams);
    return this.http.post<any>(url, resource);
  }

  updateResource(resource: any, urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/`
      + `${resource.forum_id}/resources/${resource.id}`, urlParams);
    return this.http.put<any>(url, resource);
  }

  uploadResourceFile(formData: any, urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/resources/%(id)s`, urlParams);
    return this.http.put<any>(url, formData);
  }
}
