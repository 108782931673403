import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class EventDownloadFormService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/download_forms`);
  }

  download(id: any, urlParams: any = {}, apiParams: any = {}): Observable<Blob> {
    const url = sprintf(this.baseUrl, urlParams) + `/${id}`;
    return this.downloadFile(url, apiParams);
  }
}
