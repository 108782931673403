import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { sprintf } from 'sprintf-js';


import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class PresentationService extends BaseService {

  constructor(
    protected override http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/presentations`);
  }

  listModules(urlParams: any, params: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + '/modules';
    return this.http.get<any>(url, {
      params,
    });
  }

  getImages(id: string, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    return super.get(`${id}/images`, urlParams, apiParams);
  }
}
