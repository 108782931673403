<div *ngIf="currentStep==='client'" [@moveIn]="currentStep">
  <h2>Select a Rep</h2>
  <div>
    <table class="table table-striped ajax-table" datatable [dtOptions]="dtOptionsClients">
      <thead>
        <tr>
          <th></th>
          <th>Last name</th>
          <th>First name</th>
          <th>Territory</th>
          <th>Title</th>
          <th>Email</th>
          <th>Office Phone</th>
        </tr>
      </thead>
      <tbody *ngIf="clients?.length !== 0">
        <tr *ngFor="let client of clients">
          <td>
            <input type="radio" class="form-check-input" name="client_{{client.id}}"
              [checked]="model.client_id === client.id" (change)="clientSelected(client)" />
          </td>
          <td><a [routerLink]="getCompanySalesforceUrl(client.uri())">{{client.last_name}}</a></td>
          <td><a [routerLink]="getCompanySalesforceUrl(client.uri())">{{client.first_name}}</a></td>
          <td>
            <span *ngFor="let area of client.areas">
              <a [routerLink]="getCompanySalesforceUrl(area.uri())">{{area.name}}</a>
            </span>
          </td>
          <td>{{client.level.label}}</td>
          <td>{{client.email}}</td>
          <td>{{client.office_phone}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="clients?.length === 0">
        <tr>
          <td colspan="7" align="center">No matching records found</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="currentStep==='meeting_type'" [@moveIn]="currentStep">
  <h2>Select an Event Type</h2>
  <div class="form-check" *ngFor="let meetingType of meetingTypes">
    <input type="radio" class="form-check-input" name="meeting_type_id" id="meeting_type-{{meetingType.id}}"
      [checked]="model.meeting_type_id === meetingType.id" (change)="meetingTypeSelected(meetingType)">
    <label class="form-check-label" for="meeting_type-{{meetingType.id}}"> {{meetingType.name}}</label>
  </div>
</div>
<div *ngIf="isCurrentPreRequestQuestions()">
  <h2>{{model.pre_request_screens[currentPreRequestStep()].name}}</h2>
  <p>{{model.pre_request_screens[currentPreRequestStep()].description}}</p>
  <div *ngFor="let question of model.pre_request_screens[currentPreRequestStep()].questions;index as j"
    [@moveIn]="currentStep">
    <app-request-question [screenIndex]="currentPreRequestStep()" [questionIndex]="j" [question]="question"
      [answers]="model.pre_request_answers"></app-request-question>
  </div>
</div>
<div *ngIf="currentStep==='priority'" [@moveIn]="currentStep">
  <h2>Select a Priority</h2>
  <div class="form-check" *ngFor="let priority of priorities">
    <input type="radio" class="form-check-input" name="priority-{{priority.id}}"
      [checked]="model.priority === priority.id" (change)="prioritySelected(priority.id)">
    <label class="form-check-label" for="priority-{{priority.id}}">{{priority.label}}</label>
  </div>
</div>
<div *ngIf="isCurrentStepQuestions()">
  <h2>{{model.screens[currentStep].name}}</h2>
  <p>{{model.screens[currentStep].description}}</p>
  <div *ngFor="let question of model.screens[currentStep].questions;index as j" [@moveIn]="currentStep">
    <ng-template [ngIf]="!dedicatedQuestionTypes.includes(question.type_id)">
      <app-request-question [screenIndex]="currentStep" [questionIndex]="j" [question]="question"
        [answers]="model.answers" [states]="states"></app-request-question>
    </ng-template>
    <div *ngIf="question.type_id==='av_equipments'">
      <h3>{{question.text}}</h3>
      <app-checkbox-group name="av_equipments" [(ngModel)]="model.av_equipments" (change)="onChangeAvEquipments()"
        [options]="avEquipments">
      </app-checkbox-group>
    </div>
    <div *ngIf="question.type_id==='attendees'">
      <h3>Select Audience</h3>
      <p>
        <button type="button" class="btn btn-primary" (click)="newAudience()">Invite New</button>
      </p>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Credential</th>
            <th>City</th>
            <th>State</th>
            <th></th>
          </tr>
        </thead>
        <tbody *ngIf="model.attendees.length">
          <tr *ngFor="let attendee of model.attendees;index as i">
            <td>{{attendee.first_name}}</td>
            <td>{{attendee.last_name}}</td>
            <td>{{attendee.degrees}}</td>
            <td>{{attendee.city}}</td>
            <td>{{attendee.state}}</td>
            <td>
              <button type="button" class="btn btn-danger" (click)="onRemoveAudience(i)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="model.attendees.length === 0">
          <tr>
            <td colspan="6" align="center">No audience added</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="question.type_id==='venue'">
      <h3>Select a Venue</h3>
      <table class="table table-striped ajax-table" datatable [dtOptions]="dtOptionsVenues">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
          </tr>
        </thead>
        <tbody *ngIf="venues?.length !== 0">
          <tr *ngFor="let venue of venues">
            <td>
              <input type="checkbox" class="form-check-input" name="venue_{{venue.id}}"
                [checked]="isVenueSelected(venue)" (change)="toggleVenue(venue)"
                [disabled]="selectedVenues.length == question.max && !isVenueSelected(venue)" />
            </td>
            <td><a [routerLink]="getCompanySalesforceUrl('/tools/vendor/' + venue.id + '/edit')">{{venue.name}}</a>
            </td>
            <td><a [routerLink]="getCompanySalesforceUrl('/tools/vendor/' + venue.id + '/edit')">{{venue.city}}</a>
            </td>
            <td><a [routerLink]="getCompanySalesforceUrl('/tools/vendor/' + venue.id + '/edit')">{{venue.state}}</a>
            </td>
            <td><a [routerLink]="getCompanySalesforceUrl('/tools/vendor/' + venue.id + '/edit')">{{venue.zip}}</a>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="venues?.length === 0">
          <tr>
            <td colspan="7" align="center">No matching records found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="question.type_id==='caterer'">
      <h3>Select a Caterer</h3>
      <table class="table table-striped ajax-table" datatable [dtOptions]="dtOptionsCaterers">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
          </tr>
        </thead>
        <tbody *ngIf="caterers?.length !== 0">
          <tr>
            <td>
              <input type="radio" class="form-check-input" name="caterer_venue" [checked]="model.caterering_by_venue"
                (change)="catererSelected('venue')" />
            </td>
            <td>
              EVENT LOCATION
            </td>
            <td>
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr *ngFor="let caterer of caterers">
            <td>
              <input type="radio" class="form-check-input" name="caterer_{{caterer.id}}"
                [checked]="model.caterer_id === caterer.id" (change)="catererSelected(caterer)" />
            </td>
            <td>
              <a [routerLink]="getCompanySalesforceUrl('/tools/vendor/' + caterer.id + '/edit')">{{caterer.name}}</a>
            </td>
            <td>
              <a [routerLink]="getCompanySalesforceUrl('/tools/vendor/' + caterer.id + '/edit')">{{caterer.city}}</a>
            </td>
            <td>
              <a [routerLink]="getCompanySalesforceUrl('/tools/vendor/' + caterer.id + '/edit')">{{caterer.state}}</a>
            </td>
            <td>
              <a [routerLink]="getCompanySalesforceUrl('/tools/vendor/' + caterer.id + '/edit')">{{caterer.zip}}</a>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="caterers?.length === 0">
          <tr>
            <td colspan="7" align="center">No matching records found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="question.type_id==='topic'">
      <h3>Select a Topic</h3>
      <table class="table table-striped ajax-table">
        <tbody *ngIf="topics?.length !== 0">
          <tr *ngFor="let topic of topics">
            <td width="1%">
              <input type="radio" class="form-check-input" name="topic_{{topic.id}}"
                [checked]="model.topic_id === topic.id" (change)="topicSelected(topic)" />
            </td>
            <td>{{topic.full_name}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="topics?.length === 0">
          <tr>
            <td colspan="2" align="center">No matching records found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="question.type_id==='speakers'">
      <h3>Select a Speaker</h3>
      <table class="table table-striped ajax-table" datatable [dtOptions]="dtOptionsSpeakers">
        <thead>
          <tr>
            <th></th>
            <th>Last name</th>
            <th>First name</th>
            <th>Degree</th>
            <th>City</th>
            <th>State</th>
            <th>Affiliation</th>
          </tr>
        </thead>
        <tbody *ngIf="speakers?.length !== 0">
          <tr *ngFor="let speaker of speakers">
            <td>
              <input type="checkbox" class="form-check-input" name="speaker_{{speaker.id}}"
                [checked]="isSpeakerSelected(speaker)" (change)="toggleSpeaker(speaker)" *ngIf="speaker.requestable"
                [disabled]="selectedSpeakers.length == question.max && !isSpeakerSelected(speaker)" />
              <div *ngIf="!speaker.requestable" [tooltip]="speaker.requestable_reason">
                <i class="fa fa-ban"></i>
              </div>
            </td>
            <td>
              <a [routerLink]="getCompanySalesforceUrl('/profiles/speakers/' + speaker.id)">{{speaker.last_name}}</a>
            </td>
            <td>
              <a [routerLink]="getCompanySalesforceUrl('/profiles/speakers/' + speaker.id)">{{speaker.first_name}}</a>
            </td>
            <td>
              <a [routerLink]="getCompanySalesforceUrl('/profiles/speakers/' + speaker.id)">{{speaker.degrees |
                commaList}}</a>
            </td>
            <td>{{speaker.default_address?.city}}</td>
            <td>{{speaker.default_address?.state}}</td>
            <td>{{speaker.default_address?.affiliation}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="speakers?.length === 0">
          <tr>
            <td colspan="7" align="center">No matching records found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="question.type_id==='event_start_at'">
      <h3>Select Date and Time</h3>
      <div class="row mb-3" *ngFor="let selectedEventDate of selectedEventDates;index as i">
        <label class="offset-md-4 col-md-1 col-form-label" [ngClass]="{'required': i < getRequiredDatesNum()}">Date {{i + 1}}</label>
        <div class="col-md-3">
          <input type="text" name="event_date_{{i}}" [bsValue]="selectedEventDate.event_date"
            class="form-control datetimepicker" autocomplete="off" bsDatepicker [minDate]="minDate" [maxDate]="maxDate"
            [disabled]="datePickerDisabled" (bsValueChange)="dateSelected(i, $event)">
          <!--<bs-datepicker-inline [bsValue]="event_date" (bsValueChange)="dateSelected($event)" [minDate]="minDate" [maxDate]="maxDate" [isDisabled]="datePickerDisabled">
          </bs-datepicker-inline>-->
        </div>
      </div>
      <div class="row mb-3">
        <label class="offset-md-4 col-md-1 col-form-label">Time</label>
        <div class="col-md-3">
          <timepicker minuteStep="15" [(ngModel)]="event_time" (ngModelChange)="timeSelected()">
          </timepicker>
        </div>
      </div>
      <div class="row">
        <label class="offset-md-4 col-md-1 col-form-label">Time Zone</label>
        <div class="col-md-3">
          <select name="timezone_id" class="form-control timezone-picker" [(ngModel)]="model.timezone_id">
            <option value="">Please select a time zone</option>
            <option [value]="timezone.id" *ngFor="let timezone of timezones">{{timezone.label}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="currentStep==='preview'" [@moveIn]="currentStep">
  <a class="float-end btn btn-primary" [routerLink]="getCompanySalesforceUrl('/requests')"
    [queryParams]="{status: 'pending'}" *ngIf="model.id">View Pending Requests</a>
  <h2 class="mb-3">
    Preview Request
  </h2>
  <div class="request-preview">
    <table class="mb-3" width="100%">
      <tr>
        <th width="300px">
          Client
          <a href="javascript:void(0)" class="ms-2" (click)="moveTo('client')">
            <i class="fa fa-pencil"></i>
          </a>
        </th>
        <td>{{client | fullName}}</td>
      </tr>
      <tr>
        <th>
          Event Type
          <a href="javascript:void(0)" class="ms-2" (click)="moveTo('meeting_type')">
            <i class="fa fa-pencil"></i>
          </a>
        </th>
        <td>{{meetingType.name}}</td>
      </tr>
      <tr *ngIf="!priorityAutomaticallySelected">
        <th>
          Priority
          <a href="javascript:void(0)" class="ms-2" (click)="moveTo('priority')">
            <i class="fa fa-pencil"></i>
          </a>
        </th>
        <td>{{model.priority | titlize}}</td>
      </tr>
      <tr *ngIf="getScreenIndex('speakers') !== null">
        <th valign="top">
          Speaker(s)
          <a href="javascript:void(0)" class="ms-2" (click)="moveTo(getScreenIndex('speakers'))">
            <i class="fa fa-pencil"></i>
          </a>
        </th>
        <td>
          <div class="mb-2" *ngFor="let speaker of selectedSpeakers">
            {{speaker | fullName}}
          </div>
        </td>
      </tr>
      <tr *ngIf="getScreenIndex('topic') !== null">
        <th>
          Topic
          <a href="javascript:void(0)" class="ms-2" (click)="moveTo(getScreenIndex('topic'))">
            <i class="fa fa-pencil"></i>
          </a>
        </th>
        <td>{{topic?.name}}</td>
      </tr>
      <tr *ngIf="getScreenIndex('event_start_at') !== null">
        <th valign="top">
          Requested Date/Time(s)
          <a href="javascript:void(0)" class="ms-2" (click)="moveTo(getScreenIndex('event_start_at'))">
            <i class="fa fa-pencil"></i>
          </a>
        </th>
        <td>
          <ng-template ngFor let-request_datetime [ngForOf]="model.request_datetimes">
            <div class="mb-2" *ngIf="request_datetime.event_date && timezones">
              {{request_datetime.event_date | date: 'MMMM d, yyyy'}} at {{event_time | date: 'h:mm a'}}
              {{getTimezoneAbbr(model.timezone_id)}}
            </div>
          </ng-template>
        </td>
      </tr>
      <tr *ngIf="getScreenIndex('venue') !== null">
        <th valign="top">
          Venue(s)
          <a href="javascript:void(0)" class="ms-2" (click)="moveTo(getScreenIndex('venue'))">
            <i class="fa fa-pencil"></i>
          </a>
        </th>
        <td>
          <div class="mb-2" *ngFor="let venue of selectedVenues">
            <div>{{venue.name}}</div>
            <div>{{venue.address1}}</div>
            <div>{{venue.city}}, {{venue.state}} {{venue.zip}}</div>
          </div>
        </td>
      </tr>
      <tr *ngIf="caterer">
        <th valign="top">
          Caterer
          <a href="javascript:void(0)" class="ms-2" (click)="moveTo(getScreenIndex('caterer'))">
            <i class="fa fa-pencil"></i>
          </a>
        </th>
        <td>
          <div>{{caterer.name}}</div>
          <div>{{caterer.address1}}</div>
          <div>{{caterer.city}}, {{caterer.state}} {{caterer.zip}}</div>
        </td>
      </tr>
      <tr *ngIf="getScreenIndex('av_equipments') !== null">
        <th valign="top">
          AV Equipments
          <a href="javascript:void(0)" class="ms-2" (click)="moveTo(getScreenIndex('av_equipments'))">
            <i class="fa fa-pencil"></i>
          </a>
        </th>
        <td>
          <div *ngFor="let av_equipment of selectedAvEquipments">{{av_equipment.name}}</div>
        </td>
      </tr>
      <ng-template ngFor let-screen let-i="index" [ngForOf]="model.screens">
        <tr *ngIf="hasNonDedicatedQuestions(screen) && model.answers[i] != undefined">
          <th valign="top">
            {{screen.name}}
            <a href="javascript:void(0)" class="ms-2" (click)="moveTo(i)">
              <i class="fa fa-pencil"></i>
            </a>
          </th>
          <td>
            <ng-template ngFor let-question let-j="index" [ngForOf]="screen.questions">
              <app-request-question-preview [dedicatedQuestionTypes]="dedicatedQuestionTypes" [question]='question'
                [answers]='model.answers' [screenIndex]="i" [questionIndex]="j" [depth]="0">
              </app-request-question-preview>
            </ng-template>
          </td>
        </tr>
      </ng-template>
    </table>
    <div *ngIf="getScreenIndex('attendees')">
      <h4>
        Audience
        <a href="javascript:void(0)" class="ms-2" (click)="moveTo(getScreenIndex('attendees'))">
          <i class="fa fa-pencil"></i>
        </a>
      </h4>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Credential</th>
            <th>City</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let attendee of model.attendees">
            <td>{{attendee.first_name}}</td>
            <td>{{attendee.last_name}}</td>
            <td>{{attendee.degrees}}</td>
            <td>{{attendee.city}}</td>
            <td>{{attendee.state}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="mt-5 text-center" [@moveIn]="currentStep" *ngIf="!previewModalFlag">
  <button type="button" class="btn btn-primary" *ngIf="steps.length > 1 && !model.id"
    (click)="firstStep()">First</button>
  <button type="button" class="btn btn-primary" *ngIf="steps.length > 1 && !model.id" (click)="prevStep()">Prev</button>
  <button type="button" class="btn btn-primary" *ngIf="currentStep==='client' && !model.id" [disabled]="!client"
    (click)="nextClicked()">Next</button>
  <button type="button" class="btn btn-primary" *ngIf="currentStep==='meeting_type' && !model.id"
    [disabled]="!meetingType" (click)="nextClicked()">Next</button>
  <button type="button" class="btn btn-primary" *ngIf="currentStep==='priority' && !model.id"
    [disabled]="!model.priority" (click)="nextClicked()">Next</button>
  <button type="button" class="btn btn-primary" *ngIf="isCurrentStepQuestions() && !model.id"
    [disabled]="isStepQuestionsNotNextable()" (click)="nextClicked()">Next</button>
  <button type="button" class="btn btn-primary" *ngIf="isCurrentPreRequestQuestions() && !model.id"
    (click)="nextClicked()">Next</button>
  <button type="button" class="btn btn-primary" *ngIf="currentStep!=='preview' && model.id"
    (click)="lastStep()">Preview</button>
  <button type="button" class="btn btn-primary" *ngIf="currentStep==='preview' && !model.id"
    (click)="createRequest()">Submit</button>
  <button type="button" class="btn btn-primary" *ngIf="model.id" (click)="updateRequest()">Save Edits</button>
</div>
<div class="mt-5 text-center" [@moveIn]="currentStep" *ngIf="previewModalFlag">
  <button type="button" class="btn btn-primary" [disabled]="steps.length <= 1" (click)="prevStep()">Prev</button>
  <button type="button" class="btn btn-primary" *ngIf="currentStep!=='preview'" (click)="nextClicked()">Next</button>
  <button type="button" class="btn btn-primary" *ngIf="currentStep==='preview'" (click)="closeClicked()">Close</button>
</div>
