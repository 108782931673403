import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';
import { Base } from './_base.model';

export class Request extends Base {
  id: number;
  salesforce_id: string;
  client_id: number;
  meeting_type_id: number;
  priority: string;
  timezone_id: string;
  topic_id: number;
  caterer: any;
  caterer_id: number;
  caterering_by_venue: boolean;
  screens: any[] = [];
  pre_request_screens: any[] = [];
  answers: any = {};
  pre_request_answers: any = {};
  request_form_id: number;
  pre_request_form_id: number;
  speakers: any[] = [];
  request_speakers: any[] = [];
  request_venues: any[] = [];
  request_datetimes: any[] = [];
  attendees: any[] = [];
  av_equipments: any[] = [];
  salesforce: any;
  meeting_type: any;
  client: any;
  topic: any;
  status: string;
  request_status: any;
  status_changes: any[] = [];
  declined_reason: string;

  deserialize(obj: any) {
    Object.assign(this, obj);
    ['screens', 'pre_request_screens', 'attendees'].forEach((k) => {
      if (obj[k]) {
        this[k] = JSON.parse(obj[k]) || [];
        this[k] = this[k].filter((i) => i); // filter null values
      }
    });
    ['answers', 'pre_request_answers'].forEach((k) => {
      if (obj[k]) {
        this[k] = JSON.parse(obj[k]) || {};
      }
    });
    this.request_datetimes.forEach((rd) => {
      if (rd.event_start_at) {
        const eventStartAt = moment(rd.event_start_at).tz(this.timezone_id);
        rd.event_start_at = eventStartAt.toDate();
        rd.event_date = eventStartAt.format('YYYY-MM-DD');
        rd.event_time = eventStartAt.format('HH:mm');
      }
    });
    return this;
  }

  setEventDateTimeUTC(numberOfEventHours: number) {
    this.request_datetimes.forEach((rd) => {
      if (rd.event_date && rd.event_time && this.timezone_id) {
        rd.event_start_at = moment.tz(`${rd.event_date} ${rd.event_time}`, this.timezone_id).toDate();
        rd.event_end_at = moment.tz(`${rd.event_date} ${rd.event_time}`, this.timezone_id).add(numberOfEventHours, 'hours').toDate();
      } else {
        rd.event_start_at = null;
        rd.event_end_at = null;
      }
    });
  }

  getStatusLabel() {
    if (this.status === 'pending' && this.request_status?.status_label) {
      return this.request_status?.status_label;
    }
    return this.status;
  }
}
