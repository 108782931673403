import { Base } from './_base.model';

export class EquipmentsRequest extends Base {
  equipments: any[];
  comments: string;

  constructor() {
    super();
    this.equipments = [];
    this.comments = '';
  }
}
