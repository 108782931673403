<div class="modal-header">
  <h4 class="modal-title">New contact for attendees</h4>
  <button type="button" class="btn btn-danger float-end" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="newAttendee" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-3">
        <label class="form-label" for="type">Type</label>
        <select formControlName="type" class="form-control" required>
          <option value="call_plan">Call Plan</option>
          <option value="contact">Contact</option>
        </select>
      </div>
      <div class="col-3">
        <label class="form-label required" for="first_name">First Name</label>
        <input type="text" formControlName="first_name" class="form-control" required>
      </div>
      <div class="col-3">
        <label class="form-label" for="middle_name">Middle Name</label>
        <input type="text" formControlName="middle_name" class="form-control">
      </div>
      <div class="col-3">
        <label class="form-label required" for="last_name" required>Last Name</label>
        <input type="text" formControlName="last_name" class="form-control" required>
      </div>
      <div class="col-4">
        <label class="form-label" for="email">Email</label>
        <input type="text" formControlName="email" class="form-control">
      </div>
      <div class="col-4">
        <label class="form-label" for="degree">Degrees</label>
        <select formControlName="degree" class="form-control">
          <ng-template ngFor let-degree [ngForOf]="degrees">
            <option [value]="degree.value">{{degree.label}}</option>
          </ng-template>
        </select>
      </div>
      <div class="col-4">
        <label class="form-label" for="specialty">Specialties</label>
        <select formControlName="specialty" class="form-control">
          <ng-template ngFor let-specialty [ngForOf]="specialties">
            <option [value]="specialty.value">{{specialty.label}}</option>
          </ng-template>
        </select>
      </div>
      <div class="col-4">
        <label class="form-label" for="profession">Profession</label>
        <select formControlName="profession" class="form-control">
          <ng-template ngFor let-profession [ngForOf]="professions">
            <option [value]="profession.value">{{profession.label}}</option>
          </ng-template>
        </select>
      </div>
      <div class="col-4">
        <label class="form-label" for="npi_no">NPI</label>
        <input type="text" formControlName="npi_no" class="form-control">
      </div>
      <div class="col-4">
        <label class="form-label" for="state_license">State License</label>
        <div class="row">
        <div class="col-6">
          <select formControlName="state_license" class="form-control" required>
            <ng-template ngFor let-state [ngForOf]="states">
              <option [value]="state.code" *ngIf="state.country_id === 'US'">{{state.name}}</option>
            </ng-template>
          </select>
        </div>
        <div class="col-6">
          <input type="text" formControlName="state_number" class="form-control" placeholder="number">
        </div>
      </div>
      </div>
      <div class="col-4">
        <label class="form-label" for="affiliation">Affiliation</label>
        <input type="text" formControlName="affiliation" class="form-control">
      </div>
      <div class="col-4">
        <label class="form-label" for="address1">Street Address 1</label>
        <input type="text" formControlName="address1" class="form-control">
      </div>
      <div class="col-4">
        <label class="form-label" for="address2">Street Address 2</label>
        <input type="text" formControlName="address2" class="form-control">
      </div>
      <div class="col-4">
        <label class="form-label required" for="city" required>City</label>
        <input type="text" formControlName="city" class="form-control" required>
      </div>
      <div class="col-4">
        <label class="form-label required" for="state">State</label>
        <select formControlName="state" class="form-control" required>
          <ng-template ngFor let-state [ngForOf]="states">
            <option [value]="state.code" *ngIf="state.country_id === 'US'">{{state.name}}</option>
          </ng-template>
        </select>
      </div>
      <div class="col-4">
        <label class="form-label" for="zip">Zip</label>
        <input type="text" formControlName="zip" class="form-control">
      </div>
      <div class="col-2">
        <label class="form-label">&nbsp;</label><br />
        <button class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>
</div>
