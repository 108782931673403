import { Base } from './_base.model';

export class ForumModule extends Base {
  id: number;
  forum_id: number;
  name: string;
  description: string;
  user_id: string;
  created_at: Date;
  archived: boolean;

  surveys: any;
  resources: any;
  forum_module_surveys: any[];
  forum_module_resources: any[];

  constructor() {
    super();
    this.surveys = {};
    this.resources = {};
  }

  deserialize(obj: any) {
    Object.assign(this, obj);
    this.surveys = {};
    this.resources = {};
    if (this.forum_module_surveys) {
      this.forum_module_surveys.forEach((ms) => {
        this.surveys[ms.forum_survey_id] = true;
      });
    }
    if (this.forum_module_resources) {
      this.forum_module_resources.forEach((mr) => {
        this.resources[mr.forum_resource_id] = true;
      });
    }
    return this;
  }

  serialize() {
    const resource_data = Object.assign({}, this);
    if (resource_data.forum_module_surveys) {
      delete resource_data.forum_module_surveys;
    }
    if (resource_data.forum_module_resources) {
      delete resource_data.forum_module_resources;
    }
    return resource_data;
  }
}
