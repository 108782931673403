import { Injectable } from '@angular/core';
import auth0 from 'auth0-js';

import { environment } from '../../environments/environment';

@Injectable()
export class Auth0Service {
  private auth0 = new auth0.WebAuth({
    ...environment.AUTH0,
    responseType: 'token id_token',
    scope: 'openid profile email',
  });

  constructor() {}

  public login() {
    this.auth0.authorize();
  }

  public parseHash(cb) {
    this.auth0.parseHash(cb);
  }
}