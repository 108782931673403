import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class EventFileService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/files`);
  }

  updateUploadForms(model: any, urlParams: any = {}): Observable<any> {
    let url = `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/upload_forms`;
    url = sprintf(url, urlParams);
    return this.http.post<any>(url, model);
  }
}
