import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html'
})
export class LoadingModalComponent implements OnInit {
  message: string = 'Loading';
  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {

  }
}
