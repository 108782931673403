import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class SupportContactService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/support_contacts`);
  }

  setDefault(model: any, urlParams?: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/default`;
    return this.http.post(url, model);
  }
}
