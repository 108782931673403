import moment from 'moment';

import { Salesforce } from './salesforce.model';
import { Base } from './_base.model';

export class Company extends Base {
  id: string;
  name: string;
  description: string;
  image: string;
  wide_image: string;
  waiting_room_video: string;
  green_room_video: string;
  header_color: string;
  background_color: string;
  active_at: Date;
  expire_at: Date;
  status_id: string;
  // node_host_status: string;
  // db_status: string;
  salesforces: Salesforce[];
  current_fiscal_year: any;
  has_division: boolean;
  divisions: any;
  presentation_download_speaker: boolean;
  presentation_download_client: boolean;
  payments_from: string;
  venue_status_from: string;
  hcp_validation_target: string;
  hcp_validation_sources: string[] | null;
  trusted_public_events: boolean;
  trusted_call_plan: boolean;
  default_request_form_id: number;
  default_password: string;
  default_cc: string;
  status: any;
  image_file: string | ArrayBuffer | null;
  wide_image_file: string | ArrayBuffer | null;
  waiting_room_video_file: string | ArrayBuffer | null;
  green_room_video_file: string | ArrayBuffer | null;
  meta: any;
  on_demand_meeting: boolean;
  event_more_info_message_agency: string;
  event_more_info_message_salesforce: string;
  budget_allocation_by: string;
  fiscal_year_begins: number;
  chat_supported_by: string;

  travel_agent_id: string;
  travel_agent_future_id: string;
  travel_agent_future_start_at: Date;

  nextStep: any;
  api_ready: boolean;

  current_agency_id: string;

  stripe_account_id: string;
  stripe_account_tos_at: Date;
  auth0_domain: string;
  auth0_client_id: string;

  pay_faculty_with_plaid: boolean;

  completed_step_index: number;
  target_step: number;

  get breadcrumbTitle(): string {
    return this.name;
  }

  get breadcrumbLink(): string {
    return this.uri();
  }

  uri(path = '') {
    return `/tools/company/${this.id}${path}`;
  }

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.active_at) {
      this.active_at = moment(this.active_at).toDate();
    }
    if (this.expire_at) {
      this.expire_at = moment(this.expire_at).toDate();
    }
    if (this.travel_agent_future_start_at) {
      this.travel_agent_future_start_at = moment(this.travel_agent_future_start_at).toDate();
    }
    return this;
  }
}
