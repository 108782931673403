import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { USER_ROLES } from './app.constants';
import { AuthGuard } from './_guards/auth.guard';

/***************** Layouts *****************/
import {
  SiteLayoutComponent,
  LoginLayoutComponent,
} from './_layouts';

import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password.component';
import { NotFoundComponent } from './404/not-found.component';
import { StaticPageComponent } from './static/static-page.component';
import { RedirectComponent } from './redirect.component';
import { DummyComponent } from './dummy.component';
import { LogoutComponent } from './logout.component';

const routes: Routes = [
  {
    path: '',
    component: RedirectComponent, // Redirect to login or dashboard
    pathMatch: 'full'
  },
  // Auth Pages
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
    ]
  },
  // Logined Pages
  {
    path: '',
    component: SiteLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'tools',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF] },
        loadChildren: () => import('./tools/tools-global.module').then(m => m.ToolsModule)
      },
      {
        path: 'profiles',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./profiles/profiles-global.module').then(m => m.ProfilesModule)
      },
      {
        path: 'my',
        loadChildren: () => import('./my-pages/my-pages.module').then(m => m.MyPagesModule)
      },
      {
        path: 'chat',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
      },
    ]
  },
  {
    path: ':companySalesforce',
    component: SiteLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./overview/overview.module').then(m => m.OverviewModule)
      },
      {
        path: 'events',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'requests',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./requests/requests.module').then(m => m.RequestsModule)
      },
      {
        path: 'non-bureau',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./non-bureau/non-bureau.module').then(m => m.NonBureauModule)
      },
      {
        path: 'resource',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./resource/resource.module').then(m => m.ResourceModule)
      },
      {
        path: 'venues',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./venue/venue.module').then(m => m.VenueModule)
      },
      {
        path: 'finance',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF] },
        loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule)
      },
      {
        path: 'profiles',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./profiles/profiles-company.module').then(m => m.ProfilesModule)
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF] },
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'reports-custom',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF] },
        loadChildren: () => import('./custom-reports/custom-reports.module').then(m => m.CustomReportsModule)
      },
      {
        path: 'kol-contracting',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./kol-contracting/kol-contracting.module').then(m => m.KOLContractingModule)
      },
      {
        path: 'kol-management',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./kol-management/kol-management.module').then(m => m.KOLManagementModule)
      },
      {
        path: 'forums',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.CLIENT] },
        loadChildren: () => import('./forums/forums.module').then(m => m.ForumsModule)
      },
      {
        path: 'tools',
        canActivate: [AuthGuard],
        data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF] },
        loadChildren: () => import('./tools/tools-company.module').then(m => m.ToolsModule)
      },
    ]
  },
  {
    path: ':companySalesforce/events/:eventId/tasks-popup',
    canActivate: [AuthGuard],
    data: { roles: [USER_ROLES.STAFF, USER_ROLES.AGENCY_STAFF, USER_ROLES.CLIENT] },
    loadChildren: () => import('./events/tasks/tasks-popup.module').then(m => m.TasksPopupModule)
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'webcasts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./webcast/webcast.module').then(m => m.WebcastModule)
  },
  // Test page for webcasts by videosdk
  {
    path: 'webcasts-videosdk',
    canActivate: [AuthGuard],
    loadChildren: () => import('./videosdk/webcast.module').then(m => m.WebcastModule)
  },
  // Public Pages
  {
    path: 'us/:id',
    component: StaticPageComponent
  },
  {
    path: 'dummy',
    component: DummyComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always',
    })
  ],
  exports: [RouterModule],
  declarations: []
})

export class AppRoutingModule { }
