import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class EventWebcastService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/webcasts`);
  }

  override list(urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/webcasts`, urlParams);
    return this.http.get<any>(url);
  }

  openToAudience(webcastId: string): Observable<any> {
    const url = this.baseUrl + `/${webcastId}/open_to_audience`;
    return this.http.post<any>(url, {});
  }

  validatePasscode(webcast: any, type: string): Observable<any> {
    const url = this.baseUrl + `/${webcast.id}/validate_passcode?type=${type}`;
    return this.http.post<any>(url, webcast);
  }

  startWebcast(webcastId: string, type: string): Observable<any> {
    const url = this.baseUrl + `/${webcastId}/start_webcast?type=${type}`;
    return this.http.post<any>(url, {});
  }

  startPresentation(webcastId: string, type: string): Observable<any> {
    const url = this.baseUrl + `/${webcastId}/presentation`;
    return this.http.post<any>(url, {
      type: type
    });
  }

  conclude(webcastId: string, type: string): Observable<any> {
    const url = this.baseUrl + `/${webcastId}/conclude`;
    return this.http.post<any>(url, {});
  }
}
