import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
/**
* value1 - value2
*/
@Pipe({
  name: 'momentDuration'
})
export class MomentDurationPipe implements PipeTransform {
  transform(
    value1: string | Date,
    value2: string | Date,
    unit: moment.unitOfTime.Diff = 'days',
  ): number {
    return moment(value1).diff(moment(value2), unit, false);
  }
}
