import { Base } from './_base.model';

export class FiscalYear extends Base {
  id: string;
  starts_on: Date;
  description: boolean;
  title: string;

  constructor() {
    super();
  }
}