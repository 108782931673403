import { environment } from 'environments/environment';

export const COMPANY_NAME = environment.COMPANY_NAME;
export const COMPANY_SITE = environment.COMPANY_SITE;

export const USER_IDLE_CONFIG = {
  idle: 1500,
  timeout: 300,
  ping: 60,
};

export const USER_ROLES = {
  STAFF: 'staff',
  AGENCY_STAFF: 'agency_staff',
  CLIENT: 'client',
  CONTACT: 'contact',
};

export const REQUEST_PRIORITIES = [
  { id: 'date', label: 'Date' },
  { id: 'topic', label: 'Topic' },
  { id: 'speaker', label: 'Speaker' },
  { id: 'secondary_event', label: 'Secondary Event' },
];

export const FISCAL_YEAR_PARAM = 'fiscal_year';
export const QUARTER_PARAM = 'quarter';

export const DEFAULT_PAGE_SIZE = 100;
export const LENGTH_MENU = [
  [50, 100, 250, -1],
  [50, 100, 250, 'All'],
];

export const IMAGES = {
  COMPANY_IMAGE_FALLBACK: 'assets/img/br-img-icon.png',
  SALESFORCE_IMAGE_FALLBACK: 'assets/img/br-img-icon.png',
  PRODUCT_IMAGE_FALLBACK: 'assets/img/br-img-icon.png',
};

export const EVENT_CARDS = {
  DETAILS: 'details',
  AUDIENCE: 'audience',
  AV: 'a/v',
  LOCATION: 'location',
  WEBCAST: 'webcast',
  OWNER: 'owner',
  INVITATIONS: 'invitations',
  CATERER: 'caterer',
  SPEAKER_TRAVEL: 'speaker-travel',
  SPEAKER: 'speaker',
  BUDGETS: 'budgets',
  NOTES: 'notes',
  TASKS: 'tasks',
  DOCUMENTS: 'documents',
  DOWNLOAD_FORMS: 'download-forms',
  NOTIFICATION: 'notification',
  EVALUATIONS: 'evaluations',
  CLOSEOUT: 'closeout',
  // CARDS: 'cards',
};

export const EVENT_CARD_STATUSES = [
  { value: 'pending', label: 'Pending' },
  { value: 'submitted', label: 'Submitted' },
  { value: 'approved', label: 'Approved' },
  { value: 'confirmed', label: 'Confirmed' },
  { value: 'completed', label: 'Completed' },
  { value: 'na', label: 'N/A' },
];

export const ATTENDEE_RESTRICTION_PERIOD = [
  { value: 'calendar_year', label: 'Calendar Year' },
  { value: 'rolling_twelve_months', label: 'Rolling Twelve Months' },
];

export const ATTENDEE_RESTRICTION_TOPIC_SCOPES = [
  { value: 'any_topic', label: 'For Any Topic' },
  { value: 'same_topic', label: 'For Same Topic' },
  { value: 'same_topic_version', label: 'For Same Topic Version' },
];

export const ITEM_COUNTS = [
  { value: 1, label: 'One' },
  { value: 2, label: 'Two' },
  { value: 3, label: 'Three' },
  { value: 4, label: 'Four' },
  { value: 5, label: 'Five' },
];

export const REGIONS = [
  { id: 'nw', color: '#b0e0f2', label: 'North West' },
  { id: 'sw', color: '#3cccd4', label: 'South West' },
  { id: 'grl', color: '#00a99f', label: 'GreatLakes' },
  { id: 'mw', color: '#00c5c6', label: 'Mid West' },
  { id: 'grp', color: '#8279be', label: 'Great Plains' },
  { id: 'sc', color: '#009c3b', label: 'South Central' },
  { id: 'se', color: '#00b837', label: 'South East' },
  { id: 'ma', color: '#cd73af', label: 'Mid Atlantic' },
  { id: 'neng', color: '#669ad5', label: 'New England' },
  { id: 'panj', color: '#c7c800', label: 'PA/NJ/NYC' },
];

export const US_STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

export const US_STATE_REGIONS = [
  { id: 'US-AL', region: 'sc', latitude: 32.3182, longitude: -86.9023 },
  { id: 'US-AK', region: 'nw', latitude: 64.2008, longitude: -149.4937 },
  { id: 'US-AZ', region: 'sw', latitude: 34.0489, longitude: -111.0937 },
  { id: 'US-AR', region: 'grp', latitude: 34.5574, longitude: -92.2863 },
  { id: 'US-CA', region: 'sw', latitude: 36.7783, longitude: -119.4179 },
  { id: 'US-CO', region: 'grp', latitude: 39.5501, longitude: -105.7821 },
  { id: 'US-CT', region: 'neng', latitude: 41.6032, longitude: -73.0877 },
  { id: 'US-DE', region: 'panj', latitude: 38.9108, longitude: -75.5277 },
  { id: 'US-FL', region: 'se', latitude: 27.6648, longitude: -81.5158 },
  { id: 'US-GA', region: 'se', latitude: 32.1656, longitude: -82.9001 },
  { id: 'US-HI', region: 'sw', latitude: 19.8968, longitude: -155.5828 },
  { id: 'US-ID', region: 'nw', latitude: 44.0682, longitude: -114.742 },
  { id: 'US-IL', region: 'mw', latitude: 40.6331, longitude: -89.3985 },
  { id: 'US-IN', region: 'grl', latitude: 40.2672, longitude: -86.1349 },
  { id: 'US-IA', region: 'mw', latitude: 41.878, longitude: -93.0977 },
  { id: 'US-KS', region: 'grp', latitude: 39.0119, longitude: -98.4842 },
  { id: 'US-KY', region: 'grl', latitude: 37.8393, longitude: -84.27 },
  { id: 'US-LA', region: 'sc', latitude: 30.9843, longitude: -91.9623 },
  { id: 'US-ME', region: 'neng', latitude: 45.2538, longitude: -69.4455 },
  { id: 'US-MD', region: 'ma', latitude: 39.0458, longitude: -76.6413 },
  { id: 'US-MA', region: 'neng', latitude: 42.4072, longitude: -71.3824 },
  { id: 'US-MI', region: 'grl', latitude: 44.3148, longitude: -85.6024 },
  { id: 'US-MN', region: 'mw', latitude: 46.7296, longitude: -94.6859 },
  { id: 'US-MS', region: 'sc', latitude: 32.3547, longitude: -89.3985 },
  { id: 'US-MO', region: 'grp', latitude: 37.9643, longitude: -91.8318 },
  { id: 'US-MT', region: 'nw', latitude: 46.8797, longitude: -110.3626 },
  { id: 'US-NE', region: 'grp', latitude: 41.4925, longitude: -99.9018 },
  { id: 'US-NV', region: 'nw', latitude: 38.8026, longitude: -116.4194 },
  { id: 'US-NH', region: 'neng', latitude: 43.1939, longitude: -71.5724 },
  { id: 'US-NJ', region: 'panj', latitude: 40.0583, longitude: -74.4057 },
  { id: 'US-NM', region: 'sw', latitude: 34.5199, longitude: -105.8701 },
  { id: 'US-NY', region: 'panj', latitude: 40.7128, longitude: -74.006 },
  { id: 'US-NC', region: 'ma', latitude: 35.7596, longitude: -79.0193 },
  { id: 'US-ND', region: 'grp', latitude: 47.5515, longitude: -101.002 },
  { id: 'US-OH', region: 'grl', latitude: 40.4173, longitude: -82.9071 },
  { id: 'US-OK', region: 'grp', latitude: 35.0078, longitude: -97.0929 },
  { id: 'US-OR', region: 'nw', latitude: 43.8041, longitude: -120.5542 },
  { id: 'US-PA', region: 'panj', latitude: 41.2033, longitude: -77.1945 },
  { id: 'US-RI', region: 'neng', latitude: 41.5801, longitude: -71.4774 },
  { id: 'US-SC', region: 'ma', latitude: 33.8361, longitude: -81.1637 },
  { id: 'US-SD', region: 'grp', latitude: 43.9695, longitude: -99.9018 },
  { id: 'US-TN', region: 'se', latitude: 35.5175, longitude: -86.5804 },
  { id: 'US-TX', region: 'sc', latitude: 31.9686, longitude: -99.9018 },
  { id: 'US-UT', region: 'nw', latitude: 39.321, longitude: -111.0937 },
  { id: 'US-VT', region: 'neng', latitude: 44.5588, longitude: -72.5778 },
  { id: 'US-VA', region: 'ma', latitude: 37.4316, longitude: -78.6569 },
  { id: 'US-WA', region: 'nw', latitude: 47.7511, longitude: -120.7401 },
  { id: 'US-WV', region: 'ma', latitude: 38.5976, longitude: -80.4549 },
  { id: 'US-WI', region: 'mw', latitude: 43.7844, longitude: -88.7879 },
  { id: 'US-WY', region: 'grp', latitude: 43.076, longitude: -107.2903 },
  { id: 'US-DC', region: 'ma', latitude: 38.9072, longitude: -77.0369 },
];

export const FORMAT = [
  { value: 'all', label: 'All' },
  { value: 'live', label: 'Live' },
  { value: 'virtual', label: 'Virtual' },
];

export const VIEW_BY = [
  { value: 'image_slides', label: 'Image Slides' },
  { value: 'power_point', label: 'PowerPoint' },
];

export const FORMAT_TYPE = [
  { value: 'format', label: 'Format' },
  { value: 'meeting_type', label: 'Meeting Type' },
];

export const REQUEST_FORM_SUB_TYPE = [
  { value: '', label: '' },
  { value: 'IFYESTHEN', label: 'If Yes Then' },
  { value: 'IFYESSKIP', label: 'If Yes Skip' },
  { value: 'IFNOTHEN', label: 'If No Then' },
  { value: 'IFNOSKIP', label: 'If No Skip' },
];

export const ADDRESS_TYPE = ['office', 'office2', 'mailing', 'sendcheck'];

export const LOGIN_GOTO_QUERY_PARAM = 'goto';

export const TRIGGER_CATEGORIES = [
  'event_request',
  'confirmation',
  'preparation',
  'closeout',
  'other',
];

export const CRON_CATEGORIES = [
  'event',
  'speaker',
  'attendee',
  'salesforce',
  'finance',
];

export const CRON_EVENT_STATUSES = ['speaker', 'contract', 'training'];

export const WEEK_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const COMPANY_STEPS = {
  COMPANY: 'company',
  COMPANY_BILLING_PROFILE: 'company_billing_profile',
  PRODUCT: 'product',
  CLIENT_LEVEL: 'client_level',
  SALESFORCE: 'salesforce',
  AREA: 'area',
  AREA_TYPE: 'area_type',
  USER: 'user',
  MEETING_TYPE: 'meeting_type',
  EVENT_TRAINING: 'event_training',
  TOPIC: 'topic',
  SPEAKER: 'speaker',
  SPEAKER_TOPIC: 'speaker_topic',
  SPEAKER_AGREEMENT: 'speaker_agreement',
  SPEAKER_CV: 'speaker_cv',
  SPEAKER_BIO: 'speaker_bio',
  DROPDOWN: 'dropdown',
  CALL_PLAN: 'call_plan',
  HCP_VALIDATION: 'hcp_validation',
  REQUEST_FORM: 'request_form',
};

export const COMPANY_STEP_LINKS = [
  {
    step: COMPANY_STEPS.COMPANY,
    label: 'Step 1. Build a Company',
    button: '',
    link: '/tools/company/new',
  },
  {
    step: COMPANY_STEPS.COMPANY_BILLING_PROFILE,
    label: 'Step 2. Add Company Billing Profile',
    button: 'Billing Profile',
    link: '/tools/company/billing_profile',
  },
  {
    step: COMPANY_STEPS.PRODUCT,
    label: 'Step 3. Add Products',
    button: 'Products',
    link: '/tools/product',
  },
  {
    step: COMPANY_STEPS.CLIENT_LEVEL,
    label: 'Step 4. Name and Define User Types',
    button: 'User Types',
    link: '/tools/client/level/wizard',
  },
  {
    step: COMPANY_STEPS.AREA_TYPE,
    label: 'Step 5. Align User Types to Areas',
    button: 'Area Types',
    link: '/tools/company/area/type/wizard',
  },
  {
    step: COMPANY_STEPS.SALESFORCE,
    label: 'Step 6. Add Company Bureaus',
    button: 'Bureaus',
    link: '/tools/salesforce',
  },
  {
    step: COMPANY_STEPS.AREA,
    label: 'Step 7. Upload Salesforce Area',
    button: 'Area',
    link: '/tools/company/area',
  },
  {
    step: COMPANY_STEPS.USER,
    label: 'Step 8. Upload Salesforce Personnel',
    button: 'Salesforce Personnel',
    link: '/tools/client/import',
  },
  {
    step: COMPANY_STEPS.MEETING_TYPE,
    label: 'Step 9. Add Event Type',
    button: 'Event Type',
    link: '/tools/meeting_type/wizard',
  },
  {
    step: COMPANY_STEPS.EVENT_TRAINING,
    label: 'Step 10. Add Compliance Training',
    button: 'Compliance Training',
    link: '/tools/event_training/wizard',
  },
  {
    step: COMPANY_STEPS.TOPIC,
    label: 'Step 11. Add Topic',
    button: 'Topic',
    link: '/tools/topic/wizard',
  },
  {
    step: COMPANY_STEPS.SPEAKER,
    label: 'Step 12. Add Speaker',
    button: 'Speaker',
    link: '/tools/speaker/import',
  },
  {
    step: COMPANY_STEPS.SPEAKER_TOPIC,
    label: 'Step 13. Add Speaker Training',
    button: 'Training',
    link: '/tools/speaker/trainings/wizard',
  },
  {
    step: COMPANY_STEPS.SPEAKER_AGREEMENT,
    label: 'Step 14. Add Speaker Contracts',
    button: 'Speaker Contract',
    link: '/tools/speaker/agreements/wizard',
  },
  {
    step: COMPANY_STEPS.SPEAKER_CV,
    label: 'Step 15. Add Speaker CVs',
    button: 'Speaker CV',
    link: '/tools/speaker/cvs',
  },
  {
    step: COMPANY_STEPS.SPEAKER_BIO,
    label: 'Step 16. Add Speaker Bios',
    button: 'Speaker Bio',
    link: '/tools/speaker/bios',
  },
  {
    step: COMPANY_STEPS.DROPDOWN,
    label: 'Step 17. Confirm Default Settings',
    button: 'Dropdown Settings',
    link: '/tools/dropdown/setting',
  },
  {
    step: COMPANY_STEPS.CALL_PLAN,
    label: 'Step 18. Add Target Master',
    button: 'Target Master',
    link: '/tools/call_plan/import',
  },
  {
    step: COMPANY_STEPS.HCP_VALIDATION,
    label: 'Step 19. Set HCP Validation Service',
    button: 'HCP Validation Service',
    link: '/tools/hcp_validation_setting',
  },
  {
    step: COMPANY_STEPS.REQUEST_FORM,
    label: 'Step 20. Program Request Form',
    button: 'Program Request Form',
    link: '/tools/request_form',
  },
];

export const CREDIT_CARD_TYPE = {
  STRIPE: 'stripe',
  CLIENT: 'client',
};
export const MISCELLANEOUS_TYPES = [
  { value: 'text', label: 'Text' },
  { value: 'dropdown', label: 'Dropdown' },
  { value: 'upload_file', label: 'Upload File' },
];

export const EVENT_SPEAKER_STATUSES = {
  PENDING: null,
  AWAITING_REPLY: 'awaiting_reply',
  CONFIRMED: 'confirmed',
  DECLINED: 'declined',
  UNAVAILABLE: 'unavailable',
  UNRESPONSIVE: 'unresponsive',
  NOT_CONTACTED: 'not_contacted',
  CANCELED: 'canceled',
};

export const CLIENT_EVENT_VIEW_SCOPES = [
  { key: 'mine', value: 'Only My Events' },
  { key: 'my_area', value: 'My Events Plus Events in My Area' },
];

export const PAYMENT_METHOD_SPEAKEREXPENSE = [
  { value: 'ach', label: 'ACH' },
  { value: 'check', label: 'Check' },
  { value: 'plaid', label: 'Plaid' },
];

export const PAYMENT_METHOD = [
  { value: 'default_credit_card', label: 'Default Credit Card' },
  { value: 'corporate_card', label: 'Corporate Card' },
  { value: 'plaid', label: 'Plaid' },
  { value: 'ach', label: 'ACH' },
  { value: 'check', label: 'Check' },
  { value: 'agency_credit_card', label: 'Agency Credit Card' },
  { value: 'stripe', label: 'Stripe' },
];

export const PAYMENT_STATUS = {
  PENDING: 'pending',
  ISSUED: 'issued',
  DECLINED: 'declined',
  REVERSED: 'reversed',
  COMPLETED: 'completed',
  DISPUTED: 'disputed',
  REFUNDED: 'refunded',
  DISPUTE_LOST: 'lost',
};

export const REPORT_TYPES = [
  { id: 'event', label: 'Event' },
  { id: 'speaker', label: 'Speaker' },
  { id: 'attendee', label: 'Attendee' },
  { id: 'expense', label: 'Expense' },
  { id: 'expense_type', label: 'Expense Type' },
  { id: 'coding', label: 'Coding' }
];

export const WEBCAST_TYPE = {
  TWILIO: 'twilio',
  ZOOM: 'zoom',
  VIDEOSDK: 'videosdk'
};