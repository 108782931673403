import moment from 'moment';

import { Base } from './_base.model';
import { Contact } from './contact.model';

export class Payment extends Base {
  id: number;
  category_id: string;
  amount: number;
  method: string;
  check_number: string;
  transaction_number: string;
  confirmation_number: string;
  credit_card_id: number;
  activity_date: Date;
  requested_date: Date;
  payment_date: Date;
  reported_date: Date;
  first_reported_date: Date;
  not_reportable: Boolean;
  sunshine_event_id: string;
  comment: string;
  contact_id: string | null;
  speaker: any;
  vendor_id: string | null;
  receipt: string;
  proof: string;
  receipt_file: string | ArrayBuffer | null;
  proof_file: string | ArrayBuffer | null;
  info: any;
  planner_reviewed_date: Date;
  planner_reviewed_by: string;
  inspected_at: Date;
  inspected_by: string;
  alert_sent: string;
  subcategory_id: string;
  stripe_transaction_info: any;
  plaid_status: Boolean | null;
  plaid_transfer_id: string;
  plaid_access_token: string | null;
  stripe_transaction_id: string;
  extend_transaction_id: string;
  contact: Contact;
  status: string;
  planner_approved_date: Date;
  secondary_inspection_date: Date;
  declined_canceled_date: Date;
  declined_canceled_by: string;
  receipt_recv_date: Date;
  expense_submitted_date: Date;
  expense_updated_date: Date;
  created_at: Date;
  updated_at: Date;

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.activity_date) {
      this.activity_date = moment(this.activity_date).toDate();
    }
    if (this.requested_date) {
      this.requested_date = moment(this.requested_date).toDate();
    }
    if (this.payment_date) {
      this.payment_date = moment(this.payment_date).toDate();
    }
    if (this.reported_date) {
      this.reported_date = moment(this.reported_date).toDate();
    }
    if (this.inspected_at) {
      this.inspected_at = moment(this.inspected_at).toDate();
    }
    if (this.planner_reviewed_date) {
      this.planner_reviewed_date = moment(this.planner_reviewed_date).toDate();
    }
    if (this.first_reported_date) {
      this.first_reported_date = moment(this.first_reported_date).toDate();
    }
    if (this.planner_approved_date) {
      this.planner_approved_date = moment(this.planner_approved_date).toDate();
    }
    if (this.secondary_inspection_date) {
      this.secondary_inspection_date = moment(this.secondary_inspection_date).toDate();
    }
    if (this.declined_canceled_date) {
      this.declined_canceled_date = moment(this.declined_canceled_date).toDate();
    }
    if (this.receipt_recv_date) {
      this.receipt_recv_date = moment(this.receipt_recv_date).toDate();
    }
    if (this.expense_submitted_date) {
      this.expense_submitted_date = moment(this.expense_submitted_date).toDate();
    }
    if (this.expense_updated_date) {
      this.expense_updated_date = moment(this.expense_updated_date).toDate();
    }
    return this;
  }

  isPaidWithCash() {
    return this.category_id === 'HONORARIA' || this.category_id === 'SPEAKEREXPENSE';
  }

  getStatus() {
    if (this.isPaidWithCash()) {
      if (this.declined_canceled_date) {
        return 'canceled';
      }
      if (this.payment_date) {
        return 'paid';
      }
      if (this.inspected_at) {
        return 'inspected';
      }
      if (this.planner_reviewed_date) {
        return 'reviewed';
      }
      return 'pending';
    }
    return this.status;
  }

  isCompleted() {
    return this.stripe_transaction_id || this.extend_transaction_id || this.plaid_transfer_id;
  }

  isUpdated() {
    if (this.created_at && this.updated_at) {
      return moment(this.updated_at).unix() === moment(this.created_at).unix();
    }
    return false;
  }
}
