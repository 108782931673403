import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { Area } from 'app/_models';

@Injectable()
export class AreaBudgetService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/salesforces/%(salesforce_id)s/area_budgets`);
  }

  list(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    return super.list(urlParams, apiParams)
      .pipe(map(res => {
        return {
          parent: res.parent ? new Area(res.parent.type_id).deserialize(res.parent) : null,
          children: res.children.map((area) => new Area(area.type_id).deserialize(area))
        };
      }));
  }
}
