import { Base } from './_base.model';

export class JobType extends Base {
  id!: string;
  name!: string;
  category!: string;
  options!: object;
  created_at!: Date;
  updated_at!: Date;

  constructor() {
    super();
  }
}
