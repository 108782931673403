import { Base } from './_base.model';

export class CountryState extends Base {
  id: number;
  country_id: string;
  code: string;
  name: string;
  is_deleted: boolean;

  constructor() {
    super();
  
    this.is_deleted = false;
  }
}