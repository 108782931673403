<div class="row mb-3" [formGroup]="formGroup">
  <div class="col-sm-4">
    <div class="mb-3">
      <label class="form-label">Organization Name</label>
      <input type="text" formControlName="name" [(ngModel)]="vendor.name" class="form-control" required>
    </div>
    <div class="mb-3">
      <label class="form-label">Vendor Code</label>
      <input type="text" formControlName="code" name="code" [(ngModel)]="vendor.code" class="form-control">
    </div>
    <div class="mb-3" *ngIf="vendorTypes">
      <label class="form-label">Type</label>
      <select formControlName="type_id" name="type_id" class="form-control" [(ngModel)]="vendor.type_id" required>
        <option [value]="vendorType.id" *ngFor="let vendorType of vendorTypes">{{vendorType.label}}</option>
      </select>
    </div>
    <div class="mb-3">
      <label class="form-label">Address 1</label>
      <input type="text" class="form-control" formControlName="address1" name="address1" [(ngModel)]="vendor.address1">
    </div>
    <div class="mb-3">
      <label class="form-label">Address 2</label>
      <input type="text" class="form-control" formControlName="address2" name="address2" [(ngModel)]="vendor.address2">
    </div>
    <div class="mb-3">
      <label class="form-label">City</label>
      <input type="text" class="form-control" formControlName="city" name="city" [(ngModel)]="vendor.city">
    </div>
    <div class="mb-3">
      <label class="form-label">State</label>
      <select formControlName="state" name="state" class="form-control" [(ngModel)]="vendor.state">
        <ng-template ngFor let-state [ngForOf]="states">
          <option [value]="state.code" *ngIf="state.country_id === 'US'">{{state.name}}</option>
        </ng-template>
      </select>
    </div>
    <div class="mb-3">
      <label class="form-label">Zip</label>
      <input type="text" class="form-control" formControlName="zip" name="zip" [(ngModel)]="vendor.zip"
        pattern="[0-9]*">
    </div>
  </div>
  <div class="col-md-4">
    <div class="mb-3">
      <label class="form-label">Contact Name</label>
      <input type="text" class="form-control" formControlName="contact_name" name="contact_name"
        [(ngModel)]="vendor.contact_name">
    </div>
    <div class="mb-3" *ngIf="agency && (vendor.type_id == 'av_company' || vendor.type_id == 'travel_agent')">
      <label class="form-label">Contact Phone</label>
      <app-phone-input formControlName="contact_phone" name="contact_phone" [(ngModel)]="vendor.contact_phone"></app-phone-input>
    </div>
    <div class="mb-3" *ngIf="agency && (vendor.type_id == 'av_company' || vendor.type_id == 'travel_agent')">
      <label class="form-label">Contact Email</label>
      <input type="text" class="form-control" formControlName="contact_email" name="contact_email"
        [(ngModel)]="vendor.contact_email">
    </div>
    <div class="mb-3" *ngIf="agency && (vendor.type_id == 'av_company' || vendor.type_id == 'travel_agent')">
      <label class="form-label">After Hours Contact Number</label>
      <input type="text" class="form-control" formControlName="contact_phone_after_hours" name="contact_phone_after_hours"
        [(ngModel)]="vendor.contact_phone_after_hours">
    </div>
    <div class="mb-3">
      <label class="form-label">Phone Number</label>
      <app-phone-input formControlName="phone" name="phone" [(ngModel)]="vendor.phone"></app-phone-input>
    </div>
    <div class="mb-3">
      <label class="form-label">Fax</label>
      <input type="text" class="form-control" formControlName="fax" name="fax" [(ngModel)]="vendor.fax">
    </div>
    <div class="mb-3">
      <label class="form-label">Email</label>
      <input type="email" class="form-control" formControlName="email" name="email" [(ngModel)]="vendor.email" email>
    </div>
    <div class="mb-3">
      <label class="form-label">Web Address</label>
      <input type="text" class="form-control" formControlName="web_address" name="web_address"
        [(ngModel)]="vendor.web_address">
    </div>
  </div>
  <div class="col-md-4">
    <div class="mb-3" *ngIf="vendorStatuses">
      <label class="form-label">Status</label>
      <select formControlName="status_id" name="status_id" class="form-control" [(ngModel)]="vendor.status_id" required>
        <option></option>
        <option [value]="vendorStatus.id" *ngFor="let vendorStatus of vendorStatuses">{{vendorStatus.label}}
        </option>
      </select>
    </div>
    <div class="mb-3" *ngIf="!agency && vendorStatuses">
      <label class="form-label">Client Status</label>
      <select formControlName="company_status_id" name="company_status_id" class="form-control"
        [(ngModel)]="vendor.vendor_company_status_id" required>
        <option></option>
        <option [value]="vendorStatus.id" *ngFor="let vendorStatus of vendorStatuses">{{vendorStatus.label}}
        </option>
      </select>
    </div>
    <div class="mb-3" *ngIf="agency && vendorStatuses">
      <label class="form-label">Client Status</label>
      <select formControlName="vendor_agency_status_id" name="vendor_agency_status_id" class="form-control"
        [(ngModel)]="vendor.vendor_agency_status_id" required>
        <option></option>
        <option [value]="vendorStatus.id" *ngFor="let vendorStatus of vendorStatuses">{{vendorStatus.label}}
        </option>
      </select>
    </div>
    <div *ngIf="vendor.type_id === 'restaurant' || vendor.type_id === 'venue'" class="mb-3">
      <label class="form-label">Franchise Parent</label>
      <select formControlName="franch_parent_id" name="franch_parent_id" class="form-control"
        [(ngModel)]="vendor.franch_parent_id">
        <option></option>
        <option [value]="v.id" *ngFor="let v of franchiseParentVendors">{{v.name}}</option>
      </select>
    </div>
    <div class="mb-3">
      <label class="form-label">Archived</label>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="archived" name="archived"
          [(ngModel)]="vendor.archived">
        <label class="form-check-label" for="archived"></label>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label">Valet Parking</label>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="valet_parking" name="valet_parking"
          [(ngModel)]="vendor.valet_parking">
        <label class="form-check-label" for="valet_parking"></label>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label">Off Street Parking</label>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="off_street_parking" name="off_street_parking"
          [(ngModel)]="vendor.off_street_parking">
        <label class="form-check-label" for="off_street_parking"></label>
      </div>
    </div>
  </div>
</div>
