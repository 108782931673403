import moment from 'moment';

import { Base } from './_base.model';

export class Presentation extends Base {
  id: string;
  category: string;
  name: string;
  version: string;
  format: string;
  meeting_type_id: number;
  topic_id: number;
  start_at: Date;
  stop_at: Date;
  view_by: string;
  created_at: Date;
  pptx: string;
  slide_count: number;
  pptx2img_status: number;
  slide_images: string[];
  main_presentation_ids: string[];
  speaker_ids: string[];
  slide_array: string[];
  archived: boolean;
  after_slide_all: number;
  qrcodes: { qrcode_id: string | null, qrcode_name: string | null, after_slide: number }[];
  question_slides: any[];

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.start_at) {
      this.start_at = moment(this.start_at).toDate();
    }
    if (this.stop_at) {
      this.stop_at = moment(this.stop_at).toDate();
    }
    return this;
  }
}
