import { Base } from './_base.model';

export class Event extends Base {
  id: string;
  created_at: Date;
  common_event: any;
  event_area: any;
  salesforce_id: string;
  speakers: any[];
  speakers_common_data: any;
  attendees: any[];
  client: any;
  venue: any;
  venue_room: any;
  payments: any[];
  budget_summary: any;
  meeting_type: any;
  topic: any;
  status_id: any;
  sub_status_id: any;
  budget_status_id: any;
  attendee_summary: any;
  checklist_summary: any;
  cards: any[];
  notes: any[];
  area_id: number;
  fiscal_year_id: string;
  meeting_type_id: number;
  venue_staff: any;
  venue_shadow: any;
  eo_of_record: any;
  eo_of_record_id: number;
  planner_staff: any;
  planner_shadow: any;
  request: any;
  av_company_id: string | null;
  accepted_at: Date;
  reconciled_at: Date;
  client_meal_opt_out: boolean;
  client_signature: string;
  html_invitation_id: string;
  print_invitation_id: string;
  email_invitation_id: string;
  upload_forms: any[];
  upload_form_summary: any;
  client_evaluation_id: number;
  speaker_evaluation_id: number;
  attendee_evaluation_id: number;
  status_changes: any[];

  static toCalendarEvent(obj: any): any {
    return {
      id: obj.id,
      title: obj.common_event.name,
      start: obj.common_event.start_at,
      end: obj.common_event.end_at,
    };
  }

  getConfirmedOrFirstSpeakers() {
    const confirmedSpeakers = this.speakers.filter((s) => s.EventSpeaker.status === 'confirmed');
    if (confirmedSpeakers.length) return confirmedSpeakers;
    return this.speakers.length ? [this.speakers[0]] : [];
  }

  getEventNotices() {
    const confirmedSpeakers = this.speakers.filter((s) => s.EventSpeaker.status === 'confirmed');
    const filteredSpeakers = confirmedSpeakers.filter((e) => e.event_notice !== null && e.event_notice !== "");
    return filteredSpeakers;
  }
}
