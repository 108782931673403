import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-vendor-edit-form',
  templateUrl: 'vendor-edit-form.component.html',
})
export class VendorEditFormComponent {
  @Input() formGroup: FormGroup;
  @Input() vendor;
  @Input() vendorTypes;
  @Input() states;
  @Input() vendorStatuses;
  @Input() franchiseParentVendors;
  @Input() agency;

  constructor(
    private fb: FormBuilder,
  ) {
    this.formGroup = this.fb.group({
      name: [''],
      code: [''],
      type_id: [''],
      address1: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip: [''],
      contact_name: [''],
      contact_email: [''],
      contact_phone: [''],
      contact_phone_after_hours: [''],
      phone: [''],
      fax: [''],
      email: [''],
      web_address: [''],
      archived: [''],
      valet_parking: [''],
      off_street_parking: [''],
      status_id: [''],
      company_status_id: [''],
      vendor_agency_status_id: [''],
      franch_parent_id: [''],
    });
  }
}
