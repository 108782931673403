import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { DatatablesUtils } from '../_utils/datatables';
import { Topic } from 'app/_models';

@Injectable()
export class TopicService extends BaseService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/topics`);
  }

  listPage(urlParams: any, params: any, dtParams: any): Observable<Topic[]> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<Topic[]>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    });
  }
}
