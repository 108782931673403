import { Base } from './_base.model';

export class Forum extends Base {
  id: number;
  name: string;
  type: string;
  status: string;
  start_date: Date;
  end_date: Date;

  uri(path = '') {
    return `/forums/${this.id}${path}`;
  }
}
