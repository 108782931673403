import { Base } from './_base.model';

export class RequestForm extends Base {
  id: number;
  meeting_types: any[];
  salesforces: any[];
  priority: string;
  screens: any;
  default_approval_group_id: number;

  constructor() {
    super();
    this.screens = [];
  }
}
