import moment from 'moment';

import { Contact } from './contact.model';
import { ContactAddress } from './contact_address.model';
import { Assistant } from './assistant.model';
import { ContactLicense } from './contact_license.model';

export class Speaker extends Contact {
  assistant: Assistant;
  licenses: ContactLicense[];
  addresses: ContactAddress[];
  contact: any;
  salesforces: any[];
  products: any[];
  topics: any[];
  event_trainings: any[];
  payment_info: any;
  office: any;
  office2: any;
  mailing: any;
  sendcheck: any;
  user: any;
  speaker_level: any;
  notes: any[];
  rates: any;
  agreements: any[];
  ytd: string;
  npi_no: string;
  hcp_flag: boolean;
  ein_flag: boolean;
  npi_registry: any;
  badge_name: string;
  badge_title: string;
  badge_affiliation: string;
  event_notice: string;
  territory_id: number;
  district_id: number;
  tll: number;
  msl: number;
  photo: string;
  blocked_dates: string;
  comments: string;
  speaker_rating_by_years: any;
  speaker_flagged_at: Date;
  speaker_checked_at: Date;

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.w9_received_on) {
      this.w9_received_on = moment(this.w9_received_on).toDate();
    }
    if (this.topics) {
      this.topics.forEach((t) => {
        if (t.trained_at) {
          t.trained_at = moment(t.trained_at).toDate();
        }
        if (t.attested_at) {
          t.attested_at = moment(t.attested_at).toDate();
        }
      });
    }
    if (this.event_trainings) {
      this.event_trainings.forEach((t) => {
        if (t.trained_at) {
          t.trained_at = moment(t.trained_at).toDate();
        }
        if (t.attested_at) {
          t.attested_at = moment(t.attested_at).toDate();
        }
      });
    }
    return this;
  }
}
