import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fullLastName' })
export class FullLastNamePipe implements PipeTransform {
  transform(input: any): string {
    if (!input) {
      return input;
    }
    let name = '';
    if (input.last_name) {
      name = input.last_name;
    }
    if (input.first_name) {
      name += ', ' + input.first_name;
    }
    return name;
  }
}
