import moment from 'moment';
import { Base } from './_base.model';

export class EventTraining extends Base {
  id: number;
  name: string;
  description: string;
  valid_from: Date;
  valid_to: Date;
  groups: any[];

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.valid_from) {
      this.valid_from = moment(this.valid_from).toDate();
    }
    if (this.valid_to) {
      this.valid_to = moment(this.valid_to).toDate();
    }
    return this;
  }
}
