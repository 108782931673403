import moment from 'moment';
import { Base } from './_base.model';

export class Report extends Base {
  id!: number;
  name!: string;
  title!: string;
  type!: string;
  reset_at!: Date;
  options!: object;
  client_view!: boolean;
  downloads: number = 0;

  constructor(obj) {
    super();
    Object.assign(this, obj);
  }

  isDownload() {
    return this.type === 'runtime';
  }

  isResetable() {
    return this.type === 'resetable';
  }

  isArchive() {
    return this.type === 'archivable';
  }

  isEmail() {
    return this.type === 'email';
  }

  getTitle() {
    let title = this.title;
    if (this.isResetable()) {
      title += ` (Last Reset ${this.reset_at ? moment(this.reset_at).format('L') : 'n/a'})`;
    }

    return title;
  }
}
