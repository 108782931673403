import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable()
export class ClientLevelService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/client_levels`);
  }

  override update(model: any, urlParams?: any, apiParams?: any): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/update`;
    return this.http.put<any>(url, model, {
      params: this.toHttpParams(apiParams)
    });
  }
}
