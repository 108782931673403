import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class SalesforceFiscalYearService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)/salesforces/%(salesforce_id)/fiscal_years/%(fiscal_year_id)`);
  }

  get(urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/salesforces/%(salesforce_id)s/fiscal_years/%(fiscal_year_id)s`, urlParams);
    return this.http.get<any>(url, {
      params: this.toHttpParams(apiParams)
    });
  }

  update(urlParams: any = {}, apiParams: any = {}, payload: any = {}): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/salesforces/%(salesforce_id)s/fiscal_years/%(fiscal_year_id)s`, urlParams);
    return this.http.put<any>(url, payload, {
      params: this.toHttpParams(apiParams)
    });
  }
}