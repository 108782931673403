import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-call-plan-new-modal',
  templateUrl: './call_plan-new-modal.component.html',
})
export class CallPlanNewModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  newAttendee = new FormGroup({
    type: new FormControl(''),
    first_name: new FormControl(''),
    middle_name: new FormControl(''),
    last_name: new FormControl(''),
    email: new FormControl(''),
    degree: new FormControl(''),
    specialty: new FormControl(''),
    profession: new FormControl(''),
    npi_no: new FormControl(''),
    state_license: new FormControl(''),
    state_number: new FormControl(''),
    affiliation: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zip: new FormControl(''),
  });
  states: any[];
  professions: any[];
  degrees: any[];
  specialties: any[];

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {

  }

  onSubmit() {
    this.event.emit(this.newAttendee.value);
  }

  createNew() {
    this.bsModalRef.hide();
    this.event.emit('new_attendee');
  }
}
