import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';

import { environment } from '../../environments/environment';
import { USER_ROLES } from '../app.constants';

@Injectable()
export class AuthenticationService {
  private currentUser: any;
  private handleLogin = (user) => {
    if (user.role === USER_ROLES.CONTACT) {
      return false;
    }
    this.currentUser = user;
    this.saveToLocalStorage();
    localStorage.setItem('QRCODE_PLACEHOLDER_URI', `${environment.apiBaseUrl}/event_qrcode?token=`)
    this.socket.connect();
    return true;
  }

  constructor(
    private http: HttpClient,
    private socket: Socket
  ) {
    const user = localStorage.getItem('currentUser');
    if (user) this.currentUser = JSON.parse(user);
  }

  login(model): Observable<boolean> {
    return this.http.post(`${environment.apiBaseUrl}/auth/signin`, model)
      .pipe(
        map(this.handleLogin),
        catchError(res => throwError(res))
      );
  }

  loginWithGoogle(model): Observable<boolean> {
    return this.http.post(`${environment.apiBaseUrl}/auth/signin_with_google`, model)
      .pipe(
        map(this.handleLogin),
        catchError(res => throwError(res))
      );
  }

  loginWithFacebook(model): Observable<boolean> {
    return this.http.post(`${environment.apiBaseUrl}/auth/signin_with_facebook`, model)
      .pipe(
        map(this.handleLogin),
        catchError(res => throwError(res))
      );
  }

  loginWithApple(model): Observable<boolean> {
    return this.http.post(`${environment.apiBaseUrl}/auth/signin_with_apple`, model)
      .pipe(
        map(this.handleLogin),
        catchError(res => throwError(res))
      );
  }

  loginWithAuth0(model): Observable<boolean> {
    return this.http.post(`${environment.apiBaseUrl}/auth/signin_with_auth0`, model)
      .pipe(
        map(this.handleLogin),
        catchError(res => throwError(res))
      );
  }

  forgotPassword(username) {
    return this.http.post(`${environment.apiBaseUrl}/auth/forgot_password`, {
      username
    });
  }

  forgotUsername(email) {
    return this.http.post(`${environment.apiBaseUrl}/auth/forgot_username`, {
      email
    });
  }

  resetPassword(code, password) {
    return this.http.post(`${environment.apiBaseUrl}/auth/reset_password`, {
      code,
      password
    });
  }

  token(): string {
    return this.currentUser && this.currentUser.token;
  }

  role(): string {
    return this.currentUser && this.currentUser.role;
  }

  id(): string {
    return this.currentUser && this.currentUser.id;
  }

  staff_id(): string {
    return this.currentUser && this.currentUser.staff_id;
  }

  staff_agency_id(): string {
    return this.currentUser && this.currentUser.agency_id;
  }

  client_id(): number {
    return this.currentUser && this.currentUser.client_id;
  }

  client_company_id(): string {
    return this.currentUser && this.currentUser.company_id;
  }

  contact_id(): string {
    return this.currentUser && this.currentUser.contact_id;
  }

  client_permissions(): any[] {
    if (this.currentUser && this.currentUser.client_id) {
      const permissions = this.currentUser.permissions.map((permission) => permission.id);
      return permissions;
    }
    return [];
  }
  client_level(): any {
    if (this.currentUser && this.currentUser.client_id) {
      return this.currentUser.level;
    }
    return {};
  }

  current_company_id(): string {
    return this.currentUser && this.currentUser.current_company_id;
  }

  current_salesforce_id(): string {
    return this.currentUser && this.currentUser.current_salesforce_id;
  }

  current_company_name(): string {
    return this.currentUser && this.currentUser.current_company_name;
  }

  current_salesforce_name(): string {
    return this.currentUser && this.currentUser.current_salesforce_name;
  }

  timezone_id(): string {
    return (this.currentUser && this.currentUser.timezone_id) || 'America/New_York';
  }

  setCurrentSalesforce(company_id: string, company_name: string, salesforce_id: string, salesforce_name: string) {
    if (this.currentUser) {
      this.currentUser.current_company_id = company_id;
      this.currentUser.current_company_name = company_name;
      this.currentUser.current_salesforce_id = salesforce_id;
      this.currentUser.current_salesforce_name = salesforce_name;
      this.saveToLocalStorage();
    }
  }

  saveToLocalStorage() {
    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
  }

  is_staff(): boolean {
    return this.role() === USER_ROLES.STAFF;
  }

  is_agency_staff(): boolean {
    return this.role() === USER_ROLES.AGENCY_STAFF;
  }

  is_client(): boolean {
    return this.role() === USER_ROLES.CLIENT;
  }

  is_contact(): boolean {
    return this.role() === USER_ROLES.CONTACT;
  }

  is_staff_or_agency_staff(): boolean {
    return this.is_staff() || this.is_agency_staff();
  }

  is_client_corporate(): boolean {
    return this.is_client() && this.client_level().is_corporate;
  }

  is_staff_can_account(): boolean {
    return this.is_staff() && this.currentUser.can_account;
  }

  is_agency_staff_can_account(): boolean {
    return this.is_agency_staff() && this.currentUser.can_account;
  }

  is_staff_super(): boolean {
    return this.is_staff() && this.currentUser.is_super;
  }

  is_agency_staff_super(): boolean {
    return this.is_agency_staff() && this.currentUser.is_super;
  }

  is_super(): boolean {
    return this.is_staff_super() || this.is_agency_staff_super();
  }

  can_account(): boolean {
    return this.is_staff_can_account() || this.is_agency_staff_can_account();
  }

  is_in(roles): boolean {
    return roles.includes(this.role());
  }

  logout(storageOnly = false): void {
    if (!storageOnly) {
      this.http.post(`${environment.apiBaseUrl}/auth/signout`, {}, {
        headers: {
          'Authorization': this.token(),
        }
      }).subscribe();
    }
    this.currentUser = null;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('QRCODE_PLACEHOLDER_URI');

    this.socket.disconnect();
  }
}
