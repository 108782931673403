import moment from 'moment';

import { Base } from './_base.model';

export class PresentationCustom extends Base {
  id: number;
  name: string;
  presentation_id: string;
  format: string;
  modules: { module_id: string | null, module_name: string | null, after_slide: number }[];
  pptx: string;

  constructor() {
    super();
    this.modules = [];
  }
}
