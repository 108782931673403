import { Injectable } from '@angular/core';

@Injectable()
export class SettingService {
  private _setting: any;

  constructor() {
    try {
      const settingValue = localStorage.getItem('setting');
      if (settingValue) this._setting = JSON.parse(settingValue);
    } catch (e) { }
    if (!this._setting) {
      this._setting = {
        events_filter_visibility: true,
        event_right_menu_visibility: true,
        events_include_shadow_programs: true,
        events_show_my_programs: true,
        event_info_card_visibility: false
      };
    }
  }

  getSetting(key: string, defaultValue: any) {
    return this._setting ? this._setting[key] : defaultValue;
  }

  setSetting(key: string, value: any) {
    this._setting[key] = value;
    localStorage.setItem('setting', JSON.stringify(this._setting));
  }

  getPageSetting(pageKey: string, key: string, defaultValue: any) {
    key = `${pageKey}/${key}`;
    return this.getSetting(key, defaultValue);
  }

  setPageSetting(pageKey: string, key: string, value: any) {
    key = `${pageKey}/${key}`;
    this.setSetting(key, value);
  }

  getEventsFilterVisibility() {
    return this.getSetting('events_filter_visibility', true);
  }

  setEventsFilterVisibility(value: Boolean) {
    this.setSetting('events_filter_visibility', value);
  }

  getEventRightMenuVisibility() {
    return this.getSetting('event_right_menu_visibility', true);
  }

  setEventRightMenuVisibility(value: Boolean) {
    this.setSetting('event_right_menu_visibility', value);
  }

  getEventScope() {
    return this.getSetting('event_scope', '');
  }

  setEventScope(value: string) {
    this.setSetting('event_scope', value);
  }

  getNonBureauEventScope() {
    return this.getSetting('event_scope_non_bureau', '');
  }

  setNonBureauEventScope(value: string) {
    this.setSetting('event_scope_non_bureau', value);
  }

  getEventInfoCardVisibility() {
    return this.getSetting('event_info_card_visibility', '');
  }

  setEventInfoCardVisibility(value: Boolean) {
    this.setSetting('event_info_card_visibility', value);
  }
}
