import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';

@Injectable()
export class EventChatMessageService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/events/%(event_id)s/chats`);
  }

  sendMessage(urlParams: any = {}, data: any = {}): Observable<any> {
    const url = sprintf(`${this.baseUrl}/%(room_id)s/messages`, urlParams);
    return this.http.post<any>(url, data);
  }
}
