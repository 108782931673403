import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { DatatablesUtils } from '../_utils/datatables';
import { Contact } from '../_models';

export class Contacts {
  contacts: Contact[];
  total: number;
}

@Injectable()
export class ContactService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/contacts`);
  }

  listPage(urlParams: any, params: any, dtParams: any): Observable<Contacts> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<Contacts>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    });
  }

  listAttendees(urlParams: any, params: any, dtParams: any): Observable<Contacts> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/attendees`, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<Contacts>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    });
  }

  getContactCompanies(id: string): Observable<any[]> {
    const url = `${this.baseUrl}/${id}/companies`;
    return this.http.get<any[]>(url);
  }

  getCallPlans(id: string): Observable<any[]> {
    const url = `${this.baseUrl}/${id}/call_plans`;
    return this.http.get<any[]>(url);
  }
}
