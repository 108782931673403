import { Base } from './_base.model';

export class Notification extends Base {
  id: number;
  types: string[];
  event_card_category_id: string;
  name: string;
  description: string;
  subject: string;
  is_default: boolean;
  content: string;
}
