import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { Area } from 'app/_models';

@Injectable()
export class AreaService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/salesforces/%(salesforce_id)s/areas`);
  }

  list(urlParams: any = {}, apiParams: any = {}): Observable<Area[]> {
    return super.list(urlParams, apiParams)
      .pipe(
        map(res => res.map((area) => new Area(area.type_id).deserialize(area)))
      );
  }

  simpleList(urlParams: any, apiParams: any = {}): Observable<any[]> {
    const url = sprintf(this.baseUrl, urlParams) + '/simple_list';
    return this.http.get<any[]>(url, { params: this.toHttpParams(apiParams) });
  }

  importAreaManagers(urlParams: any, formData) {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/salesforces/%(salesforce_id)s/areas/import_area_managers`, urlParams);
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  importZipCodes(urlParams: any, formData) {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/areas/import_zipcodes`, urlParams);
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  // company wizard
  cloneSalesforce(urlParams: any, body: any, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + '/clone_salesforce';
    return this.http.post(url, body, {
      params: this.toHttpParams(apiParams),
    });
  }

  importAreas(body: any, urlParams: any, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + '/import_areas';
    return this.http.post(url, body, {
      params: this.toHttpParams(apiParams),
      reportProgress: true,
      observe: 'events'
    });
  }

  updateManagers(model: any, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/manager_id`;
    return this.http.put<any>(url, model, {
      params: this.toHttpParams(apiParams),
    });
  }

  updateStaffs(model: any, urlParams: any = {}, apiParams: any = {}): Observable<any> {
    const url = sprintf(this.baseUrl, urlParams) + `/${urlParams.staff_field}`;
    return this.http.put<any>(url, model, {
      params: this.toHttpParams(apiParams),
    });
  }

  cloneFiscalYear(urlParams, data) {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/areas/clone_fiscal_year`, urlParams);
    return this.http.post<any>(url, data);
  }
}
