import moment from 'moment';
import { Base } from './_base.model';

export class GlobalFile extends Base {
  id: number;
  file: string;
  form_file: string | ArrayBuffer;
  description: string;
  public: boolean;
  salesforce: boolean;
  start_date: Date;
  end_date: Date;
  status: string;

  deserialize(obj: any) {
    Object.assign(this, obj);
    if (this.start_date) {
      this.start_date = moment(this.start_date).toDate();
    }
    if (this.end_date) {
      this.end_date = moment(this.end_date).toDate();
    }
    return this;
  }

  visibleTo(): string[] {
    const visible_to: string[] = [];
    if (this.public) {
      visible_to.push('Public');
    }
    if (this.salesforce) {
      visible_to.push('Salesforce');
    }
    return visible_to;
  }

  visibleToAbbreviated(): string[] {
    const visible_to: string[] = [];
    if (this.public) {
      visible_to.push('Pub');
    }
    if (this.salesforce) {
      visible_to.push('Sales');
    }
    return visible_to;
  }
}

export class File extends GlobalFile {
  topics: any[];
  salesforces: any[];
  products: any[];
  form_file: string | ArrayBuffer;
  speaker: boolean;
  start_date: Date;
  end_date: Date;
  status: string;
  category: string;
  client_levels: any[];
  event_id: string;
  contact_id: string;
  archived: boolean;
  upload_form_id: string;

  visibleTo(): string[] {
    const visible_to: string[] = [];
    if (this.public) {
      visible_to.push('Public');
    }
    if (this.speaker) {
      visible_to.push('Speaker');
    }
    if (this.salesforce) {
      visible_to.push('Salesforce');
    }
    return visible_to;
  }

  visibleToAbbreviated(): string[] {
    const visible_to: string[] = [];
    if (this.public) {
      visible_to.push('Pub');
    }
    if (this.speaker) {
      visible_to.push('Sp');
    }
    if (this.salesforce) {
      visible_to.push('Sales');
    }
    return visible_to;
  }
}